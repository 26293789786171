import React from "react"
import moment from 'moment'
import { parse, ParsedQuery } from 'query-string'
import { setSessionCookie, getSessionCookie } from "../helpers/session"
import { DATE_API_FORMAT } from '../helpers/constant'
import { CalendarPickerPeriod } from "../Components/CalendarPicker"

// import { initialState, IState, reducer } from './reducer';

export interface FuncProps {
  lockQuarter: (quarter:string) => void
  lockMonth: (month:string) => void
  lockYear: (year:string) => void
  lockPeriod: (period:string | null) => void
}
export interface ContextProps extends FuncProps {
  quarter: string
  month: string
  year: string
  period: CalendarPickerPeriod | null | "historical"
}

export const appDate = moment().subtract(3,'months').endOf('quarter')
export const monthAppDate = moment().subtract(1,'months').endOf('month')
export const yearAppDate = moment(appDate).endOf('year')
export const firstHistoricalDate = moment(appDate).subtract(5,"years")

const initialState = {
  quarter: appDate.format(DATE_API_FORMAT),
  month: monthAppDate.format(DATE_API_FORMAT),
  year: yearAppDate.format(DATE_API_FORMAT),
  period: null,
  lockQuarter: (quarter:string) => {},
  lockMonth: (month:string) => {},
  lockYear: (month:string) => {},
  lockPeriod: (period:string | null) => {}
}

const IState = {
  quarter: initialState.quarter,
  month: initialState.month,
  year: initialState.year,
  period: null,
  lockQuarter: initialState.lockQuarter,
  lockMonth: initialState.lockMonth,
  lockYear: initialState.lockYear,
  lockPeriod: initialState.lockPeriod
}

export const CalendarContext = React.createContext<ContextProps>(IState)

// Provider for sidebar
export class CalendarProvider extends React.Component {
  constructor(props:any) {
    super(props);
    const session = getSessionCookie()
    const parsedParams:ParsedQuery = parse(props.location.search)
    const queryDate = Array.isArray(parsedParams.date) ? parsedParams.date[0] : parsedParams.date || null
    const period = (Array.isArray(parsedParams.period) ? parsedParams.period[0] : parsedParams.period) || session.period || null
    this.state = {
      quarter: (period === "quarter" && queryDate) || session.quarter || initialState.quarter,
      month: (period === "month" && queryDate) || session.month || initialState.month,
      year: (period === "year" && queryDate) || session.year || initialState.year,
      period: period,
      lockQuarter: this.lockQuarter,
      lockMonth: this.lockMonth,
      lockYear: this.lockYear,
      lockPeriod: this.lockPeriod
    }
  }

  state:ContextProps

  lockMonth = (month:string) => {
    const monthMoment = moment(month, DATE_API_FORMAT)
    this.setState({ month: month, quarter: monthMoment.endOf('quarter').format(DATE_API_FORMAT), year: monthMoment.endOf('year').format(DATE_API_FORMAT) }, () => {
      this.setSession()
    })
  }

  lockQuarter = (quarter:string) => {
    const quarterMoment = moment(quarter, DATE_API_FORMAT)
    this.setState({ quarter: quarter, year: quarterMoment.endOf('year').format(DATE_API_FORMAT) }, () => {
      this.setSession()
    })
  }

  lockYear = (year:string) => {
    this.setState({ year: year }, () => {
      this.setSession()
    })
  }

  lockPeriod = (period:string | null) => {
    this.setState({ period: period }, () => {
      this.setSession()
    })
  }

  setSession = () => {
    setSessionCookie({
      quarter: this.state.quarter,
      month: this.state.month,
      year: this.state.year,
      period: this.state.period,
    })
  }

  render() {
    return (
      <CalendarContext.Provider
        value={this.state}
      >
        {this.props.children}
      </CalendarContext.Provider>
    )
  }
}
