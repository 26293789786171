import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { noRefreshHistory as history } from "../history"
import { ICellRendererParams } from "@ag-grid-community/core"
import { downloadWithFilename } from './download'
import classnames from 'classnames'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { statusColor, statusEnumToValue } from './helpers'
import { get, uniqueId } from 'lodash'
import moment from 'moment'
import { DocumentPreviewIcon } from '../Components/Shared/DocumentPreviewIcon'

/**
 * if showHref is true, show real url on hover.
 **/
type textLinkParams = {
  text: string,
  url: string,
  showHref?: boolean,
  newTab?: boolean,
}

export const textLink = ({text, url, showHref = true, newTab = false}: textLinkParams) => {
  if(!text) return (<span>-</span>)
  let href = showHref? url: ""
  return (
    <a href={href} target={newTab? "_blank": ""} className="fake-link">
      {text}
      <FontAwesomeIcon
        icon={["fas", "external-link"]}
        className={classnames("ag-cell-right-icon-hover text-blue-80", {"d-none": !newTab})}
        onClick={() => history.push(url)}
      />
    </a>
  )
}

export const ReportPlanRenderer = (params: ICellRendererParams) => {
  // for report list table
  let result: JSX.Element[] = params.data?.plans?.map((plan:any, idx: number)=>{
    var link = textLink({text: plan.name, url: "/clients/" + plan.client?.id + "/" + params.data?.id + "/report"})
    return link
  })
  return (
    <span>
      {result.map((el, idx) => {
        if(idx > 0) {
          return <React.Fragment key={idx}>, {el}</React.Fragment>
        }
        return <React.Fragment key={idx}>{el}</React.Fragment>
      })}
    </span>
  )
}

export const checkOrCrossRenderer = (params: ICellRendererParams) => {
  let check = params.value && params.value !== "Inactive" ? "fa-check": ""
  return (<span className={`cell-icon fa-solid ${check}`}></span>)
}

export const rightAlignedRenderer = (params: ICellRendererParams) => {
  return (
    <div className="text-right">
      {params.valueFormatted || params.value}
    </div>
  )

}

export const fileIconRenderer = (params: ICellRendererParams) => {
  if(params.data){
    let result = DocumentPreviewIcon({document: params.data, className: "svg-20px"})
    let downloadIcon = (
      <FontAwesomeIcon
          icon="download"
          className="ml-1"
      />)
    return result || downloadIcon
  }
}

export const downloadIconRenderer = (params: ICellRendererParams, grayText: boolean) => {
  return (
    <Button className="btn btn-link p-0 border-0"
      onClick={() => downloadWithFilename(params.value || "", params.data.name)}>
      <FontAwesomeIcon
        icon="download"
        className={classnames("ml-1", {"text-callan-blue" :!grayText})}
      />
    </Button>
  )
}

export const favoriteIconRenderer = (params: ICellRendererParams) => {
  if(params.value){
    return (
      <FontAwesomeIcon
        icon="star"
        className="text-accent-yellow ml-4"
      />
    )
  } else {
    return (
      <FontAwesomeIcon
        icon={["far", "star"]}
        className="ml-4"
      />
    )
  }
}

export const tickIconRenderer = (params: ICellRendererParams) => {
  if(params.value){
    return (
      <FontAwesomeIcon
        icon="check"
        className="text-gray-100"
      />
    )
  } else {
    return ""
  }
}

export const statusIconRenderer = (params: ICellRendererParams) => {
  if(params.value){
    const status = get(params.data, params.colDef?.field || "")
    if(!status) return ""
    return (
    <FontAwesomeIcon
      icon="circle"
      className={`text-${statusColor(statusEnumToValue(status.status))}`}
    />
    )
  } else {
    return ""
  }
}

export const statusIconRendererWithDate = (params: ICellRendererParams) => {
  if(params.data){
    const status = get(params.data, params.colDef?.field || "")
    if(!status) return ""
    var text = document.createElement("span")
    // text.innerText = moment(params.value.lastUpdated).format("YYYY/MM/DD HH:mm")
    text.className = "text-gray-70"
    return (
      <span className="text-gray-70">
        <FontAwesomeIcon
          icon="circle"
          className={`text-${statusColor(statusEnumToValue(status.status))}`}
        />
        {" " + moment(status.lastChangedDate).format("YYYY/MM/DD")}
      </span>
    )
  } else {
    return ""
  }
}

export const downloadLinkRenderer = (params: ICellRendererParams) => {
  return (
    <a href={params.data.url} target={"_blank"} className="fake-link">
      {params.value}
    </a>
  )
}

export interface FormCellRendererProps extends ICellRendererParams{
  values: {
    editMode: boolean
  }
}

export const calendarCellRenderer = (params: FormCellRendererProps) => {
  let icon = params.values.editMode ? (<FontAwesomeIcon icon={["far", "calendar"]} className="ag-cell-right-icon text-blue-80"/>) : (<></>)
  return (<span>{params.valueFormatted}{icon}</span>)
}

// supply full url for ag-grid cellRenderer.
export const glidepathLinkRenderer = (params: ICellRendererParams) => textLink({text: params.value, url: params?.data?.url})

type TooltipCellRendererProps = {
  value: string,
  toolTip: string,
}

const tooltipCellRenderer = (params: TooltipCellRendererProps) => {
  const uniqId = uniqueId()
  return (
    <>
      <span title={params.value} className='hover-transition-underline' id={"grid-tooltip-"+uniqId}>
        {params.value}
      </span>
      <UncontrolledTooltip placement="top" target={"grid-tooltip-"+uniqId}>
        {params.toolTip}
      </UncontrolledTooltip>
    </>
  )
}

export const sourceTooltipCellRenderer = (params: ICellRendererParams) => {
  return tooltipCellRenderer({value: params.valueFormatted || params.value, toolTip: `Source: ${params.data?.origin?.value}`})
}

export const importedFileLinkRenderer = (params: ICellRendererParams, disableUntilFinished: boolean = true) => {
  let isInProcess = params?.data?.status.code === "_1" && !params?.data?.messages
  let text = params?.valueFormatted || params.value
  if(disableUntilFinished && isInProcess){
    return text
  }
  return isInProcess? text: textLink({
    text,
    url: "/control-center/imported-files/" + params?.data?.id,
    showHref: true,
    newTab: false,
  })
}