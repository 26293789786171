import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import _, { cloneDeep, compact, find, first, get, orderBy, remove, some, sortBy as _sortBy, uniq } from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { Button, ButtonDropdown, Col, Container, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Row } from "reactstrap"
import Auth from "../../../Auth/Auth"
import { FormInputField } from "../../../helpers/constant"
import { JobParameter, Maybe, MeFragment, SavedJob, SavedJobFragment, usePortfolioJobsSettingsQuery, useUpdateSavedJobMutation } from "../../../__generated__/graphql"
import { MultiFilter } from "../../Org/ManagerInteractions"
import ErrorDisplay from "../../Shared/ErrorDisplay"
import { Filter, MultiplePicker, OptionItem, Picker } from "../../Shared/Picker"
import RouteLeavingGuard from "../../Shared/RouteLeavingGuard"
import EditButtons from "../../ui/EditButtons"
import { FormInput } from "../../ui/Forms/FormInput"
import Loading from "../../ui/Loading"
import PlaceHolder from "../../ui/PlaceHolder"
import { JobSettingParametersTable, PortfolioJobSettingDefinitions } from "./JobSettingFields"

interface JobSettingInputProp extends FormInputField {}

const OnInput: JobSettingInputProp = {
  property: "on",
  label: "",
  type: "checkbox",
  subtype: "on",
  subClasses: {
    inputWrapperClasses: "col-sm-12 mr-0 pr-0",
  },
}

const JobSettingInput: JobSettingInputProp[] = [
  {
    property: "friendlyName",
    label: "",
    type: "h5",
    subClasses: {
      wrapperClasses:
        "row headline gray-underline underline text-uppercase justify-content-between mx-1 mt-3 mb-0 pb-0",
    },
  },
  {
    property: "parameters",
    label: "",
    type: "",
    subClasses: {
      wrapperClasses:
        "row headline gray-underline underline text-uppercase justify-content-between mx-1 mt-3 mb-0 pb-0",
    },
  },
]

interface JobSettingParametersDisplayProps {
  data: JobParameter[]
  editMode?: boolean
  jobId: number
  settings: JobSettingParametersTable
  handleChange: (value: any, field: any) => void
}

const JobSettingParametersDisplay = (props: JobSettingParametersDisplayProps) => {
  let {data, editMode, jobId, settings, handleChange} = props
  let paramsSettings = settings?.parameters || []

  return (
    <div className="mt-2 px-3 mx-2">
      {
        data?.map(({parameter: field, value: propertyVal, friendlyName: label}, idx) => {
          let onChangeCallback = (value: any) => handleChange(value, field)

          if(!field) {
            return <React.Fragment key={idx}></React.Fragment>
          }
          let displayName = label || field
          let { type, subtype, subClasses, options } = paramsSettings?.find((param) => param.parameter === field) || {}
          if(!type && !subClasses) {
            // default text css formatting
            subClasses = {
              labelClasses: "col-sm-4",
              inputWrapperClasses: "col-sm-8 pr-1",
            }
          }
          return (
            <FormInput
              key={idx}
              property={field}
              displayName={displayName}
              type={type || "text"}
              subtype={subtype}
              idx={`${jobId}-${idx}-${field}`}
              editMode={!!editMode}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              subClasses={subClasses}
              options={options}
            />
          )
        })
      }
    </div>)
}

type SingleJobSettingProps = {
  job?: SavedJobFragment // initial query data
  jobId: number
  editMode?: boolean
  saving: boolean // saving status for all jobs/edit button.
  setSavingCallCount: React.Dispatch<React.SetStateAction<number>>
}

type ParametersTrackerType = {
  [parameter: string]: string
}


const SingleJobSetting: React.FC<SingleJobSettingProps> = (props) => {
  let { job, editMode, saving, setSavingCallCount } = props
  const [state, setState] = useState<Maybe<SavedJobFragment>>(job || null)
  // save edited parameters.
  const [parameterTracker, setParameterTracker] = useState<ParametersTrackerType>({})
  
  // local saving status for current job
  const [onSaving, setOnSaving] = useState(false)
  const [updateSavedJob] = useUpdateSavedJobMutation()
  // const [errorMessages, setErrorMessage] = useState<string[]>([])

  let jobId = job?.id || props.jobId

  useEffect(() => {
    if(saving) {
      setOnSaving(true)
      let paramKeys: string[] = Object.keys(parameterTracker)
      let addItems: any[] = []
      let removeItems: string[] = []
      paramKeys?.forEach((param) => {
        let {parameter, value} = job?.parameters?.find((p: JobParameter) => p.parameter === param) || {parameter: undefined, value: undefined}
        if(!parameter) {
          return
        }
        let trackerValue = parameterTracker[parameter]
        if(trackerValue !== value) {
          let add: any= {parameter, value: trackerValue}
          addItems.push(add)
          removeItems.push(parameter)
        }
      })
      let changedParams = { add: addItems, remove: removeItems}
      
      if(!addItems.length && !removeItems.length) {
        setSavingCallCount((cnt) => cnt - 1)
        setOnSaving(false)
      }else {
        updateSavedJob({variables: {input: {id: jobId, patch: {parameters: changedParams}}}}).then((result) => {
          console.log(160, {result})
          const {data} = result
          if(data?.updateSavedJob) {
            setState(data?.updateSavedJob)
          }
        }).catch((err) => {
          console.error("Error updating SavedJob:", {id: jobId, message: err.message})
        }).finally(() => {
          setOnSaving(false)
          setSavingCallCount((cnt) => cnt - 1)
        })
      }
    }else {
      if(onSaving) {
        setOnSaving(false)
      }
    }
  }, [saving])

  useEffect(() => {
    if(!editMode) {
      setState(job || null)
      setParameterTracker({})
    }
  }, [editMode])

  const toggleActivateJob = () => {
    setOnSaving(true)
    let newState = { ...state, on: !state.on }
    updateSavedJob({
      variables: {
        input: {
          id: jobId,
          patch: {  
            on: newState.on 
          },
        }
      }
    }).then(result => {
      console.log(147, "success")
      const {data} = result
      if(data?.updateSavedJob) {
        setState(data?.updateSavedJob)
      }else {
        setState(newState)
      }
      setParameterTracker({})
    }).catch((err) => {
      console.error("Error updating SavedJob on field:", {id: jobId, message: err.message})
      setState(newState)
    }).finally(() => {
      setOnSaving(false)
    })
  }
  
  const handleParametersChange = (value: any, field: any) => {
    let {parameters} = state
    let newValue = value === null? value: value?.toString()
    let changedIdx = parameters?.findIndex((param) => param.parameter === field)
    let newParams = parameters?.map((param, idx) => {
      if(idx === changedIdx) {
        return {...param, value: newValue} as JobParameter
      }
      return param
    }) || []
    let newState = { ...state, parameters: newParams }
    setParameterTracker((tracker) => ({...tracker, [field]: newValue}))
    setState(newState)
  }
  const onComponent = () => {
    let { property, label, type, subtype, placeholder, subClasses } = OnInput
    let propertyVal = _.get(state, "on", false)
    return (
      <FormInput
        key={"on"}
        property={property}
        displayName={label}
        subClasses={subClasses}
        type={type}
        subtype={subtype}
        placeholder={placeholder}
        idx={`${jobId}-${property}`}
        editMode={!editMode}
        propertyVal={propertyVal}
        updateValue={toggleActivateJob}
      />
    )
  }

  if(onSaving) {
    return <Loading />
  }
  return (
    <div key={jobId}>
      {JobSettingInput.map(({ property, label, type, subtype, placeholder, subClasses, Component }, idx) => {
        let propertyVal = _.get(state, property, "")
        let onChangeCallback = (value: any) => {
          if (value === "true" || value === "false") {
            value = JSON.parse(value)
          }
        }

        if (type === "h5") {
          // title with on checkbox.
          return (
            <h5 className={subClasses?.wrapperClasses} key={idx}>
              <div className='mt-1 pt-1 pb-1'>{propertyVal}</div>
              {onComponent()}
            </h5>
          )
        }
        if (property === "parameters") {
          let jobName = state?.jobName
          propertyVal = _.get(state, property, []) as JobParameter[]
          return (
            <JobSettingParametersDisplay
              key={idx}
              jobId={jobId}
              data={propertyVal}
              editMode={editMode}
              settings={PortfolioJobSettingDefinitions[jobName] || []}
              handleChange={(value: any, field: any) => {
                handleParametersChange(value, field)
            }}
            />
          )
        }
        return (
          <FormInput
            key={idx}
            property={property}
            displayName={label}
            subClasses={subClasses}
            type={type}
            subtype={subtype}
            placeholder={placeholder}
            idx={`${jobId}-${idx}-${property}`}
            editMode={!!editMode}
            propertyVal={propertyVal}
            updateValue={onChangeCallback}
            // optionSource={optionSource}
            // options={options}
            // readonly={readonly}
            // required={required}
            // tooltip={tooltip}
            // defaultOptions={propertyVal}
          />
        )
      })}
    </div>
  )
}

type JobSettingsListProps = {
  clientportfolioId: number
  data: Maybe<SavedJobFragment[]>
  editMode?: boolean
  saving: boolean
  setSavingCallCount: React.Dispatch<React.SetStateAction<number>>
}

const JobSettingsList: React.FC<JobSettingsListProps> = (props) => {
  let { data, editMode, saving, setSavingCallCount } = props

  return (
    <Row className='mx-0'>
      {data?.map((job, index) => {
        return (
          <Col md={4} key={index}>
            <SingleJobSetting editMode={editMode} job={job} jobId={job.id || props.id} setSavingCallCount={setSavingCallCount} saving={saving}/>
          </Col>
        )
      })}
    </Row>
  )
}

interface JobSettingsSidebarProps {
  auth: Auth
  jobSettings: SavedJobFragment[]
  selectedJobSetting: number
  setSelectedJobId: (id:number) => void
  assetClasses?: {code: number, value: string}[]
  user?: MeFragment // filter my jobSetting
  settingsToDefaultFilter?: (filters:Filter[]) => MultiFilter[]
  afterFilter?: (filters: Filter[], activeFilters:MultiFilter[]) => void
  stayOpen?: boolean
}

export const JobSettingsSidebar:React.FC<JobSettingsSidebarProps> = (props:JobSettingsSidebarProps) => {
  let { jobSettings, assetClasses, user, settingsToDefaultFilter, afterFilter, stayOpen, auth} = props
  let userId = user?.person?.id
  const EditedFilters: Filter[] = compact([
    {
      filterId: 1,
      filterName: "Type",
      classNames: "left",
      property: "type",
      options: [
        {
          id: 0,
          name: "Type A",
          value: "a"
        },
        {
          id: 1,
          name: "Type B",
          value: "b"
        }]
    },
  ])

  const sortOptions:OptionItem[] = [
  {
    id: 0,
    name: "Type",
  },
  ]

  const [sortBy, setSortBy] = useState(1)
  const [filterByMultiple, setFilterByMultiple] = useState<MultiFilter[]>(settingsToDefaultFilter ? settingsToDefaultFilter(EditedFilters): [{filterId: 1, activeOptions: []}])

  const [activeFilters, setActiveFilters] = useState<number[]>([])
  const onFilter = (filterId: number) => {
    let newActiveFilters = cloneDeep(activeFilters)
    const index = newActiveFilters.indexOf(filterId)
    if (index === -1) {
      newActiveFilters.push(filterId)
    } else {
      newActiveFilters.splice(index, 1)
    }

    setActiveFilters(newActiveFilters)
  }

  const sortInteractionsByMultiple = (props:JobSettingsSidebarProps) =>{
    let {jobSettings} = props
    let sortedResult: SavedJobFragment[] = jobSettings.filter((i:SavedJobFragment) => {
      if(filterByMultiple.length > 0) {
        let f = filterByMultiple.every(filterType => {
          let {filterId, activeOptions} = filterType
          if(activeOptions.length > 0) {
            let filter = EditedFilters.find(element=>element.filterId === filterId)
            if(filter){
              let {property, options} = filter
              let propertyVal = _.get(i, property)
              let propertyValMatchOption = false
              if(filter.matchFunction){
                propertyValMatchOption = options.some(option=>activeOptions.includes(option.id) && filter?.matchFunction &&filter?.matchFunction(option, propertyVal))
              }else if(Array.isArray(propertyVal)) {
                propertyValMatchOption = options.some(option=>activeOptions.includes(option.id) && (propertyVal.includes(option.value) || propertyVal.some((obj:any)=> option.property && _.get(obj, option.property) === option.value)))
              }else {
                propertyValMatchOption = options.some(option=>activeOptions.includes(option.id) && option.value === propertyVal)
              }
              return propertyValMatchOption
            }
          }
          return true
        })
        return f
      }else {
        return true
      }
    })

    switch (sortBy) {
      case 1:
        sortedResult = _sortBy(sortedResult, ["type"])
        break
      default:
        sortedResult = _sortBy(sortedResult, ["type"])
        break
    }
    return sortedResult
  }

  const [sortedInteractions, setSortedInteractions] = useState(()=>sortInteractionsByMultiple(props))

  useEffect(() => {
    console.log({selected: props.selectedJobSetting})
    // if((!props.selectedJobSetting) || props.selectedJobSetting === 0){
    //   if(jobSettings.length > 0){
    //     props.setSelectedJobId(first(jobSettings)?.id || 0)
    //   }
    // }
  }, [props.selectedJobSetting])

  useEffect(() => {
    setSortedInteractions(()=>sortInteractionsByMultiple(props))
  }, [sortBy, filterByMultiple, props])

  let content = (<>
    {sortedInteractions.length > 0 && sortedInteractions.map((jobSetting: SavedJobFragment, idx) => {
        return (
          <ListGroupItem tag="a" key={jobSetting.id} className={classnames("jobSetting-item", { active: jobSetting.id === props.selectedJobSetting})} onClick={() => { props.setSelectedJobId(jobSetting.id) }}>
            <div className="jobSetting-list-details">
              <p className={classnames("jobSetting-subject")}>{ jobSetting.friendlyName }</p>
              <p className="jobSetting-type">
                Type: {jobSetting.jobName}
              </p>
              <p className="jobSetting-date">
                Last run: 
                {/* {jobSetting.jobName} */}
              </p>
            </div>
          </ListGroupItem>
        )
      })
    }
    {sortedInteractions.length == 0 &&
      <div className="p-3 text-gray-70">
        There are no jobSettings associated with this {'clientPortfolio'} with the current filters.
      </div>
    }
  </>)

  // if(componentPiece) {
  //   return content
  // }

  let hideFilters = []

  return (
    <Picker
      filters={EditedFilters.map(({filterId: id, filterName: name}) => ({id, name}))}
      // activeFilters={filterByType}
      // filters={EditedFilters}
      activeFilters={activeFilters}
      sortOptions={sortOptions}
      activeSort={sortBy}
      onFilter={onFilter}
      // columnNumber={2}
      // dropDownMenuClass="filter-dropdown"
      onSort={id => setSortBy(id)}
      searching={false}
      listClass="jobSetting-list"
      toolbarClass="jobSetting-toolbar"
      // stayOpen={stayOpen}
    >
      {content}
    </Picker>
  )
}

interface SavedJobsProps {
  savedJobs: SavedJob[]
  selectedSavedJobId: number
  selectSavedJob: (id:number) => void
}

const SavedJobsSidebar:React.FC<SavedJobsProps> = (props:SavedJobsProps) => {
  const [sortBy, setSortBy] = useState(1)
  const [activeFilters, setActiveFilters] = useState<number[]>([])
  let { savedJobs } = props

  let pickerFilters: OptionItem[] = []
  pickerFilters = []
  // Add the possible options to the
  let types = uniq(props.savedJobs.map((savedJob:SavedJob) => get(savedJob, "type.value", ""))).sort()
  types.map((type:string) => { pickerFilters.push({id: pickerFilters.length+1, name: type})})

  const sortOptions:OptionItem[] = [
    {
      id: 1,
      name: "Type",
    },
  ]


  const onFilter = (filterId: number) => {
    let newActiveFilters = cloneDeep(activeFilters)
    const index = newActiveFilters.indexOf(filterId)
    if(filterId === 1){
      if (index === -1) {
        newActiveFilters.push(filterId)
      } else {
        newActiveFilters.splice(index, 1)
      }
    } else {
      if (index === -1) {
        remove(newActiveFilters, (id) => id != 1)
        newActiveFilters.push(filterId)
      } else {
        newActiveFilters.splice(index, 1)
      }
    }
    setActiveFilters(newActiveFilters)
  }

  const onSort = (sortId: number) => {
    setSortBy(sortId)
  }


  const objectListItem = (savedJob: SavedJob, idx: number) => {
    const type = get(savedJob, "type.value")
    return (
      <ListGroupItem
        tag="a"
        key={idx}
        className={classnames({
          active: savedJob.id === props.selectedSavedJobId,
          "picker-list-item-characteristics": true,
        })}
        onClick={() => { props.selectSavedJob(savedJob?.id || -1) }}
      >
        <h5>
          {savedJob.name || savedJob.shortDescription || "Unnamed Saved Job"}
        </h5>
        <dl>
          <dd>Type: {type}</dd>
          <dt>Last Run:</dt>
        </dl>
      </ListGroupItem>
    )
  }

  const emptyMessage = () => {
    return `There are no saved jobs associated with this clientPortfolio.`
  }

  savedJobs = savedJobs
  .filter((savedJob: SavedJob) => {
    if (activeFilters.length > 0) {
      // Inverse as filters remove options
      return !some(activeFilters, (id:number) => {
        const matchingFilter = find(pickerFilters, {id: id})
        return matchingFilter?.name !== savedJob.type?.value
      })

    }
    return true
  })
  .sort((savedJobA: SavedJob, savedJobB: SavedJob) => {
    const nameA = (savedJobA.name || "").toLocaleLowerCase().trim()
    const nameB = (savedJobB.name || "").toLocaleLowerCase().trim()
    switch (sortBy) {
      case 1:
        return nameA.localeCompare(nameB)
      case 2:
        return nameB.localeCompare(nameA)
      default:
        throw new Error(`unrecognized sort choice ${sortBy}`)
    }
  })



  return (
    <Picker
      filters={pickerFilters}
      activeFilters={activeFilters}
      sortOptions={sortOptions}
      activeSort={sortBy}
      onFilter={onFilter}
      onSort={onSort}
      searching={false}
      listClass="interaction-list"
      toolbarClass="interaction-toolbar"
    >
      {savedJobs.length > 0 && savedJobs.map((o, idx) => objectListItem(o, idx))}
      {savedJobs.length == 0 &&
        <div className="p-3 text-gray-70">
          {emptyMessage()}
        </div>
      }
    </Picker>
  )
}


type idProps = {
  clientportfolioId: number
  // history?: History
  // location?: Location
  auth: Auth
}

enum SavingStatus{
  Initial = 1,
  UpdateJobParameters = 2,
  Error = 3,
}

const allowedJobTypes = ["DNA_SUMWEALTH"]

const PortfolioJobSettings: React.FC<idProps> = ({ clientportfolioId, auth }) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  let {id} = useParams() as {id: any}

  const getInitialJobId = (id:string) => {
    const jobId = parseInt(id) || 0
    if (!jobId) {
      return 0
    }else {
      return jobId
    }
  }
  const [selectedJobId, setSelectedJobId] = useState(() => getInitialJobId(id || ""))

  const [isAddSavedJobOpen, setAddSavedJobOpen] = useState(false)
  const toggleAddSavedJobDropdown = () => setAddSavedJobOpen(!isAddSavedJobOpen)

  const [isAdHocJobOpen, setAdHocJobOpen] = useState(false)
  const toggleAdHocJobDropdown = () => setAdHocJobOpen(!isAdHocJobOpen)

  const [savingStatus, setSavingStatus] = useState(SavingStatus.Initial)
  const [savingCallCount, setSavingCallCount] = useState(0)

  const { loading, error, data, refetch } = usePortfolioJobsSettingsQuery({
    fetchPolicy: "cache-first",
    variables: { id: clientportfolioId },
  })

  // const {loading: addJobDropdownLoading, error: addJobDropdownError, data: addJobDropDownData} = useSomeDataQuery

  // const {loading: addHocJobDropdownLoading, error: addHocJobDropdownError, data: addHocJobDropDownData} = useSomeDataQuery

  // TODO: can't get params
  const deleteJob = () => {
    console.log("deleted")
  }

  const runJob = () => {
    console.log("run job")
    // modal popup
  }

  const handleSubmit = () => {
    setSaving(true)
    const cnt = data?.clientPortfolio?.jobs?.length || 0
    setSavingStatus(SavingStatus.UpdateJobParameters)
    setSavingCallCount(cnt)
  }

  const selectJobId = (id: number) => {
    if(id !== selectedJobId) {
      history.push(`/clientportfolios/${clientportfolioId}/jobsettings/${id}`)
    }
  }

  useEffect(()=>{
    let newId = getInitialJobId(id ||"")
    if(newId && newId !== selectedJobId) {
      setSelectedJobId(newId)
    }
  }, [id])

  useEffect(() => {
    if(!loading && data?.clientPortfolio?.jobs?.length) {
      let firstJobId = first(data?.clientPortfolio?.jobs)?.id || 0
      if(firstJobId && !id) {
        history.replace(`/clientportfolios/${clientportfolioId}/jobsettings/${firstJobId}`)
      }
    }
  }, [loading])

  useEffect(() => {
    console.log(`370, saving is ${saving} and savingCallCount is ${savingCallCount}`)
    if(saving && savingCallCount === 0) {
      refetch().then().catch((err) => {
        console.error("Error refetching SavedJobs", {portfolio: clientportfolioId, message: err.message})
      }).finally(() => {
        setSaving(false)
        setEditMode(false)
        setSavingStatus(SavingStatus.Initial)
      })
    }
  }, [savingCallCount])

  const cancelEditMode = () => {}

  const heading = (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <div className='pane pane-toolbar sticky-top client-portfolio'>
        <div className="add-to-composite-wrapper border-right pl-1 mr-1">
          <ButtonDropdown isOpen={isAddSavedJobOpen} toggle={toggleAddSavedJobDropdown} className={"headline-dropdown add-to-composite-picker-dropdown border-blue-80 pr-1"}>
            <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link">
              {"Add Saved Job"}
            </DropdownToggle>
            <DropdownMenu onClick={(event) => event.stopPropagation()}>
              <ListGroup className="headline-dropdown-links">
                {/* {compositeList?.map((composite) => {
                  if (!composite) {
                    return <React.Fragment key={"undefined"}></React.Fragment>
                  }
                  return (
                    <ListGroupItem
                      tag="a"
                      key={`composite-${composite?.id}`}
                      onClick={(event: React.MouseEvent<any, globalThis.MouseEvent>) => {
                        event.stopPropagation()
                        if (composite.id < 0) {
                          selectComposite(composite)
                          setAddToCompositeModalOpen(true)
                        } else {
                          setSaving(true)
                          let members = selectedRows.map((portfolio) => ({ ...defaultCompositeMemberInput, memberId: portfolio.id } as CompositeMemberInputItem))
                          addPidsToComposite({
                            variables: {
                              input: {
                                // selectedComposite doesn't work.
                                id: composite.id,
                                patch: { compositeMember: { add: members } },
                              },
                            },
                          })
                            .then((result) => {
                              setSaving(false)
                              if (result?.data) {
                                addAlert({title: "Added to Composite" ,message: `${selectedRows.length} item${selectedRows.length > 1 ? "s" : ""} added to ${composite.name}`})
                                toggleAddToCompositeDropdownCollapse()
                                // history.push(`/clientportfolios/${newId}?editMode=true`)
                              }
                            })
                            .catch((err) => {
                              setSaving(false)
                              console.log("error addPidsToComposite", err.message)
                              toggleAddToCompositeDropdownCollapse()
                            })
                        }
                      }}
                      className={classnames({ divider: composite?.id === -1 })}
                    >
                      {composite?.name}
                    </ListGroupItem>
                  )
                })} */}
              </ListGroup>
            </DropdownMenu>
          </ButtonDropdown>
          <Button color="link" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={deleteJob}>
            Delete Job <FontAwesomeIcon icon={["far", "trash-alt"]} className="ml-1 text-blue-100" />
          </Button>
        </div>
        <div className="pl-1 mr-1">
          <Button color="link" className="text-callan-blue border-blue-80 btn-thin" onClick={runJob}>
            Run
          </Button>
          <ButtonDropdown isOpen={isAdHocJobOpen} toggle={toggleAdHocJobDropdown} className={"headline-dropdown add-to-composite-picker-dropdown border-blue-80 px-2 mx-1"}>
            <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link">
              {"Ad Hoc Job"}
            </DropdownToggle>
            <DropdownMenu onClick={(event) => event.stopPropagation()}>
              <ListGroup className="headline-dropdown-links">
                {/* {compositeList?.map((composite) => {
                  if (!composite) {
                    return <React.Fragment key={"undefined"}></React.Fragment>
                  }
                  return (
                    <ListGroupItem
                      tag="a"
                      key={`composite-${composite?.id}`}
                      onClick={(event: React.MouseEvent<any, globalThis.MouseEvent>) => {
                        event.stopPropagation()
                        if (composite.id < 0) {
                          selectComposite(composite)
                          setAddToCompositeModalOpen(true)
                        } else {
                          setSaving(true)
                          let members = selectedRows.map((portfolio) => ({ ...defaultCompositeMemberInput, memberId: portfolio.id } as CompositeMemberInputItem))
                          addPidsToComposite({
                            variables: {
                              input: {
                                // selectedComposite doesn't work.
                                id: composite.id,
                                patch: { compositeMember: { add: members } },
                              },
                            },
                          })
                            .then((result) => {
                              setSaving(false)
                              if (result?.data) {
                                addAlert({title: "Added to Composite" ,message: `${selectedRows.length} item${selectedRows.length > 1 ? "s" : ""} added to ${composite.name}`})
                                toggleAddToCompositeDropdownCollapse()
                                // history.push(`/clientportfolios/${newId}?editMode=true`)
                              }
                            })
                            .catch((err) => {
                              setSaving(false)
                              console.log("error addPidsToComposite", err.message)
                              toggleAddToCompositeDropdownCollapse()
                            })
                        }
                      }}
                      className={classnames({ divider: composite?.id === -1 })}
                    >
                      {composite?.name}
                    </ListGroupItem>
                  )
                })} */}
              </ListGroup>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <EditButtons
          editMode={editMode}
          setEditMode={setEditMode}
          saving={saving}
          onSubmit={handleSubmit}
          cancelEdit={cancelEditMode}
        />
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (error) {
    return (
      <Container fluid>
        {heading}
        <ErrorDisplay error={error} />
      </Container>
    )
  } else if (data) {
    const jobs = data?.clientPortfolio?.savedJobs?.filter((job) => allowedJobTypes.includes(job.jobName) || true) || []
    const idNotInJobs = !jobs.some((job) => job.id === id)
    return (
      <Container fluid>
        {heading}
        <Row>

          <SavedJobsSidebar
            savedJobs={jobs as SavedJob[]}
            selectSavedJob={(id:number) => selectJobId(id)}
            selectedSavedJobId={selectedJobId}
          />
          {/* <JobSettingsSidebar
            auth={auth}
            jobSettings={jobs}
            selectedJobSetting={selectedJobId}
            setSelectedJobId={selectJobId}
            assetClasses={[{code: 1, value: "Equity"}, {code: 2, value: "Fixed Income"}, {code: 3, value: "Alternatives"}]}
            // user={auth.user}
            // settingsToDefaultFilter={(filters) => filters}
            afterFilter={(filters, activeFilters) => console.log({filters, activeFilters})}
            stayOpen={true}
          /> */}
          <Col md="8" lg="9" className='pane pane-table'>
            SelectedJobId: {selectedJobId}
            <SingleJobSetting editMode={editMode} jobId={selectedJobId} setSavingCallCount={setSavingCallCount} saving={saving}/>
          </Col>
        </Row>
        {/* if no jobs, with id => go to jobsettings */}
        { !jobs?.length && id && <Redirect to={`/clientportfolios/${clientportfolioId}/jobsettings/`} /> }
        {/* if has jobs, no id => go to first jobsettings/firstId */}
        { jobs?.length && idNotInJobs && <Redirect to={`/clientportfolios/${clientportfolioId}/jobsettings/${first(jobs)?.id}`} /> }
        {/* <div className='pane pane-table'>
          <JobSettingsList editMode={editMode} data={jobs} clientportfolioId={clientportfolioId} setSavingCallCount={setSavingCallCount} saving={saving}/>
        </div> */}
      </Container>
    )
  } else {
    return (
      <Container fluid>
        {heading}
        <div>No data</div>
      </Container>
    )
  }
}

export default PortfolioJobSettings
