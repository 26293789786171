import iassign from "immutable-assign"
import classnames from 'classnames'
import React, { Component, useState, useRef, useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { Container, Row, Col, Nav, NavItem, NavLink, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from 'moment'
import _, { cloneDeep } from "lodash"

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import { DATE_API_FORMAT } from "../../helpers/constant"
import PlaceHolder from "./../ui/PlaceHolder"
import { ManagerType } from '../../helpers/helpers'
import { useProductWriteUpsQuery, Fact, Opinion, ProductWriteUpsQuery, useCreateFactMutation, useCreateOpinionMutation, useMeQuery, User, ResearchCategoryCode, CreateFactInput, CreateOpinionInput, ProductWriteUpsDocument, useDeleteFactMutation, useDeleteOpinionMutation, useDeleteManagerNoteMutation, DeleteInput, StatusCode, ProductOpinion, WriteupVehicleType, CreateOpinionMutation, useOrgsQuery, OrgType, useCreateManagerNoteMutation, useUpdateManagerNoteMutation, UpdateManagerNoteFields, CreateManagerNoteInput, ProductFields, ManagerNoteFragment, useDownloadProductWriteupsLazyQuery, Exact, DownloadProductWriteupsArgs, DownloadProductWriteupsQuery } from '../../__generated__/graphql'

import RouteLeavingGuard, { GuardModal, ModalScript } from "../Shared/RouteLeavingGuard"
import WriteUpComponent, { WriteUpHistory, typeMapping, ClientSelection } from "../Shared/WriteUp"
import {
  convertLookupToString,
  excludePropertyArray
} from "../../helpers/object"
import { ClientsLookup, WRITEUPS_ANCHOR_LIST } from "../Org/ManagerWriteUps"
import { dnaNoteHasChanged, factualHasChanged, productOpinionHasChanged, sortAndFilterNotes } from "../../helpers/writeUps"
import AnchorLinkMenu from "../ui/AnchorLinkMenu"
import { PersonSelection } from "../ui/Pickers/EmployeePicker"
import { DnaNote, LegacyNote } from "../../queries/extended/types/WriteUps"
import { ApolloError, ApolloQueryResult, QueryLazyOptions } from "@apollo/client"
import { IconName } from "@fortawesome/fontawesome-svg-core"

interface ProductWriteUpsSwitcherProps {
  id: number
  auth: Auth
  managerType?: ManagerType
}

interface ProductWriteUpsProps {
  id: number
  auth: Auth
  managerType?: ManagerType
  code: ResearchCategoryCode
}

// Some config values
const LOAD_LIMIT = 10
const TABLE_INCREMENT = 5 // This number <= LOAD_LIMIT
const INITIAL_TABLE_LENGTH = 5 // This number <= LOAD_LIMIT


const ProductWriteUpsSwitcher: React.FC<ProductWriteUpsSwitcherProps> = ({ id, managerType, auth }: ProductWriteUpsSwitcherProps) => {
  const params = useParams() as { subtype?: string }
  const history = useHistory()
  const match = useRouteMatch()
  let urlWithoutSubtype =
      params && params.subtype
        ? match.url.slice(0, -params.subtype.length - 1)
        : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }
  const picker = (
    <Container fluid>
      <Row>
        <Col>
          <Nav className="sub-nav sub-nav-secondary" tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/overall`) === 0
                })}
                onClick={() => handleRedirect("overall")}
              >
                Product Overall
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/people`) === 0
                })}
                onClick={() => handleRedirect("people")}
              >
                Product People
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/philosophy`) === 0
                })}
                onClick={() => handleRedirect("philosophy")}
              >
                Philosophy/Process
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/portfolio`) === 0
                })}
                onClick={() => handleRedirect("portfolio")}
              >
                Product Dynamics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/long`) === 0
                })}
                onClick={() => handleRedirect("long")}
              >
                Long Term Performance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: match.url.indexOf(`${urlWithoutSubtype}/short`) === 0
                })}
                onClick={() => handleRedirect("short")}
              >
                Short Term Performance
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </Container>
  )

  return(
    <>
      {picker}
      <Switch>
        <Route
          exact
          path="/products/:productId(\d+)/write-ups/:subtype(overall)"
          component={() =>
            <ProductWriteUps
              id={id}
              managerType={managerType}
              code={"POVER" as ResearchCategoryCode}
              auth={auth}
              key={1}
            />
          }
        />
        <Route
          exact
          path="/products/:productId(\d+)/write-ups/:subtype(people)"
          component={() =>
            <ProductWriteUps
              id={id}
              managerType={managerType}
              code={"PPEO" as ResearchCategoryCode}
              auth={auth}
              key={2}
            />
          }
        />
        <Route
          exact
          path="/products/:productId(\d+)/write-ups/:subtype(philosophy)"
          component={() =>
            <ProductWriteUps
              id={id}
              managerType={managerType}
              code={"PHIL" as ResearchCategoryCode}
              auth={auth}
              key={3}
            />
          }
        />
        <Route
          exact
          path="/products/:productId(\d+)/write-ups/:subtype(portfolio)"
          component={() =>
            <ProductWriteUps
              id={id}
              managerType={managerType}
              code={"CONST" as ResearchCategoryCode}
              auth={auth}
              key={4}
            />
          }
        />
        <Route
          exact
          path="/products/:productId(\d+)/write-ups/:subtype(long)"
          component={() =>
            <ProductWriteUps
              id={id}
              managerType={managerType}
              code={"PERF" as ResearchCategoryCode}
              auth={auth}
              key={5}
            />
          }
        />
        <Route
          exact
          path="/products/:productId(\d+)/write-ups/:subtype(short)"
          component={() =>
            <ProductWriteUps
              id={id}
              managerType={managerType}
              code={"STPERF" as ResearchCategoryCode}
              auth={auth}
              key={6}
            />
          }
        />
        <Redirect
          from="/products/:productId(\d+)/write-ups"
          to="/products/:productId(\d+)/write-ups/overall"
        />
      </Switch>
    </>
  )
}

const useForceUpdate = () =>{
  // integer state
  const [value, setValue] = useState(0);
  // update the state to force render
  return () => setValue(value => value + 1);
}

const ProductWriteUps: React.FC<ProductWriteUpsProps> = ({ id, managerType, auth, code }: ProductWriteUpsProps) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [historyDropdown, setHistoryDropdown] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState("")
  // fix author/date change remains after clicking saving when nothing saved.
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)
  const [noteIdsToDelete, setNoteIdsToDelete] = useState(new Set<number>())

  const [offset, setOffset] = useState(0)

  let { data, loading, error, refetch, fetchMore } =
  useProductWriteUpsQuery({
    variables: {
      id,
      // Fix CAL-1825
      filters:{
        researchCategory: code,
        offset,
        limit: LOAD_LIMIT
      }
    },
    fetchPolicy: "network-only"
    // partialRefetch: true,
  })

  // show in overall tabs only.
  const [getWriteupsUrl, {loading: urlLoading, data: urlData, error: urlError}] = useDownloadProductWriteupsLazyQuery({
    fetchPolicy: "network-only"
  });
  console.log(getWriteupsUrl)

  let { data: clientData, loading: clientLoading, error: clientError } =
  useOrgsQuery({
    variables: {
      limit: 4_000, // TODO: temp fix for CAL-2671, api research needed.

      type: ["Client"] as OrgType[]
    }
    // partialRefetch: true,
  })

  const [createFactMutation] = useCreateFactMutation()
  const [createOpinionMutationFunction] = useCreateOpinionMutation()
  const [createDnaNote] = useCreateManagerNoteMutation()
  const [updateDnaNote] = useUpdateManagerNoteMutation()
  const [deleteDnaNote] = useDeleteManagerNoteMutation()
  const [deleteFactMutation] = useDeleteFactMutation()
  const [deleteOpinionMutation] = useDeleteOpinionMutation()
  const [deleting, setDeleting] = useState(false)

  let {data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery({fetchPolicy: "cache-first"})
  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setErrorMessage("")
    setEditMode(!editMode)
  }
  const forceUpdateNow = useForceUpdate()

  useEffect(()=> {
    if(!editMode && forceUpdateFlag && !saving) {
      forceUpdateNow()
    }
  }, [editMode])

  const onSubmit = () => {
    // check for non-nullable status code when creating ProductOpinion
    let opinions = _.cloneDeep(resultRef!.current?.state?.editedOpinion)
    let checkPassFlag =
      Object.keys(opinions || {}).every((identifier) => {
        let opinionVersions = _.cloneDeep(resultRef!.current?.state?.opinionList[identifier]) || [] as ProductOpinion[]
        let currentOpinion = resultRef!.current?.getLatest(opinionVersions) as ProductOpinion
        // new created opinion has id < 0, back to normal id = clientId after client dropdown selected.
        if(currentOpinion) {
          return !!(currentOpinion?.opinions?.status?.code && currentOpinion?.vehicle?.code)
        }else {
          return true
        }
      })

    if(checkPassFlag) {
      handleSubmit()
    }else {
      setErrorMessage("Red fields are required.")
      return
    }
  }

  const handleSubmit = () => {
    // TODO needs refactoring. !!!!
    if(!auth.checkPermissions(["edit:writeups"])){
      return
    }
    setErrorMessage("")
    setSaving(true)
    setForceUpdateFlag(true)
    let currentFact = resultRef!.current?.getLatest(_.cloneDeep(resultRef!.current?.state?.currentFacts)) as Fact // may be undefined
    let initialFact = resultRef!.current?.getInitial(_.cloneDeep(resultRef!.current?.state?.initialFacts)) as Fact // may be undefined
    let factualEditedFlag = factualHasChanged(currentFact, initialFact)

    if (currentFact && resultRef!.current?.state?.editedFact) {
      // update only if edited or new created
      if(factualEditedFlag || currentFact?.id === initialFact?.id){
        currentFact = currentFact as Fact
        let formattedData = convertLookupToString(currentFact, false, [])
        formattedData.authorId = formattedData.author.id
        delete formattedData.author
        formattedData.category = formattedData.researchCategory
        delete formattedData.researchCategory

        const formattedRemoveNull = formattedData //_.pickBy(formattedDataRemove, _.identity);

        const updateData = {
          id: id,
          type: "PRODUCT",
          fact: excludePropertyArray(formattedRemoveNull, ["__typename", "name", "id", "lastUpdated"]),
        } as CreateFactInput

        createFactMutation({ variables: {
            input: updateData,
          },
          optimisticResponse: {
            __typename: "Mutation",
            fact: {
              ...currentFact
            }
          },
          awaitRefetchQueries: true,
          refetchQueries:[{query: ProductWriteUpsDocument, variables:{ id: id, filters:{
            researchCategory: code,
            offset,
            limit: LOAD_LIMIT
          }}}]
          // update: (cache, { data }) => {
          //   const product: any = cache.readQuery({
          //     query: ProductWriteUpsDocument,
          //     variables: { id: id },
          //   })
          //   let updatedProduct = iassign(
          //     product,
          //     (currentProduct) =>
          //       _.get(currentProduct, "product.product.writeUps.facts"),
          //     (currentFacts) => [...currentFacts, data!.fact]
          //   )
          //   cache.writeQuery({
          //     query: ProductWriteUpsDocument,
          //     variables: { id: id },
          //     data: updatedProduct,
          //   })
          // },
        })
        .then(() => {
          setSaving(false)
          setEditMode(false)
        })
        .catch((err) => {
          setSaving(false)
          console.log("Error", err.message)
        })
      }else {
        console.log("fact is not updated")
        setEditMode(false)
      }
    }

    let editedOpinionFlag:boolean = false
    Object.keys(resultRef!.current?.state?.editedOpinion || {}).map((identifier) => {
      let opinionVersions = _.cloneDeep(resultRef!.current?.state?.opinionList[identifier]) || [] as ProductOpinion[]
      let initialVersion = resultRef!.current?.getInitial(opinionVersions) as ProductOpinion
      let currentOpinion = resultRef!.current?.getLatest(opinionVersions) as ProductOpinion
      let currentVersion = currentOpinion
      let opinionEditedFlag = productOpinionHasChanged(currentOpinion, initialVersion)
      if (currentOpinion && resultRef!.current?.state?.editedOpinion[identifier] && currentOpinion.__typename === "ProductOpinion") {
        // update only if edited or new created
        if(opinionEditedFlag || currentVersion.opinions?.id === initialVersion.opinions?.id){
          currentOpinion = currentOpinion as ProductOpinion
          let formattedData = convertLookupToString(currentOpinion.opinions || {}, false, [])
          formattedData.authorId = formattedData.author.id
          delete formattedData.author
          formattedData.category = formattedData.researchCategory
          delete formattedData.researchCategory
          formattedData.clientId = formattedData.client?.id || 244
          delete formattedData.client

          const formattedRemoveNull = formattedData //_.pickBy(formattedDataRemove, _.identity);

          const updateData = {
            id: id,
            type: "PRODUCT",
            vehicle: currentOpinion.vehicle?.code,
            opinion: excludePropertyArray(formattedRemoveNull, ["__typename", "name", "id", "lastUpdated"]),
          } as CreateOpinionInput

          createOpinionMutationFunction({ variables: {
              input: updateData,
            },
            optimisticResponse: {
              __typename: "Mutation",
              opinion: {
                ...currentOpinion.opinions
              }
            } as CreateOpinionMutation,
              awaitRefetchQueries: true,
              refetchQueries:[{query: ProductWriteUpsDocument, variables:{ id: id, filters:{
                researchCategory: code,
                offset,
                limit: LOAD_LIMIT
              }}}],
              // update: (cache, { data }) => {
              //   const product: any = cache.readQuery({
              //     query: ProductWriteUpsDocument,
              //     variables: { id: id },
              //   })
              //   // console.log(414, data)
              //   let updatedProduct = iassign(
              //     product,
              //     (currentProduct) =>
              //       _.get(currentProduct, "product.product.writeUps.opinions"),
              //     (selectedProduct) => {
              //       return [...selectedProduct||[], {
              //         vehicle: _.get(currentOpinion, "vehicle")||{__typename: "vehicle"},
              //         opinions: data?.opinion,
              //       }]
              //     }
              //   )
              //   cache.writeQuery({
              //     query: ProductWriteUpsDocument,
              //     variables: { id: id },
              //     data: updatedProduct,
              //   })
              // },
          })
          .then(() => {
            // fix CAL-1410 spinning wheel saving issue
            setSaving(false)
            setEditMode(false)
          })
          .catch((err) => {
            setSaving(false)
            console.log("Error", err.message)
            // throw new Error(`${err.message}`)
          })
        }
      }
      // if any opinion changed, flag as edited
      if (!editedOpinionFlag && resultRef!.current?.state?.editedOpinion[identifier]){
        editedOpinionFlag = true
      }
    })
    // notes section
    // 1. delete notes
    let noteIdsArray = Array.from(noteIdsToDelete)
    if(noteIdsArray.length > 0) {
      Promise.all([
        ...noteIdsArray.map(noteID => deleteDnaNote({ variables: {input: {id: noteID}}}))
      ]).then(results => {
        if(results[0] && results[0].data){
          setEditMode(false)
          setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error deleting dna notes", err.message)
      })
    }

    // 2. update notes
    let currentNotes = _.cloneDeep(resultRef!.current?.state?.currentDnaNotes)
    let initialNotes = _.cloneDeep(resultRef!.current?.state?.initialDnaNotes)
    let editedNotes = _.cloneDeep(resultRef!.current?.state?.editedDnaNote)
    let editedNoteFlag:boolean = false
    Object.keys(editedNotes || {}).map((identifier) => {
      const [, stringId] = identifier.split("|")
      const noteId = parseInt(stringId)
      let initialDnaNote = initialNotes?.find(note=>note.id === noteId)

      let currentDnaNote = currentNotes?.find(note=>note.id === noteId)
      let dnaNoteEditedFlag = dnaNoteHasChanged(currentDnaNote, initialDnaNote)
      if(!initialDnaNote) {
        // new created
        if(currentDnaNote && dnaNoteEditedFlag && !!currentDnaNote.body){
          console.log("submit new note")
          let researchCode: ResearchCategoryCode = (currentDnaNote.researchCategories? (currentDnaNote.researchCategories[0]).code : code )|| code
          let updateData  = {
            body: currentDnaNote.body,
            confidential: false,
            productTags: [id],
            researchCategories: [researchCode]
          } as CreateManagerNoteInput
          createDnaNote({ variables: {
            input: updateData,
          },
          optimisticResponse: {
            __typename: "Mutation",
            createManagerNote: {
              __typename: "ManagerNotePayload",
              note: currentDnaNote
            }
          },
          awaitRefetchQueries: true,
          refetchQueries:[{query: ProductWriteUpsDocument, variables:{ id: id, filters:{
            researchCategory: code,
            offset,
            limit: LOAD_LIMIT
          }}}]
        })
        .then((result) => {
          console.log("success")
          setEditMode(false)
          setSaving(false)
        })
        .catch((err) => {
          setSaving(false)
          console.log("Error ", err)
        })
        }
      }else {
        if(currentDnaNote && dnaNoteEditedFlag){
          // updateDnaNote
          let patch  = {
            body: currentDnaNote.body,
            orgTags: [id],
          } as UpdateManagerNoteFields
          updateDnaNote({ variables: {
            input: {
              id: noteId,
              patch
            },
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateManagerNote: {
              __typename: "ManagerNotePayload",
              note: currentDnaNote
            }
          },
          awaitRefetchQueries: true,
          refetchQueries:[{query: ProductWriteUpsDocument, variables:{ id: id, filters:{
            researchCategory: code,
            offset,
            limit: LOAD_LIMIT
          }}}]
        })
        .then((result) => {
          console.log("success")
          setEditMode(false)
          setSaving(false)
        })
        .catch((err) => {
          setSaving(false)
          console.log("Error ", err)
        })
        }else if(dnaNoteEditedFlag){
          // delete
        }else if (currentDnaNote) {
          // no material change
          console.log('no change, quit')
          return
        }else {
          // not possible
          console.log('no, weird happens.')
        }
      }
      // if any note  changed, flag as edited
      if (!editedNoteFlag && resultRef!.current?.state?.editedDnaNote[identifier] && dnaNoteEditedFlag){
        editedNoteFlag = true
      }
    })

    // no change on fact or productOpinion, do not call api, quit editMode
    if ((!resultRef!.current?.state?.editedFact || !factualEditedFlag)  && (!resultRef!.current?.state?.editedOpinion || !editedOpinionFlag) && (!resultRef!.current?.state?.editedDnaNote || !editedNoteFlag) && noteIdsArray.length < 1){
      console.log('not update')
      handleEdit() // no change, like cancel edit.
      setSaving(false)
    }else {
      // setEditMode(false)
      // setSaving(false)
    }
  }

  const handleDelete = (writeUp: Fact | Opinion) => {
    if(!auth.checkPermissions(["edit:writeups"])){
      return
    }
    setDeleting(true)
    if(writeUp.__typename === "Fact"){
      const deleteData = {
        id: writeUp.id,
      } as DeleteInput

      deleteFactMutation({ variables: {
          input: deleteData,
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //     deleteFact: {
        //       __typename: "Status",
        //       status: "test",
        //       message: ""
        //     }
        // },
        update: (cache, { data }) => {
          const product: any = cloneDeep(cache.readQuery({
            query: ProductWriteUpsDocument,
            variables: { id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }},
          }))
          let updatedProduct = iassign(
            product,
            currentProduct => currentProduct.product.product.writeUps.facts,
            (selectedProduct) => {
              let rows = _.cloneDeep(selectedProduct) as Fact[]
              _.remove(rows, (v:Fact) => v.id === writeUp.id)
              return rows
            }
          )
          cache.writeQuery({
            query: ProductWriteUpsDocument,
            variables: { id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }},
            data: updatedProduct,
          })
          setDeleting(false)
        },
      })
    } else if (writeUp.__typename === "Opinion"){
      const deleteData = {
        id: writeUp.id,
      } as DeleteInput

      deleteOpinionMutation({ variables: {
          input: deleteData,
        },
        // optimisticResponse: {
        //   __typename: "Mutation",
        //     deleteOpinion: {
        //       __typename: "Status",
        //       status: "test",
        //       message: ""
        //     }
        // },
        update: (cache, { data }) => {
          const product: any = cloneDeep(cache.readQuery({
            query: ProductWriteUpsDocument,
            variables: { id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }},
          }))
          let updatedProduct = iassign(
            product,
            currentProduct => currentProduct?.product?.product?.writeUps?.opinions,
            (selectedProduct) => {
              let rows = _.cloneDeep(selectedProduct) as ProductOpinion[]
              _.remove(rows, (v:ProductOpinion) => v?.opinions?.id === writeUp.id)
              return rows
            }
          )
          cache.writeQuery({
            query: ProductWriteUpsDocument,
            variables: { id: id, filters:{
              researchCategory: code,
              offset,
              limit: LOAD_LIMIT
            }},
            data: updatedProduct,
          })
          setDeleting(false)
        },
      })
    }
  }

  const handleManagerNoteDelete = (noteId: number) => {
    // work for dnaNotes only
    let newSet = _.clone(noteIdsToDelete)
    newSet.add(noteId)
    setNoteIdsToDelete(newSet)
  }

  const downloadWriteups = () => {
    getWriteupsUrl({variables:{args:{productId: id}}})
  }

  let exportButtonComponent = <React.Fragment key={'download'}></React.Fragment>
  const LoadingButton = (
    <div className="d-flex align-items-center justify-contents-between mr-2">
      <Button className="btn-no-style text-blue-100 p-0 ml-auto">
        Downloading
        <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />
      </Button>
    </div>
  )

    exportButtonComponent = (
    <div className="d-flex align-items-center justify-contents-between mr-2" id="exportProductWriteUpsTooltipContainer">
      <Button color="secondary btn-thin" className="text-callan-blue"
        onClick={()=>downloadWriteups()}>
          Download DOCX
          <img src='/assets/DOCX.svg' className="ml-2"/>
      </Button>
      <div className="d-inline-flex align-items-center tooltip-icon" id="exportProductWriteUpsTooltip">
        <FontAwesomeIcon
          icon={"question-circle" as IconName}
          className="mt-1"
          size="sm"
          />
      </div>
    </div>
    )

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      { data && data.product && data.product.product && data.product.product.writeUps &&
        <ButtonDropdown isOpen={historyDropdown} toggle={() => setHistoryDropdown(!historyDropdown)} className="mr-2 pr-2 border-right">
          <DropdownToggle caret>
            <FontAwesomeIcon
              icon={["fas", "history"]}
              size="sm"
              className="mr-2"
            />
            View History
          </DropdownToggle>
          <DropdownMenu>
            {_.some(data.product.product.writeUps?.facts, (wu) => wu?.researchCategory?.code === code) && <DropdownItem key="fact" onClick={() => resultRef.current?.openHistory('fact')}>{WRITEUPS_ANCHOR_LIST[0].title}</DropdownItem>}
            {/* {resultRef && _.map(resultRef.current?.state.opinionList, (value, key) => {
              return (<DropdownItem key={key} onClick={() => resultRef.current?.openHistory(key)}>{`${value[0].opinions?.client?.name}`}</DropdownItem>)
            })} */}
            {resultRef.current?.state.opinionList[`s|244`] && <DropdownItem key={'s|244'} onClick={() => resultRef.current?.openHistory("s|244")}>{`Callan-Internal - ${typeMapping["s"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`k|244`] && <DropdownItem key={'k|244'} onClick={() => resultRef.current?.openHistory("k|244")}>{`Callan-Internal - ${typeMapping["s"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`q|244`] && <DropdownItem key={'q|244'} onClick={() => resultRef.current?.openHistory("q|244")}>{`Callan-Internal - ${typeMapping["s"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`s|16467`] && <DropdownItem key={'s|16467'} onClick={() => resultRef.current?.openHistory("s|16467")}>{`Callan-IAG - ${typeMapping["s"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`k|16467`] && <DropdownItem key={'k|16467'} onClick={() => resultRef.current?.openHistory("k|16467")}>{`Callan-IAG - ${typeMapping["k"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`q|16467`] && <DropdownItem key={'q|16467'} onClick={() => resultRef.current?.openHistory("q|16467")}>{`Callan-IAG - ${typeMapping["q"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`s|18737`] && <DropdownItem key={'s|18737'} onClick={() => resultRef.current?.openHistory("s|18737")}>{`Callan-UMA - ${typeMapping["s"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`k|18737`] && <DropdownItem key={'k|18737'} onClick={() => resultRef.current?.openHistory("k|18737")}>{`Callan-UMA - ${typeMapping["k"]}`}</DropdownItem>
            }
            {resultRef.current?.state.opinionList[`q|18737`] && <DropdownItem key={'q|18737'} onClick={() => resultRef.current?.openHistory("q|18737")}>{`Callan-UMA - ${typeMapping["q"]}`}</DropdownItem>
            }
            {resultRef && _.map(resultRef.current?.state.sortedExistingOpinionIds, (opinionInfo, idx) => {
              let {clientId, opinionId, vehicle} = opinionInfo
              let identifier = `${vehicle}|${clientId}`
              let opinions = resultRef.current?.state.opinionList[identifier]
              let clientName = ""
              if(opinions && opinions[0]?.opinions?.client?.name){
                clientName = opinions[0]?.opinions?.client?.name
              }
              if(clientId === `244` || clientId === `16467` || clientId === `18737`) {
                return <React.Fragment key={`${clientId}-${opinionId}`}></React.Fragment>
              }
              return <DropdownItem key={`${clientId}-${opinionId}`} onClick={() => resultRef.current?.openHistory(identifier)}>{`${clientName} - ${typeMapping[vehicle || "s"]}`}</DropdownItem>
            }
            )}
          </DropdownMenu>
        </ButtonDropdown>
      }
      {urlLoading ? LoadingButton : !editMode &&
        exportButtonComponent}
      {errorMessage &&
        <span className="text-accent-red py-2 ml-2 d-flex">
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]}/>
          &nbsp; {`Save Error: ${errorMessage}`}
        </span>
      }
      {auth.checkPermissions(["edit:writeups"]) &&
        <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={onSubmit} cancelEdit={handleEdit}/>
      }
    </div>
  )

  if (loading || currentUserLoading) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <div className="pane pane-table">
                <PlaceHolder />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  if (error || currentUserError) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <div className="pane pane-table">
                <p>{error?.message}</p>
                <p>{currentUserError?.message}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  if (currentUserData && currentUserData.me && data && data.product && data.product.product) {
    const currentUser = currentUserData.me as User
    const product = data.product.product
    const factWriteUps = _.groupBy(product.writeUps?.facts, "researchCategory.code")
    const opinionWriteUps = _.groupBy(product.writeUps?.opinions, "opinions.researchCategory.code")

    let {legacyNotes, dnaNotes} = sortAndFilterNotes(product?.notes)
     // only show on product overview tab
    let showExportButton = code === "POVER"
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <Result
                facts={factWriteUps[code] as Fact[]}
                opinions={opinionWriteUps[code] as ProductOpinion[]}
                dnaNotes={dnaNotes}
                legacyNotes={legacyNotes}
                editMode={editMode}
                managerType={managerType}
                code={code}
                auth={auth}
                ref={resultRef}
                currentUser={currentUser}
                handleDelete={(writeUp:Fact | Opinion) => handleDelete(writeUp)}
                setEditMode={(value:boolean) => setEditMode(value)}
                handleManagerNoteDelete={(noteId:number) => handleManagerNoteDelete(noteId)}
                clients={_.compact(clientData?.orgs?.filter((client: any) =>  client.type.code === "FUNDS")) as ClientSelection[]}
                deleting={deleting}
                forceUpdate={forceUpdateFlag}
                refetchQuery={() =>refetch({id, filters:{
                  researchCategory: code,
                  offset,
                  limit: LOAD_LIMIT
                }})}
                setOffset={setOffset}
                offset={product?.notes?.length || 0}
                fetchMore={fetchMore}
                productId={id}
                downloadUrl={showExportButton? getWriteupsUrl: undefined}
                urlLoading={showExportButton? urlLoading: undefined}
                urlError={showExportButton? urlError: undefined}
                urlData={showExportButton? urlData: undefined}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  facts: Fact[]
  opinions: ProductOpinion[]
  dnaNotes?: DnaNote[]
  legacyNotes?: LegacyNote[]
  editMode: boolean
  code: ResearchCategoryCode
  auth: any
  managerType?: ManagerType
  currentUser: User
  handleDelete: (writeUp: Fact | Opinion) => void
  handleManagerNoteDelete: (noteId: number) => void
  setEditMode: (value: boolean) => void
  clients: ClientSelection[]
  deleting: boolean
  forceUpdate: boolean
  refetchQuery: () => Promise<ApolloQueryResult<ProductWriteUpsQuery>>
  fetchMore: any
  setOffset: (offset: number) => void
  offset: number
  productId: number
  // onChange: (oldState:AssetsByClientQuery, newState:AssetsByClientQuery) => void
  downloadUrl?: (options?: QueryLazyOptions<Exact<{args: DownloadProductWriteupsArgs}>> | undefined ) => void
  urlLoading?: boolean
  urlData?: DownloadProductWriteupsQuery | undefined
  urlError?: ApolloError | undefined
}

const vehicleTypeCodeOrder = {'s': 0, 'k': 1, 'q': 2} as {[code: string]: number}

const vehicleSort = (opinion: {
  clientId: string;
  clientName: string | undefined;
  opinionId: number;
  vehicle: string
}) => {
  let code = opinion.vehicle || 'k'
  return vehicleTypeCodeOrder[code]
}

// script for UrlErrorMessageModal CAL-1951
const urlErrorMessageScript: ModalScript = {
  header: "Download Writeups Error",
  body: "An error occurred, and Word could not be generated. Please click on the help icon next to Download button for instructions on how to use this feature.",
  leaveButtonContent: "",
  stayButtonContent: "Confirm"
}

interface ResultState {
  currentFacts: Fact[]
  currentOpinions: ProductOpinion[]
  currentDnaNotes: DnaNote[]
  editedFact: boolean
  editedOpinion: { [key: string]: boolean }
  editedDnaNote: { [key: string]: boolean } // {[`note|${id}`]: boolean}
  initialFacts: Fact[]
  initialOpinions: ProductOpinion[]
  initialDnaNotes: DnaNote[]
  legacyNotes: LegacyNote[]
  historyModal: boolean
  historySelection: string
  historyId: number
  addOpinionDropdown: boolean
  addClientDropdown: boolean
  nextOpinionId: number
  opinionList: OpinionList
  sortedNewOpinionIds: {opinionId: number, clientId: string, vehicle: string}[] // new added
  sortedExistingOpinionIds: {opinionId: number, clientId: string, vehicle: string}[] // existing ones
  tableLength: number
  activeAnchorLinks: {[id:string]: boolean}
  loadingMore: boolean
  offset: number
  showTableIncrement: boolean
  downloadUrl?: (options?: QueryLazyOptions<Exact<{args: DownloadProductWriteupsArgs}>> | undefined ) => void
  urlLoading?: boolean
  urlData?: DownloadProductWriteupsQuery | undefined
  urlErrorMessage?: string | undefined
  urlErrorModalOpen?: boolean
}

interface OpinionList {
  [key: string]: ProductOpinion[]
}

class Result extends Component<Props, ResultState> {
  constructor(props: Props) {
    super(props)
    this.openHistory = this.openHistory.bind(this)
    this.getOpinionList = this.getOpinionList.bind(this)
    const {downloadUrl, urlLoading} = props
    this.state = {
      currentFacts: this.props.facts || [],
      currentOpinions: this.props.opinions || [],
      currentDnaNotes: this.props.dnaNotes || [],
      editedFact: false,
      editedOpinion: {},
      editedDnaNote: {},
      initialFacts: this.props.facts || [],
      initialOpinions: this.props.opinions || [],
      initialDnaNotes: this.props.dnaNotes || [],
      legacyNotes: this.props.legacyNotes || [], // not editable
      historyModal: false,
      historySelection: "",
      historyId: 0,
      addOpinionDropdown: false,
      addClientDropdown: false,
      nextOpinionId: -1,
      opinionList: this.getOpinionList(),
      sortedNewOpinionIds: [] as {opinionId: number, clientId: string, vehicle: string}[],
      sortedExistingOpinionIds: [] as {opinionId: number, clientId: string, vehicle: string}[],
      tableLength: INITIAL_TABLE_LENGTH, // visible table length
      activeAnchorLinks: {},
      loadingMore: false,
      offset: this.props.offset,
      showTableIncrement: true,
      downloadUrl: downloadUrl,
      urlLoading: !!urlLoading,
      urlErrorMessage: "",
      urlErrorModalOpen: false,
    }
  }

  componentDidMount = () => {
    this.setSortAll(this.props.opinions, this.props.dnaNotes || [])
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !_.isEqual(prevProps.facts, this.props.facts) ||
      !_.isEqual(prevProps.opinions, this.props.opinions) || !_.isEqual(prevProps.dnaNotes, this.props.dnaNotes) || !_.isEqual(prevProps.legacyNotes, this.props.legacyNotes)) {
      this.setState({
        currentFacts: this.props.facts || [],
        currentOpinions: this.props.opinions || [],
        currentDnaNotes: this.props.dnaNotes || [],
        initialFacts: this.props.facts || [],
        initialOpinions: this.props.opinions || [],
        initialDnaNotes: this.props.dnaNotes || [],
        // fix can't add opinion after deleting one.
        // TODO check if these two lines are needed.
        // editedOpinion: {},
        // editedFact: false
      }, ()=>{this.setState({opinionList: this.getOpinionList()}, ()=>this.setSortAll(this.props.opinions, this.props.dnaNotes || []))})
    }else if (prevProps.editMode && !this.props.editMode) {
      if(this.props.forceUpdate) {
        this.props.refetchQuery().then(()=>this.setTableLength(5))
      }else {
        this.setTableLength(5)
      }
    }else if(!prevProps.editMode && this.props.editMode){
      this.addNote()
    }
    let {urlData, urlLoading, urlError} = this.props
    if(prevProps.editMode || this.props.editMode){
      return
    }
    if(!prevProps.urlData && urlData) {
      let url = urlData?.download?.url
      if(!!url) {
        console.log('Download started')
        const downloadLink = document.createElement("a")
        downloadLink.href = url
        downloadLink.download = "download"
        downloadLink.click()
        setTimeout(()=> {this.setState({ urlLoading })}, 2000);
      } else {
        console.error('no url')
        this.setState({urlErrorMessage: "", urlLoading}, () => this.setUrlErrorModalVisible(true))
      }
    }else if(!prevProps.urlError && urlError) {
      this.setState({urlErrorMessage: `${urlError.message}.`, urlLoading}, () => this.setUrlErrorModalVisible(true))
    }else if(prevProps.urlLoading !==  urlLoading) {
      this.setState({urlLoading})
    }else {
      console.log("exclusion")
    }
  }

  sortOpinionIds = (opinions: ProductOpinion[]) => {
    let opinionList = _.groupBy(
      opinions || [],
      (opinion) => opinion.vehicle?.code + "|" + opinion.opinions?.client?.id || "0"
    ) as OpinionList
    let keys = Object.keys(opinionList)
    let allIdsMap = keys.map(identifier=>{
      let [vehicleCode, clientId] = identifier.split("|")
      let allVersions = opinionList[identifier] as ProductOpinion[]
      return {clientId, clientName: allVersions[0]?.opinions?.client?.name, opinionId: allVersions[0]?.opinions?.id || 0, vehicle: vehicleCode}
    })
    let newOpinions = allIdsMap.filter(opinion=>opinion.opinionId< 1)
    let existingOpinions = allIdsMap.filter(opinion=> opinion.opinionId > 0)
    let sortedNewOpinions = _.sortBy(newOpinions, o=>o.opinionId)
    let sortedExistingOpinions = _.sortBy(existingOpinions,  ["clientName", vehicleSort])
    let sortedNewOpinionIds = sortedNewOpinions.map(o=>({opinionId: o.opinionId, clientId: o.clientId, vehicle:o.vehicle}))
    let sortedExistingOpinionIds = sortedExistingOpinions.map(o=>({opinionId: o.opinionId, clientId: o.clientId, vehicle:o.vehicle}))
    return {sortedNewOpinionIds, sortedExistingOpinionIds}
  }

  setSortOpinionIds = (opinions: ProductOpinion[]) => {
    let {sortedNewOpinionIds, sortedExistingOpinionIds} = this.sortOpinionIds(opinions)
    this.setState({
      sortedNewOpinionIds,
      sortedExistingOpinionIds
    })
  }

  setSortAll = (opinions: ProductOpinion[], dnaNotes?: DnaNote[]) => {
    let {sortedNewOpinionIds, sortedExistingOpinionIds} = this.sortOpinionIds(opinions)
    let currentDnaNotes = dnaNotes || this.state.currentDnaNotes
    let {legacyNotes} = this.props
    let {tableLength} = this.state
    let dataLength = (currentDnaNotes || []).length + (legacyNotes || []).length
    let initialTableLength = Math.min(dataLength, tableLength)
    this.setState({
      sortedNewOpinionIds,
      sortedExistingOpinionIds,
      tableLength: initialTableLength,
      currentDnaNotes,
      showTableIncrement: dataLength >= tableLength
    })
  }

  resetForm = () => {
    this.setState({
      currentFacts: this.state.initialFacts,
      currentOpinions: this.state.initialOpinions,
      currentDnaNotes: this.props.dnaNotes || [],
      editedFact: false,
      editedDnaNote: {},
      editedOpinion: {},
    }, ()=>this.setSortAll(this.state.initialOpinions, this.state.initialDnaNotes))
  }

  handleFactChange = (
    value: any,
    property: string,
    id: number
  ) => {
    if(!this.props.editMode) {
      return
    }
    if(!this.state.editedFact) {
      // fix CAL-1410 saving issue caused by not focusing when click editor.
      this.handleFocus("fact")
      return
    }
    let oldState = cloneDeep(this.state.currentFacts)
    let newState = iassign(
      oldState,
      selectedTable => {
        let rows = cloneDeep(selectedTable)
        var selectedRow = _.find(rows, (o) => {return (o.id === id)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        }
        return rows
      }
    )
    this.setState({ currentFacts: newState, editedFact: true})
  }

  handleOpinionChange = (
    value: any,
    property: string,
    id: number,
    identifier: string
    ) => {
      if(!this.props.editMode) {
        return
      }else if(!this.state.editedOpinion[identifier]) {
        this.handleFocus(identifier)
        return
      }
    let editedOpinion = {} as { [key: string]: boolean}
    let oldState = cloneDeep(this.state.currentOpinions)
    let newState = iassign(
      oldState,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable)
        var selectedRow = _.find(rows, (o) => {return (o.opinions?.id === id)})
        if(selectedRow){
          if(property === "vehicle"){
            _.set(selectedRow, property, value)
          } else {
            _.set(selectedRow, "opinions."+property, value);
          }
        }
        let opinion = cloneDeep(this.state.editedOpinion)
        let editedOpinionId = `selectedRow?.vehicle?.code}|${selectedRow?.opinions?.client?.id}`
        if(!opinion[editedOpinionId]) {
          _.set(opinion, [editedOpinionId], true)
        }
        editedOpinion = iassign(
          opinion,
          [editedOpinionId],
          () => true
        )
        return rows
      }
    )
    this.setState({
      currentOpinions: newState,
      editedOpinion
    }, ()=> this.setState({opinionList: this.getOpinionList(), ...this.sortOpinionIds(newState)}))
  }

  handleNoteChange = (
    value:string | PersonSelection,
    property: string,
    id: number
  ) => {
    if(!this.props.editMode) {
      return
    }else {
      // ???
      let editedNote = {} as {[key: string]: boolean}
      let oldState = cloneDeep(this.state.currentDnaNotes)
      let newState = iassign(
        oldState,
        selectedTable => {
          let rows = _.cloneDeep(selectedTable)
          var selectedRow = _.find(rows, (o) => {return (o.id === id)})
          if(selectedRow){
            _.set(selectedRow, property, value);
          }
          let note = cloneDeep(this.state.editedDnaNote)
          if(!note[`note|${selectedRow?.id}`]) {
            _.set(note, [`note|${selectedRow?.id}`], true)
          }
          editedNote = iassign(
            note,
          [`note|${selectedRow?.id}`],
          () => true
          )
          return rows
      })
      this.setState({
        currentDnaNotes: newState,
        editedDnaNote: editedNote
      })
    }
  }

  handleFocus = (type: string) => {
    // this function works for first focus, to switch to currentUser and current date
    if(!this.props.editMode) {
      return
    }
    if(type=== "fact" && !this.state.editedFact){
      let rows = _.cloneDeep(this.state.currentFacts)
      let newRow = _.cloneDeep(this.getLatest(rows)) as Fact
      if(newRow){
        newRow.author = this.props.currentUser?.person || newRow.author
        newRow.date = moment().format(DATE_API_FORMAT)
        newRow.lastUpdated = moment().format("YYYY-MM-DD HH:mm:ss")
        newRow.id = -1
        newRow.__typename = "Fact"
      } else {
        newRow = {
          __typename: "Fact",
          id: -1,
          author: this.props.currentUser.person,
          date: moment().format(DATE_API_FORMAT),
          lastUpdated: moment().format("YYYY-MM-DD HH:mm:ss"),
          text: "",
          bullets: "",
          researchCategory: {
            __typename: "ResearchCategoryLookup",
            value: "",
            code: this.props.code
          }
        }
      }
      rows.push(newRow)
      this.setState({currentFacts: rows, editedFact: true})
    } else if (!this.state.editedOpinion[type] && type !== "fact"){
      // productOpinion
      let rows = _.cloneDeep(this.state.currentOpinions)
      let newRow = _.cloneDeep(this.getLatest(this.state.opinionList[type])) as ProductOpinion
      if(newRow && newRow.opinions){
        newRow.__typename = "ProductOpinion"
        newRow.opinions.author = this.props.currentUser.person
        newRow.opinions.date = moment().format(DATE_API_FORMAT)
        newRow.opinions.lastUpdated = moment().format("YYYY-MM-DD HH:mm:ss")
        newRow.opinions.id = this.state.nextOpinionId
        newRow.opinions.__typename = "Opinion"
      } else {
        const [vehicleCode, clientId] = type.split("|")
        const client = _.find(ClientsLookup, ["id", parseInt(clientId)]) || {id: this.state.nextOpinionId, name: "Choose Client", __typename: "Manager"}
        newRow = {
          __typename: "ProductOpinion",
          opinions: {
            __typename: "Opinion",
            id: this.state.nextOpinionId,
            author: this.props.currentUser.person,
            date: moment().format(DATE_API_FORMAT),
            lastUpdated: moment().format("YYYY-MM-DD HH:mm:ss"),
            meritsText: "",
            meritsBullets: "",
            considerationsText: "",
            considerationsBullets: "",
            status: {
              __typename: "statusLookup",
              code: "" as StatusCode, // no default value
              value: "Please Select",
            },
            researchCategory: {
              __typename: "ResearchCategoryLookup",
              value: "",
              code: this.props.code
            },
            client: client
          },
          vehicle: {
            // !!!important couldn't be empty in creation,
            code: vehicleCode as WriteupVehicleType,
            value: "",
            __typename: "WriteupVehicleLookup"
          }
        }
      }
      rows.push(newRow)
      let oldOpinion = cloneDeep(this.state.editedOpinion)
      if(!oldOpinion[type]) {
        _.set(oldOpinion, [type], true)
      }
      let editedOpinion = iassign(
        oldOpinion,
        [type],
        () => true
      )

      let newOpinionList = _.groupBy(
        rows || [],
        (opinion) => (opinion.vehicle?.code + "|" + opinion.opinions?.client?.id || "0")) as OpinionList

      this.setState({
        currentOpinions: rows,
        editedOpinion: editedOpinion,
        nextOpinionId: this.state.nextOpinionId - 1,
        opinionList: newOpinionList}, () => this.setSortOpinionIds(rows))
    } else if(type !== "fact"){
      console.log('not a new ', type)
    }
  }

  handleNoteFocus = (type: string) => {
    // this function works for first focus, to switch to currentUser and current date
    if(!this.props.editMode) {
      return
    }
    if(!this.state.editedDnaNote[type]) {
      const [, id] = type.split("|")
      let rows = _.cloneDeep(this.state.currentDnaNotes)
      let dnaNotes = _.filter(rows, note=>note.legacy === false)
      let note = dnaNotes.find(note=>note.id === parseInt(id)) // existing
      if(note) {
        note.author = this.props.currentUser.person
        // NOTE: updateDate here is in fact DateTime
        note.updateDate = moment().format("YYYY-MM-DD HH:mm:ss")
        rows = rows.map(el=> {
          if(el.id === parseInt(id)) {
            return note as DnaNote
          }
          return el
        })
      }else {
        note = {
          __typename: "ManagerNote",
          id: this.state.nextOpinionId,
          author: this.props.currentUser.person,
          createDate: moment().format(DATE_API_FORMAT),
          title: "",
          body: "",
          legacy: false,
          researchCategories: [{
            __typename: "ResearchCategoryLookup",
            value: "",
            code: this.props.code
          }
          ],
        }
        rows.unshift(note)
      }
      let dnaNote = cloneDeep(this.state.editedDnaNote)
      if(!dnaNote[type]) {
        _.set(dnaNote, [type], true)
      }
      let editedDnaNote = iassign(
        dnaNote,
        [id],
        () => true
      )
      this.setState({
        currentDnaNotes: rows,
        editedDnaNote: editedDnaNote,
        nextOpinionId: this.state.nextOpinionId - 1,
        tableLength: this.state.tableLength + 1
      })
    }else {
      console.log('not a new ', type)
    }
  }

  createNewClient = () => {
    // TODO default vehicle type code?
    this.handleFocus(`s|${this.state.nextOpinionId}`)
  }

  addNote = () => {
    console.log('add note')
    this.handleNoteFocus(`note|${this.state.nextOpinionId}`)
  }

  removeNote = (noteId: number) => {
    let {currentDnaNotes} = this.state
    let newNotes = _.filter(currentDnaNotes, note=>note.id !== noteId)
    this.setState({currentDnaNotes: newNotes}, ()=>this.props.handleManagerNoteDelete(noteId))
  }

  openHistory = (
    type:string
  ) => {
    if(type === "fact"){
      this.setState({historyId: (this.getLatest(this.state.currentFacts) as Fact)?.id, historySelection: "fact", historyModal: true})
    } else {
      this.setState({historyId: ((this.getLatest(this.state.opinionList[type]) as ProductOpinion)?.opinions as Opinion)?.id, historySelection: type, historyModal: true})
    }
    // this.forceUpdate()
  }

  getLatest = (list: Fact[] | ProductOpinion []) => {
    if(!list || list.length === 0){
      return undefined
    }
    if(list[0].__typename === "Fact"){
      const factList = list as Fact[]
      return _.maxBy(factList, (f) => moment(f.lastUpdated).valueOf())
    } else if (list[0].__typename === "ProductOpinion"){
      const opinionList = list as ProductOpinion[]
      return _.maxBy(opinionList, (f) => moment(f.opinions?.lastUpdated).valueOf())
    }
  }

  // find the latest saved version before current round of edit, if no previous version return current edit version
  getInitial = (list: Fact[] | ProductOpinion []) => {
    if(!list || _.isUndefined(list)||list.length === 0){
      return undefined
    }
    if(list[0].__typename === "Fact"){
      const factList = list as Fact[]
      return _.maxBy(factList, (f) => f.id) as Fact
    } else if (list[0].__typename === "ProductOpinion"){
      const opinionList = list as ProductOpinion[]
      return _.maxBy(opinionList, (f) => f?.opinions?.id) as ProductOpinion
    }
  }

  handleEdit = () => {
    this.props.setEditMode(!this.props.editMode)
  }

  getOpinionList = () => {
    const opinionList = _.groupBy(this.state?.currentOpinions || this.props.opinions, (opinion) => (opinion.vehicle?.code + "|" + opinion.opinions?.client?.id || "0"))
    return opinionList as OpinionList
  }

  opinionComponent = (opinionList:OpinionList, identifier: string, subtype?: "header"|"editor") => {
    const foundOpinions = opinionList[identifier] || []
    const currentOpinion = this.getLatest(foundOpinions) as ProductOpinion

    if(identifier === "s|244") {
      // split header and editor for 244 callan representative opinion
      return (
        <WriteUpComponent
          key={`callan-rep-${identifier}-${subtype}`}
          type="callanRepOpinion"
          subtype={subtype || "header"}
          writeUp={currentOpinion}
          editMode={this.props.editMode}
          edited={this.state.editedOpinion[identifier] || false}
          version={(foundOpinions).length}
          handleUpdate={(value: any,property: string)=>this.handleOpinionChange(value,property,currentOpinion?.opinions?.id || 0,identifier)}
          handleFocus={()=>this.handleFocus(identifier)}
          openHistory={()=>this.openHistory(identifier)}
          currentUser={this.props.currentUser}
      />
      )
    }
    if(!currentOpinion) return(<></>) //  is this needed? yes

    return(
      <WriteUpComponent
        key={`productOpinion-${currentOpinion.opinions?.id || identifier}`}
        type="opinion"
        writeUp={currentOpinion}
        editMode={this.props.editMode}
        edited={this.state.editedOpinion[identifier] || false}
        version={(foundOpinions).length}
        handleUpdate={(value: any,property: string)=>this.handleOpinionChange(value,property,currentOpinion?.opinions?.id || 0,identifier)}
        handleFocus={()=>this.handleFocus(identifier)}
        openHistory={()=>this.openHistory(identifier)}
        currentUser={this.props.currentUser}
        clients={this.props.clients} // needs clients list for all non-244
        clientList={true}
      />
    )
  }

  setTableLength = (length: number) => this.setState({tableLength: length})

  noteComponents = () => {
    let {currentDnaNotes, legacyNotes, tableLength} = this.state
    // show DnaNote first, only show legacy notes if there's more space.
    let currentDnaLength = currentDnaNotes.length
    let showDnaLength = Math.min(tableLength, currentDnaLength)
    let showLegacyLength = Math.min(Math.max(tableLength - currentDnaLength, 0), legacyNotes.length)
    return (
      <div className="writeup-note-contents" key={currentDnaNotes.length}>
        {currentDnaNotes.slice(0, showDnaLength).map(note=>this.dnaNoteComponent(note))}
        {legacyNotes.slice(0, showLegacyLength).map(note=>this.legacyNoteComponent(note))}
      </div>)
  }

  dnaNoteComponent = (note: DnaNote) => {
    // return <div>Dna Notes Under Construction</div>
    if(!note) return <></>
    let id = note?.id || this.state.nextOpinionId
    return (
      <WriteUpComponent
        key={`dnaNote-${id}`}
        type="dnaNote"
        writeUp={note}
        editMode={this.props.editMode}
        edited={false}
        version={0}
        handleUpdate={(value: any,property: string)=>this.handleNoteChange(value, property, id)}
        handleManagerNoteDelete={()=>this.removeNote(id)}
        handleFocus={()=>this.handleNoteFocus(`note|${id}`)}
        openHistory={()=>{}}
        currentUser={this.props.currentUser}
        clientList={false}
      />
    )
  }

  legacyNoteComponent = (note: LegacyNote) => {
    if(!note) return <></>
    return (
      <WriteUpComponent
        key={`legacy-${note?.id}`}
        type="legacyNote"
        writeUp={note}
        editMode={this.props.editMode}
        edited={false}
        version={0}
        handleUpdate={()=>{}}
        handleFocus={()=>{}}
        openHistory={()=>{}}
        currentUser={this.props.currentUser}
        clientList={false}
      />
    )
  }

  getWriteUpsAnchorLists = () => {
    if (this.props.editMode) {
      return WRITEUPS_ANCHOR_LIST
    }
    let {currentFacts, currentDnaNotes, legacyNotes, opinionList} = this.state
    let factualLinkShow = currentFacts && currentFacts.length > 0
    let callanRepOpinion = opinionList[`s|244`]
    let callanRepOpinionLinkShow = callanRepOpinion && callanRepOpinion.length > 0
    let opinionListLength = Object.keys(opinionList).length
    let clientOpinionLinkShow = callanRepOpinionLinkShow? (opinionListLength > 1) : (opinionListLength > 0)
    let privateNoteLinkShow = !_.isEmpty(currentDnaNotes) || !_.isEmpty(legacyNotes)

    let DictionaryShowMap: {[anchorId: string]: boolean} = {
      "factual": factualLinkShow,
      "callan-rep": callanRepOpinionLinkShow,
      "client-opinions": clientOpinionLinkShow,
      "private-notes": privateNoteLinkShow

    }
    return WRITEUPS_ANCHOR_LIST.map((anchorLink) =>{
      const {anchorId} = anchorLink
      const showFlag = DictionaryShowMap[anchorId]
      return {...anchorLink, hidden: !showFlag}
    })
  }

  toggleActiveAnchorLink = (id:string) => {
    let {activeAnchorLinks} = this.state
    let currentIdState = !!activeAnchorLinks[id]
    let newState = {
      ...activeAnchorLinks, [id]: !currentIdState
    }
    this.setState({activeAnchorLinks: newState})
  }

  getNoNoteCover = (type: "write-ups" | "notes" | "all") => {
    let displayMap = {
      "write-ups": {"content": "opinions or factual information","height": 220},
      "notes": {"content": "private notes","height": 220},
      "all": {"content": "opinions or factual information","height": 700}
    }
    let {setEditMode} = this.props
    let content = displayMap[type].content
    let height = displayMap[type].height
    return (
      <>
        {(type === "write-ups") && (<div className="writeup-fact-container">
          <div className="d-flex justify-content-between align-items-center px-2 writeup-fact-header-container">
            <h3 className="writeup-fact-header headline my-3">Write-ups</h3>
          </div>
        </div>)}
        <div className='d-flex flex-column w-100 align-items-center justify-content-center background-gray-10 mt-2' style={{ height }}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Button onClick={() => setEditMode(true)} color="primary" className="d-flex">
                Edit
                <FontAwesomeIcon
                  icon="pen"
                  size="xs"
                  className="ml-2"
                />
              </Button>
              <p className="text-center d-flex mt-2 mb-0">
                No {`${content}`} has been entered.
              </p>
              <p>
                <span className="fake-link" onClick={() => setEditMode(true)}>Click edit</span> to add some.
              </p>
            </div>
        </div>
      </>
    )
  }

  loadMore = () =>{
    if(this.state.loadingMore){
      return
    }
    this.setState({loadingMore: true})
    let currentVariables = {
      id: this.props.productId,
      filters:{
        offset: this.state.offset,
        limit: LOAD_LIMIT,
        researchCategory: this.props.code
      }
    }
    this.props.fetchMore({
      variables: currentVariables,
      updateQuery: (previousResult: ProductWriteUpsQuery, { fetchMoreResult } :any) => {
        if(!fetchMoreResult){
          return previousResult
        }
        // const {legacyNote,  dnaNote} = this.props
        const {legacyNotes: previousLegacyNotes,  currentDnaNotes: previousCurrentDnaNotes, initialDnaNotes: previousInitialDnaNotes} = this.state
        const newNotes = ((fetchMoreResult?.product?.product as ProductFields)?.notes || []) as ManagerNoteFragment[]
        let newLength = newNotes.length
        const {legacyNotes: newLegacyNotes,  dnaNotes: newDnaNotes} = sortAndFilterNotes(newNotes)
        const returnedState = {
          legacyNotes: [
            ...previousLegacyNotes, ...newLegacyNotes
          ],
          currentDnaNotes: [
            ...previousCurrentDnaNotes,  ...newDnaNotes
          ],
          initialDnaNotes: [
            ...previousInitialDnaNotes, ...newDnaNotes
          ],
          offset: this.props.offset + newNotes.length
        }
        this.setState({
          ...returnedState,
          loadingMore: false,
          tableLength: returnedState.legacyNotes.length + returnedState.initialDnaNotes.length,
          offset: returnedState.legacyNotes.length + returnedState.initialDnaNotes.length,
          showTableIncrement: newLength === LOAD_LIMIT
        })

        return previousResult; // ???
      }
    })
  }

  setUrlErrorModalVisible = (state: boolean) => {
    this.setState({urlErrorModalOpen: state})
  }

  render() {
    const {facts, opinions , editMode, dnaNotes, legacyNotes} = this.props
    const {tableLength, showTableIncrement} = this.state
    const currentFact = this.getLatest(this.state.currentFacts) as Fact
    const {opinionList, sortedExistingOpinionIds, sortedNewOpinionIds, urlErrorMessage} = this.state
    const writeUpList = this.state.historySelection === "fact" ? this.state.currentFacts : opinionList[this.state.historySelection]

    let showNoWriteUpCover = !editMode && _.isEmpty(facts) && _.isEmpty(opinions)
    let showNoPrivateNoteCover = !editMode && _.isEmpty(dnaNotes) && _.isEmpty(legacyNotes)
    let showAllContentCover = showNoPrivateNoteCover && showNoWriteUpCover
    let noAllContentCover = this.getNoNoteCover("all")
    let noWriteUpCover = this.getNoNoteCover('write-ups')
    let noPrivateNotesCover = this.getNoNoteCover('notes')

    let errorScript = urlErrorMessageScript
    if(urlErrorMessage) {
      errorScript = {...errorScript, body: urlErrorMessage}
    }

    return (
      <div className={classnames("pane pane-table", {cover: showAllContentCover})}>
        <WriteUpHistory
          auth={this.props.auth}
          editMode={this.props.editMode}
          historyId={this.state.historyId}
          show={this.state.historyModal}
          toggle={(value: boolean) => this.setState({ historyModal: value })}
          selectWriteUp={(id: number) => this.setState({ historyId: id })}writeUpList={writeUpList}
          //TODO
          handleDelete={(writeUp: Fact | Opinion) => {this.props.handleDelete(writeUp)}}
          deleting={this.props.deleting}
        />
        <Row>
          {showAllContentCover? (<></>):
          (<Col md={2} key={`left-column`} className="">
            <AnchorLinkMenu list={this.getWriteUpsAnchorLists()}
            linkProps={{offset: -50}}
             handleSetActive={this.toggleActiveAnchorLink}
             handleSetInactive={this.toggleActiveAnchorLink}
            />
          </Col>)
          }
          <Col md={showAllContentCover? 12: 9} key={`right-Column`}>
            {showAllContentCover?
            noAllContentCover:
          (<>
            {showNoWriteUpCover?
              noWriteUpCover:
            <>
              <GuardModal
                key={`${this.props.productId}-url-error-modal`}
                open={!!this.state.urlErrorModalOpen}
                stay={() => this.setUrlErrorModalVisible(false)}
                leave={() => this.setUrlErrorModalVisible(false)}
                script={errorScript}
                hide={{leaveButton: true}}
              />
            <WriteUpComponent
              key={"fact"}
              type="fact"
              writeUp={currentFact}
              editMode={this.props.editMode}
              edited={this.state.editedFact}
              version={this.state.currentFacts.length}
              handleUpdate={(value: any,property: string)=>this.handleFactChange(value,property,currentFact?.id || -1)}
              handleFocus={()=>this.handleFocus("fact")}
              openHistory={()=>this.openHistory("fact")}
              currentUser={this.props.currentUser}
            />
            <div className="writeup-opinion-container" key="callan-rep"
            id={WRITEUPS_ANCHOR_LIST[1].anchorId}>
              <div className="d-flex justify-content-between align-items-center px-2 writeup-opinion-header-container">
                <h3 className="headline my-3 writeup-opinion-header">{WRITEUPS_ANCHOR_LIST[1].title}
                </h3>
                {this.opinionComponent(opinionList, "s|244", "header")}
              </div>
              {this.opinionComponent(opinionList, "s|244", "editor")}
            </div>
            <div className="writeup-opinion-container" key="client-opinions"
            id={WRITEUPS_ANCHOR_LIST[2].anchorId}>
              <div className="d-flex justify-content-between align-items-center px-2 writeup-opinion-header-container mb-1">
                  <h3 className="writeup-opinion-header headline my-3">{WRITEUPS_ANCHOR_LIST[2].title}</h3>
                {this.props.editMode &&
                <Button
                  className="float-right my-1 mr-1 blue-text"
                  color="secondary"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.createNewClient()}
                  }>Add Client</Button>
                }
              </div>
              {/** TODO
               * what happened when choosing existing , hit saving trigger spinning saving
              */}
              {this.opinionComponent(opinionList, "q|244")}
              {this.opinionComponent(opinionList, "k|244")}
              {this.opinionComponent(opinionList, "|244")}
              {this.opinionComponent(opinionList, "s|16467")}
              {this.opinionComponent(opinionList, "q|16467")}
              {this.opinionComponent(opinionList, "k|16467")}
              {this.opinionComponent(opinionList, "|16467")}
              {this.opinionComponent(opinionList, "s|18737")}
              {this.opinionComponent(opinionList, "q|18737")}
              {this.opinionComponent(opinionList, "k|18737")}
              {this.opinionComponent(opinionList, "|18737")}
              {sortedNewOpinionIds.map((opinion, idx) => {
                let {opinionId, clientId, vehicle} = opinion
                if(clientId === "244" || clientId === "16467" || clientId === "18737") return (<React.Fragment key={`other-${opinionId}`}></React.Fragment>)
                return <React.Fragment key={`other-${opinionId}`}>{this.opinionComponent(opinionList, `${vehicle}|${clientId}`)}</React.Fragment>
              })}
              {sortedExistingOpinionIds.map((opinion, idx) => {
                let {opinionId, clientId, vehicle} = opinion
                if(clientId === "244" || clientId === "16467" || clientId === "18737") return (<React.Fragment key={`other-${opinionId}-${vehicle}`}></React.Fragment>)
                return <React.Fragment key={`other-${opinionId}-${vehicle}`}>{this.opinionComponent(opinionList, `${vehicle}|${clientId}`)}</React.Fragment>
              })}
            </div>
            </>}
                <div className="writeup-note-container private-note" key="private-notes" id={WRITEUPS_ANCHOR_LIST[3].anchorId}>
                  <div className="d-flex justify-content-between align-items-center px-2 writeup-note-header-container internal-note">
                    <h3 className="headline my-3 writeup-note-header">{WRITEUPS_ANCHOR_LIST[3].title}</h3>
                    <div className="text-center internal-use"> {"INTERNAL USE ONLY"}</div>
                    {this.props.editMode ?
                      (<Button
                        className="float-right my-1 blue-text mr-1"
                        color="secondary"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.addNote()}
                        }>Add Note
                      </Button>) : (<div></div>)
                    }
                    {/* add note button*/}
                  </div>
                {showNoPrivateNoteCover?
                  noPrivateNotesCover:
                <>
                  {this.noteComponents()}
                  <div className="row justify-content-center">
                    {showTableIncrement && (<Button
                      color="secondary"
                      disabled={!showTableIncrement}
                      className="mt-2"
                      onClick={() => {
                        let newLength = tableLength + TABLE_INCREMENT
                        if (newLength < this.state.initialDnaNotes.length + this.state.legacyNotes.length){
                          this.setTableLength(tableLength + TABLE_INCREMENT)
                        }else {
                          console.log('load more')
                          this.loadMore()
                        }
                      }}>
                      {"Show more notes"}
                      {this.state.loadingMore && (<FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />)}
                    </Button>)}
                    {!showTableIncrement && !this.state.loadingMore && (<Button
                      color="secondary"
                      className="mt-2"
                      disabled={!showTableIncrement}>
                      {"No more notes"}
                    </Button>)}
                  </div>
                 {tableLength < INITIAL_TABLE_LENGTH &&(<div className="dummy-block"></div>)}
                </>
                }
              </div>
            </>)}
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProductWriteUpsSwitcher
