import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApolloQueryResult, DocumentNode, useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import classNames from 'classnames'
import iassign from "immutable-assign"
import _, { compact, first, isEmpty, sortBy, union } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState, RefObject, useContext } from 'react'
import { Button, Col, Container, CustomInput, Form, FormGroup, Input, Label, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Table } from 'reactstrap'
import { File as Document, DocumentAccess, DocumentSubType, DocumentType, FileBasicInfoFragment, FileDetailsQuery, FileDetailsQueryVariables, FileFullDetailsFragment, FileTypeSubTypeMap, FileVersion, GlidePathAssociationFragment, GlidePathDocumentsDocument, ManagerAssociationFragment, ManagerDocumentsDocument, MeFragment, NewFileVersion, ProductAssociationFragment, ProductDocumentsDocument, useDownloadVersionLazyQuery, useFileTypeMappingQuery, useRemoveFileMutation, useRemoveVersionMutation, useUploadNewFileMutation, VehicleAssociationFragment, FileTypeMapType, ClientReportDocumentsDocument, PlanDocumentsFragmentDoc, PlanAssociationFragment, ClientAssociationFragment, Maybe, CreateDataLoadInput, useGetFileUploadSignedUrlLazyQuery } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField } from '../../helpers/constant'
import { convertLookupToString, excludePropertyArray } from "../../helpers/object"
import { checkMimeType, isFileEmpty } from '../../helpers/upload'
import { Association, DocumentAssociationsList, mapAssociations } from '../Associations/AssociationsList'
import { DocumentAssociationsModal } from '../Associations/AssociationsModal'
import { OptionItem, Picker } from '../Shared/Picker'
import EditButtons from '../ui/EditButtons'
import { FormInput } from "../ui/Forms/FormInput"
import PlaceHolder from '../ui/PlaceHolder'
import { GuardModal } from './RouteLeavingGuard'
import { registerRecentDocument } from '../../helpers/session'
import { downloadWithFilename } from '../../helpers/download'
import { searchDocumentColumnDef } from '../../helpers/columnDefs'
import SortableTable from './SortableTable'
import { DocumentPreviewIcon } from './DocumentPreviewIcon'
import { EditButtonContext } from '../../Context/EditButtonContext'
import { LoadTypeCodeMapping } from '../../helpers/helpers'
import { SIGNED_FILE_URL_QUERY, UPLOAD_NEW_VERSION_MUTATION } from '../../queries/File'
import { SIGNED_DATA_LOAD_URL_QUERY, UPLOAD_NEW_DATA_LOAD_VERSION_MUTATION } from '../../queries/Importer'

const DocumentTopInput: FormInputField[] = [
  {
    property: "description",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    property: "name",
    label: "File Name",
    type: "text",
    readonly: true
  },
  {
    property: "version",
    label: "Version",
    type: "text",
    readonly: true
  },
  {
    property: "type.code",
    label: "Type",
    type: "select",
    placeholder: '',
    subtype: "single",
    required: true,
    optionSource: "DocumentType",
  },
  {
    property: "subType.code",
    label: "Sub-Type",
    type: "select",
    placeholder: '',
    subtype: "single",
    required: true,
    optionSource: "DocumentSubType",
  },
  {
    property: "access.code",
    label: "Access",
    type: "select",
    placeholder: "",
    subtype: "single",
    required: true,
    optionSource: "DocumentAccess",
  },
  {
    property: "person",
    label: "Person",
    type: "text",
    readonly: true
  },
  {
    property: "asOfDate",
    label: "As Of",
    type: "date",
    subtype: "month",
    required: true,
  },
  {
    property: "updated",
    label: "Updated",
    type: "date",
    readonly: true,
    required: true,
    subClasses:{
      inputClasses: "text-left"
    }
  },
]

interface DocumentPermissionParams {
  document: Document
  page: "Manager" | "Client"
  auth: Auth
}

interface DocumentPermissionReturn {
  edit: boolean
  view: boolean
}

export const getDocumentPermission = ({document, page, auth}:DocumentPermissionParams):DocumentPermissionReturn => {
  let edit = false
  let view = false
  if(auth.checkPermissions(["edit:all_documents"])) edit = true
  if(auth.checkPermissions(["view:all_documents"])) view = true

  const hasClients = !isEmpty(document?.clients)
  if(page === "Manager"){
    if(hasClients){
      edit = false
      if(document?.access?.code === DocumentAccess._4) {
        if(auth.checkPermissions(["edit:my_documents"])) view = true
      }
    } else {
      if(document?.access?.code === DocumentAccess._3) {
        if(auth.checkPermissions(["view:client_documents"])) view = true
        if(auth.checkPermissions(["edit:client_documents"])) edit = true
      } else if(document?.access?.code === DocumentAccess._4) {
        if(auth.checkPermissions(["edit:my_documents"])) view = true
        if(auth.checkPermissions(["edit:my_documents"])) edit = true
      } else if(document?.access?.code === DocumentAccess._5) {
        if(auth.checkPermissions(["view:pe_document"])) view = true
        if(auth.checkPermissions(["edit:my_documents"])) edit = true
      } else if(document?.access?.code === DocumentAccess._6) {
        if(auth.checkPermissions(["view:pd_document"])) view = true
        if(auth.checkPermissions(["edit:my_documents"])) edit = true
      } else if(document?.access?.code === DocumentAccess._7) {
        if(auth.checkPermissions(["view:hf_document"])) view = true
        if(auth.checkPermissions(["edit:my_documents"])) edit = true
      } else if(document?.access?.code === DocumentAccess._8) {
        if(auth.checkPermissions(["view:ra_document"])) view = true
        if(auth.checkPermissions(["edit:my_documents"])) edit = true
      } else {
        if(auth.checkPermissions(["view:manager_documents"])) view = true
        if(auth.checkPermissions(["view:my_documents"])) view = true
        if(auth.checkPermissions(["edit:my_documents"])) edit = true
      }
    }
  } else if (page === "Client"){
    if(document?.access?.code === DocumentAccess._3) {
      if(auth.checkPermissions(["view:client_documents"])) view = true
      if(auth.checkPermissions(["edit:client_documents"])) edit = true
    } else if(document?.access?.code === DocumentAccess._4) {
      if(auth.checkPermissions(["edit:my_documents"])) view = true
      if(auth.checkPermissions(["edit:my_documents"])) edit = true
    } else if(document?.access?.code === DocumentAccess._5) {
      if(auth.checkPermissions(["view:pe_document"])) view = true
      if(auth.checkPermissions(["edit:my_documents"])) edit = true
    } else if(document?.access?.code === DocumentAccess._6) {
      if(auth.checkPermissions(["view:pd_document"])) view = true
      if(auth.checkPermissions(["edit:my_documents"])) edit = true
    } else if(document?.access?.code === DocumentAccess._7) {
      if(auth.checkPermissions(["view:hf_document"])) view = true
      if(auth.checkPermissions(["edit:my_documents"])) edit = true
    } else if(document?.access?.code === DocumentAccess._8) {
      if(auth.checkPermissions(["view:ra_document"])) view = true
      if(auth.checkPermissions(["edit:my_documents"])) edit = true
    } else {
      if(auth.checkPermissions(["view:my_documents"])) view = true
      if(auth.checkPermissions(["edit:my_documents"])) edit = true
    }
  }

  return {edit, view}
}

interface DocumentsSidebarProps {
  documents: Document[]
  selectedDocument: string
  selectDocument: (id:string) => void
  search: string
}

export type DocumentAssociationTypes = "Manager" | "Plan" | "Product" | "Vehicle" | "GlidePath" | "Client"| "Import"

export const DocumentsSidebar:React.FC<DocumentsSidebarProps> = (props:DocumentsSidebarProps) => {
  const [sortBy, setSortBy] = useState(1)
  const [activeFilters, setActiveFilters] = useState<number[]>([])
  let { documents, search, selectDocument, selectedDocument } = props

  let filters:OptionItem[] = []
  _.uniq(documents.map((document:Document) => document.subType?.value || "None")).sort().forEach((category:string) => { filters.push({id: filters.length, name: category})})

  const sortOptions:OptionItem[] = [
    {
      id: 1,
      name: "Most recent",
    },
    {
      id: 2,
      name: "Name ( A to Z )",
    },
    {
      id: 3,
      name: "Name ( Z to A )",
    },
  ]

  switch (sortBy) {
    case 1:
      documents = _.sortBy(documents, ["subject"])
      break
    case 2:
      documents = _.sortBy(documents, ["type"])
      break
    case 3:
      documents = _.sortBy(documents, ["importance"])
      break
  }

  const toggleFilters = (id:number) => {
    let filtered = activeFilters

    if (filtered.includes(id)) {
      _.pull(filtered, id)
    } else {
      filtered = [...filtered, id]
    }

    setActiveFilters([...filtered])
  }

  const sortDocuments = () => {
    return documents
      .filter((document: Document) => {
        if (search) {
          const name = (document.description || document.name || "").toLocaleLowerCase().trim()
          if (!name.includes(search.toLowerCase().trim()))
            return false
        } else if (activeFilters.length > 0) {
          // Inverse as filters remove options
          return _.some(activeFilters, (id:number) => {
            const matchingFilter = _.find(filters, {id: id})
            return matchingFilter?.name === (document.subType?.value || "None")
          })

        }
        return true
      })
      .sort((documentA: Document, documentB: Document) => {
        const nameA = (documentA.description || documentA.name || "").toLocaleLowerCase().trim()
        const nameB = (documentB.description || documentB.name || "").toLocaleLowerCase().trim()
        const dateA = moment(_.find(documentA.versions, ["id", documentA.versionId])?.updated).valueOf()
        const dateB = moment(_.find(documentB.versions, ["id", documentB.versionId])?.updated).valueOf()
        switch (sortBy) {
          case 1:
            return dateB - dateA
          case 2:
            return nameA.localeCompare(nameB)
          case 3:
            return nameB.localeCompare(nameA)
          default:
            throw new Error(`unrecognized sort choice ${sortBy}`)
        }
      })
  }

  const sortedDocuments = sortDocuments()

  useEffect(() => {
    if(selectedDocument === "0"){
      if(sortedDocuments.length > 0){
        selectDocument(first(sortedDocuments)?.id || "")
      }
    }
  }, [selectedDocument, selectDocument, sortedDocuments])

  return (
    <Picker
      filters={filters}
      activeFilters={activeFilters}
      sortOptions={sortOptions}
      activeSort={sortBy}
      onFilter={id => toggleFilters(id)}
      onSort={id => setSortBy(id)}
      searching={false}
      listClass="document-list"
      toolbarClass="document-toolbar"
    >
      {sortedDocuments.length > 0 && sortedDocuments.map((document: Document, idx) => {
        const documentVersion = _.find(document.versions, ["id", document.versionId])
        const sortedVersions = _.sortBy(document.versions, [i => moment(i.updated, DATE_API_FORMAT).valueOf()]).reverse()
        const versionNumber = sortedVersions.length - sortedVersions.findIndex((version) => version.id === document.versionId)
        return (
          <ListGroupItem tag="a" key={document.id} className={classNames("document-item", { active: document.id === props.selectedDocument})} onClick={() => { selectDocument(document.id) }}>
            {/* <div className="document-icon">{ typeIcon(document) }</div> */}
            <div className="document-list-details d-flex">
              <h5>{ document.description || document.name }</h5>
            </div>
            <dl>
              <dt>Last Updated:</dt>
              <dd>{moment(documentVersion?.updated, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</dd>
              <dt>Version:</dt>
              <dd>{versionNumber}</dd>
              <dt>Type / Sub-type</dt>
              <dd>{document.type?.value || "-"} / {document.subType?.value || "-"}</dd>
            </dl>
          </ListGroupItem>
        )
      })}
      {sortedDocuments.length === 0 &&
        <div className="p-3 text-gray-70">
          There are no documents associated with this manager with the current filters.
        </div>
      }
    </Picker>
  )
}

interface DisplayDocsProps {
  document: Document
  typeMapping: FileTypeSubTypeMap[]
  editMode: boolean
  refetchQuery: (variables?: FileDetailsQueryVariables | undefined) => Promise<ApolloQueryResult<FileDetailsQuery>>
  hasError?: (value:boolean) => void
  user?: MeFragment
  auth: Auth
  fullWidth?: boolean
  associationId?: number
  associationType?: Exclude<DocumentAssociationTypes, "Import">
  setEditMode: (mode:boolean) => void
  removeDocument?: (document:FileBasicInfoFragment) => void
  notEditable?: boolean
}

interface DisplayDocsState {
  currentState: Document
  initialState: Document
  hasError: boolean
  addAssociationInEditModalOpen: boolean
  associationsModalOpen: boolean
  versionModalOpen: boolean
  associations: Association[]
  sortedVersions: FileVersion[]
  editMode: boolean
}


export class DisplayDocs extends React.Component<DisplayDocsProps, DisplayDocsState> {
  constructor(props: DisplayDocsProps) {
    super(props)
    const { document: inDocument, editMode, associationType, associationId } = props

    const sortedVersions = sortBy(inDocument.versions, [i => moment(i.updated).valueOf()]).reverse()
    let allAssociations:Association[] = mapAssociations({
      managers: inDocument.managers as ManagerAssociationFragment[],
      products: inDocument.products as ProductAssociationFragment[],
      glidePaths: inDocument.glidePaths as GlidePathAssociationFragment[],
      vehicles: inDocument.vehicles as VehicleAssociationFragment[],
    })

    const sortedAssociations = sortBy(allAssociations, (association:Association) => {
      if(association.id === associationId && association.__typename === associationType){
        return 0
      }
      return 1
    })

    this.state = {
      currentState: inDocument,
      initialState: inDocument,
      associationsModalOpen: false,
      versionModalOpen: false,
      hasError: false,
      associations: sortedAssociations,
      sortedVersions: sortedVersions,
      editMode: editMode,
      addAssociationInEditModalOpen: false
    }
  }

  static getDerivedStateFromProps(props: DisplayDocsProps, state:DisplayDocsState) {
    const { document: inDocument, associationType, associationId } = props

    const sortedVersions = _.sortBy(inDocument.versions, [i => moment(i.updated).valueOf()]).reverse()
    let allAssociations:Association[] = mapAssociations({
      managers: inDocument.managers as ManagerAssociationFragment[],
      products: inDocument.products as ProductAssociationFragment[],
      glidePaths: inDocument.glidePaths as GlidePathAssociationFragment[],
      vehicles: inDocument.vehicles as VehicleAssociationFragment[],
      plans: inDocument.plans as PlanAssociationFragment[],
      clients: inDocument.clients as ClientAssociationFragment[],
    })

    const sortedAssociations = sortBy(allAssociations, (association:Association) => {
      if(association.id === associationId && association.__typename === associationType){
        return 0
      }
      return 1
    })

    return {
      currentState: props.editMode ? state.currentState : inDocument,
      initialState: inDocument,
      associationsModalOpen: state.associationsModalOpen,
      hasError: state.hasError,
      associations: sortedAssociations,
      sortedVersions: sortedVersions,
    }
  }

  componentDidMount() {
    const { document, associationType, associationId } = this.props
    if(associationType && associationId) {
      registerRecentDocument({id: document.id, name: document.description || document.name, sourceType: associationType, sourceId: associationId})
    }else {
      registerRecentDocument({id: document.id, name: document.description || document.name, sourceType: "Document", sourceId: 0})
    }
  }

  componentDidUpdate(prevProps:DisplayDocsProps) {
    if(this.props.editMode !== prevProps.editMode) {
      this.setState({editMode: this.props.editMode})
    }
  }

  addAssociation = (association:Association) => {
    if (this.state.associations.filter(a => a.__typename === association.__typename && a.id === association.id).length > 0) {
      return
    }

    let newAssocs = this.state.associations
    newAssocs.push(association)
    this.setState({ associations: [...newAssocs]})
  }

  setAssociationsModalOpen = (open:boolean) => {
    this.setState({ associationsModalOpen: open})
  }

  downloadFile = () => {
    downloadWithFilename(this.state.currentState.url || "", this.state.currentState.name)
  }

  refetchQuery = () => {
    this.setAssociationsModalOpen(false)
    this.props.setEditMode(false)
    return this.props.refetchQuery()
  }

  handleInputChange = (value: any, property: string) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = property.split(".")
    let newState = iassign(
      oldState,
      path,
      () => value
    )
    if(property === "type.code"){
      newState = iassign(
        newState,
        ["subType", "code"],
        () => null
      )
    }

    let errorTracker = false
    // DocumentTopInput.forEach((
    //   {
    //     property,
    //     required,
    //   }
    // ) => {
    //   let propertyVal: any = _.get(
    //     newState,
    //     property,
    //   )
    //   if(required && !propertyVal && property !== "updated"){
    //     errorTracker = true
    //   }
    // })
    this.setState({ currentState: newState, hasError: errorTracker }, () => {
      if(this.props.hasError){
        this.props.hasError(errorTracker)
      }
    })
  }

  setAddAssociationInEditModalOpen =(open:boolean) => {
    this.setState({addAssociationInEditModalOpen: open})
  }

  handleAddAssociationClick = () => {
    if (this.props.editMode) {
      this.setAddAssociationInEditModalOpen(true)
    }else {
      this.setAssociationsModalOpen(true)
    }
  }

  closeModal = (callback?: (() => void) | undefined) => this.setState({addAssociationInEditModalOpen: false}, callback)

  handleConfirmNavigationClick = () => this.closeModal(() => {
    this.setAssociationsModalOpen(true)
    this.props.setEditMode(false)
  })

  render = () => {
    const document = this.state.currentState
    const documentVersion = _.find(document.versions, ["id", document.versionId])
    const versionNumber = this.state.sortedVersions.length - this.state.sortedVersions.findIndex((version) => version.id === document.versionId)
    let typesForMapping = [...this.props.typeMapping || []]
    if(!typesForMapping.find((type) => type.subType === document.subType?.code &&  type.type === document.type?.code)){
      typesForMapping.push(
        {type: document.type?.code, typeOrder: typesForMapping.length, subType: document.subType?.code, subTypeOrder: typesForMapping.length, __typename: "FileTypeSubTypeMap"}
      )
    }
    const groupedTypes = _.groupBy(this.props.typeMapping, 'type')
    const callanUser = this.props.auth.checkPermissions(["view:all_managers"])
    const documentPermissions = getDocumentPermission({document: document as Document, page: ["Plan", "Client"].includes(this.props.associationType || "") ? "Client" : "Manager", auth: this.props.auth})
    const notEditableConfig = this.props.notEditable

    return (
      <Col md={this.props.fullWidth ? 12 : 8} lg={this.props.fullWidth ? 12 : 9}>
        <div className="pane">
          <Row>
            <Col>
              <h5 className="mini">DOCUMENT</h5>
              <h2 className="headline underline">{document.description || document.name}</h2>
            </Col>
          </Row>
          <Row>
            <Col md={this.props.fullWidth ? 6 : 4}>
              <DocumentPreview
                document={document}
                downloadFile={this.downloadFile}
              />
            </Col>
            <Col md={this.props.fullWidth ? 6 : 8}>
              <Row>
                <Col className="inline-headline-container">
                  <h3 className="headline underline mt-4">Details</h3>
                  {DocumentTopInput.map(
                    (
                      {
                        property,
                        label,
                        type,
                        subtype,
                        placeholder,
                        optionSource,
                        readonly,
                        tooltip,
                        subClasses,
                        required,
                      },
                      idx
                    ) => {
                      let propertyVal: any = _.get(
                        this.state.currentState,
                        property
                      )
                      let optionFilterRule
                      let editModeOverride = this.props.editMode
                      if(property === "version"){
                        propertyVal = versionNumber
                      } else if (property === "person"){
                        propertyVal = `${documentVersion?.person?.firstName} ${documentVersion?.person?.lastName}`
                      } else if (property === "updated"){
                        propertyVal = moment(documentVersion?.updated, DATE_API_FORMAT).format(DATE_API_FORMAT)
                      } else if (property === "type.code"){
                        optionFilterRule = Object.keys(groupedTypes).reduce((result, entry) => {
                          const firstEntry = _.first(groupedTypes[entry])
                          return {...result, [firstEntry?.type || ""]: firstEntry?.typeOrder }
                        }, {})
                      } else if (property === "subType.code"){
                        const fileType = this.state.currentState.type?.code || ""
                        const optionFilterMapping = groupedTypes[fileType] || []
                        optionFilterRule = optionFilterMapping.reduce((acc, { type, subType, subTypeOrder }) => ({ ...acc, [subType || ""]: subTypeOrder }), {})
                      } else if (property === "access.code"){
                        if (!(callanUser || this.props.auth.checkPermissions(["edit:my_documents"]))) {
                          editModeOverride = false
                        } else if (!callanUser && this.props.auth.checkPermissions(["edit:my_documents"])) {
                          optionFilterRule = {
                            [DocumentAccess._1]: 1,
                            [DocumentAccess._4]: 2,
                          }
                        }
                      }
                      let onChangeCallback = (value: any) =>{
                        this.handleInputChange(!!value ? value : null, property)
                      }
                      return (
                        <FormInput
                          key={idx}
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          placeholder={placeholder}
                          idx={idx}
                          editMode={editModeOverride}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                          optionSource={optionSource}
                          optionFilterRule={optionFilterRule}
                          readonly={readonly}
                          tooltip={tooltip}
                          subClasses={subClasses}
                          required={required}
                        />
                      )
                    }
                  )}
                </Col>
                <Col>
                  <h3 className="headline underline mt-4 d-flex justify-content-between">
                    <span>Associations</span>
                    {!notEditableConfig && documentPermissions.edit &&
                      <Button className="btn-no-style text-gray-50 p-0" onClick={this.handleAddAssociationClick}>
                        Add New
                        <FontAwesomeIcon icon="plus-circle" className="ml-2"/>
                      </Button>
                    }
                  </h3>
                  <DocumentAssociationsList
                    document={document as FileFullDetailsFragment}
                    associations={this.state.associations}
                    flat
                    removable={!notEditableConfig && documentPermissions.edit}
                  />
                  <DocumentAssociationsModal
                    modalOpen={this.state.associationsModalOpen}
                    setModalOpen={this.setAssociationsModalOpen}
                    document={document}
                    allAssociations={this.state.associations}
                    refetchQuery={this.refetchQuery}
                    associationType={this.props.associationType}
                  />
                  <GuardModal
                    key={`${document.id}-add-associations-inedit-modal`}
                    open={this.state.addAssociationInEditModalOpen}
                    stay={() => this.closeModal()}
                    leave={this.handleConfirmNavigationClick}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className="headline underline mt-4">Versions</h3>
                  <Table hover>
                    <thead>
                      <tr className="uppercase unbordered text-gray-80">
                        <th></th>
                        <th>Person</th>
                        <th>Updated</th>
                        <th>Reason for Change</th>
                        {this.props.auth.checkPermissions(["download:documents"]) &&
                          <th></th>
                        }
                        {documentPermissions.edit &&
                          <th></th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sortedVersions.map((version, idx) => {
                        const versionNumber = this.state.sortedVersions.length - idx

                        return(
                          <VersionRow
                            key={idx}
                            documentId={this.state.currentState.id}
                            version={version}
                            versionNumber={versionNumber}
                            row={idx}
                            auth={this.props.auth}
                            refetchQuery={this.refetchQuery}
                            hideDelete={notEditableConfig || this.state.sortedVersions.length <= 1}
                            documentPermissions={notEditableConfig? {...documentPermissions, edit: false}:documentPermissions}
                          />
                        )
                      })}
                    </tbody>
                  </Table>
                  <FileActionLinks
                    document={document}
                    auth={this.props.auth}
                    associationId={this.props.associationId}
                    associationType={this.props.associationType}
                    removeDocument={this.props.removeDocument}
                    notEditable={notEditableConfig}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }
}

interface DocumentPreviewProps {
  document: Document
  downloadFile: () => void
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  document,
  downloadFile
}) => {
  return(
    <>
      <h3 className="headline underline mt-4 mb-0">Preview</h3>
      <div className="background-gray-20 pt-5">
        <div className="d-flex align-items-center justify-content-center">
          <div className="w-50 h-50">
            <DocumentPreviewIcon document={document} />
          </div>
        </div>
        {downloadFile &&
          <div className="d-flex align-items-center justify-content-center py-3">
            <Button className="btn" color="primary" onClick={downloadFile}>
              Download Document
              &nbsp;
              <FontAwesomeIcon icon="download" />
            </Button>
          </div>
        }
      </div>
    </>
  )
}

interface VersionRowProps {
  documentId: string
  version: FileVersion
  versionNumber: number
  row: number
  auth: Auth
  refetchQuery: () => void
  hideDelete: boolean
  documentPermissions: DocumentPermissionReturn
}

const VersionRow: React.FC<VersionRowProps> = ({
  documentId,
  version,
  versionNumber,
  row,
  auth,
  refetchQuery,
  hideDelete,
  documentPermissions,
}) => {
  const [downloaded, setDownloaded] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [removeVersionMutation] = useRemoveVersionMutation()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [downloadVersionQuery, {loading, data, error}] = useDownloadVersionLazyQuery()
  const downloadVersion = () => {
    if(data){
      setDownloaded(false)
    } else {
      downloadVersionQuery({ variables: {
          id: documentId,
          version: version.id,
        },
      })
    }
  }
  // Wait until version is returned then create the download
  if(data && !error && !loading && !downloaded){
    downloadWithFilename(data.file?.url || "", data.file?.name)
    setDownloaded(true)
  }

  const removeVersion = () => {
    setDeleting(true)
    removeVersionMutation({ variables: {
        input: {
          id: documentId,
          version: version.id,
        }
      },
    })
      .then(result => {
        setDeleting(false)
        setDeleteModalOpen(false)
        refetchQuery()
      })
      .catch(err => {
        setDeleting(false)
        console.log("Error manager Documents Version delete", err.message)
        // throw new Error(`${err.message}`)
      })
  }
  return(
    <tr key={row} className={classNames({"unbordered": row === 0})}>
      <td>{versionNumber}</td>
      <td>{version.person?.firstName} {version.person?.lastName}</td>
      <td>{moment(version.updated, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</td>
      <td className="text-gray-70">{version.reason}</td>
      {auth.checkPermissions(["download:documents"]) &&
        <td className="text-right">
          {!loading && <FontAwesomeIcon icon="download" onClick={downloadVersion}/>}
          {!!loading && <FontAwesomeIcon icon="spinner-third" spin />}
        </td>
      }
      {documentPermissions.edit &&
        <td className="text-right">
          {!deleting && !hideDelete &&<FontAwesomeIcon icon="trash" onClick={() => setDeleteModalOpen(true)}/>}
          {!!deleting && <FontAwesomeIcon icon="spinner-third" spin />}
          <Modal size="md" className="mt-5" isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(!deleteModalOpen)} zIndex={1500}>
            <ModalHeader className="fee-modal-header">
              Delete Document Version
            </ModalHeader>
            <ModalBody>
              Are you sure you want to permanently delete this version <u>for all associations</u>?
            </ModalBody>
            <ModalFooter>
              {!deleting &&
                <>
                  <Button onClick={() => setDeleteModalOpen(false)} color="secondary" className="mr-1 ml-auto">Cancel</Button>
                  <Button onClick={() => removeVersion()} color="danger" className="mr-1">Delete Version</Button>
                </>
              }
              {deleting &&
                <Button color="danger" className="mr-1">
                  Deleting
                  <FontAwesomeIcon icon="spinner-third" className="ml-2" spin/>
                </Button>
              }
            </ModalFooter>
          </Modal>
        </td>
      }
    </tr>
  )
}

interface FileActionLinksProps {
  document: FileBasicInfoFragment
  associationId?: number
  associationType?: DocumentAssociationTypes
  auth: Auth
  iconOnly?: boolean
  queryDocument?: DocumentNode
  removeDocument?: (document:FileBasicInfoFragment) => void
  notEditable?: boolean
}

export const FileActionLinks: React.FC<FileActionLinksProps> = ({
  document,
  associationId,
  associationType,
  auth,
  iconOnly,
  queryDocument,
  removeDocument,
  notEditable,
}) => {
  const [deleting, setDeleting] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [versionModalOpen, setVersionModalOpen] = useState(false)
  const [removeFileMutation] = useRemoveFileMutation()
  const documentId = document.id
  const documentPermissions = getDocumentPermission({document: document as Document, page: ["Plan", "Client"].includes(associationType || "") ? "Client" : "Manager", auth})
  const hasAssociation = !!removeDocument

  const deleteDocument = () => {
    if(notEditable || !documentPermissions.edit){
      return
    }
    setDeleting(true)
    removeFileMutation({ variables: {
        input: {
          id: documentId,
        }
      },
      update: (cache, { data }) => {
        let query = undefined
        let documentLocation:string | undefined = undefined
        if(associationType === "Manager"){
          query = queryDocument || ManagerDocumentsDocument
          documentLocation = "org.documents"
        } else if(associationType === "Product"){
          query = queryDocument || ProductDocumentsDocument
          documentLocation = "product.product.documents"
        } else if(associationType === "GlidePath"){
          query = queryDocument || GlidePathDocumentsDocument
          documentLocation = "glidePath.documents"
        } else if(associationType === "Client"){
          query = queryDocument || ClientReportDocumentsDocument
          documentLocation = "org.documents"
        } else if(associationType === "Plan"){
          query = queryDocument || PlanDocumentsFragmentDoc
          documentLocation = "plan.documents"
        }

        if(query && documentLocation){
          const org: any = cache.readQuery({
            query: query,
            variables: { id: associationId },
          })
          let path = (documentLocation || "").split(".")
          let updatedOrg = iassign(
            org,
            path,
            (selectedOrg) => {
              let rows = _.cloneDeep(selectedOrg || []) as any[]
              _.remove(rows, (v:Document) => v.id === documentId)
              return rows
            }
          )
          cache.writeQuery({
            query: query,
            variables: { id: associationId },
            data: updatedOrg,
          })
        }
      },
    })
      .then(result => {
        setDeleting(false)
        setDeleteModalOpen(false)
        if(removeDocument)
          removeDocument(document)
      })
      .catch(err => {
        setDeleting(false)
        console.log("Error manager Documents Version delete", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const removeAssociation = () => {
    if(notEditable || !documentPermissions.edit){
      return
    }
    if(removeDocument) removeDocument(document)
    setDeleteModalOpen(false)
  }

  const deleteHeadingText = hasAssociation ? "Delete Document" : "Permanently Delete Document"

  return(
    <>
      <div className="d-flex justify-content-between">
        {!notEditable && auth.checkPermissions(["upload:documents"]) &&
          <Button color="link" className={classNames({"text-callan-blue": true, "px-0": iconOnly})} onClick={() => setVersionModalOpen(true)}>
            {!iconOnly && "Upload New Version"}
            <FontAwesomeIcon icon="upload" className="ml-2"/>
          </Button>
        }
        {!notEditable && documentPermissions.edit &&
          <Button color="link" className={classNames({"text-danger": true, "px-0": iconOnly})} onClick={() => setDeleteModalOpen(true)}>
            {!iconOnly && deleteHeadingText}
            <FontAwesomeIcon icon="trash" className="ml-2 text-danger"/>
          </Button>
        }
      </div>
      <VersionModal
        modalOpen={versionModalOpen}
        setModalOpen={setVersionModalOpen}
        document={document}
      />
      <Modal size="md" className="mt-5" isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(!deleteModalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
          <div className="d-flex justify-content-between w-100 text-danger">
            <div>
              {deleteHeadingText}
            </div>
            <div onClick={() => setDeleteModalOpen(false)}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={3}>
              <div className="d-flex justify-content-center">
                <FontAwesomeIcon icon="exclamation-triangle" size={"4x"} className="text-danger unlock-icon-height"/>
              </div>
            </Col>
            <Col xs={9}>
              Are you sure you want to {hasAssociation && "remove the current association or"} permanently delete this document <u>for all associations</u>?
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {!deleting &&
            <>
              <Button onClick={() => setDeleteModalOpen(false)} color="secondary" className="mr-1 ml-auto px-2">Cancel</Button>
              {hasAssociation &&
                <Button onClick={removeAssociation} color="outline-red" className="mr-1 text-danger px-2">Remove Association</Button>
              }
              <Button onClick={deleteDocument} color="danger" className="mr-1 px-2">Permanently Delete Document</Button>
            </>
          }
          {deleting &&
            <Button color="danger" className="mr-1">
              Deleting
              <FontAwesomeIcon icon="spinner-third" className="ml-1" spin/>
            </Button>
          }
        </ModalFooter>
      </Modal>
    </>
  )
}

enum uploadStatus{
  Ready = 1,
  FetchUrl = 2,
  StartUploading = 3,
  Uploading = 4,
  Error = 5,
  Cancelled = 6,
  Uploaded = 7,
}

const AddFileModalId = "add-file-modal"

interface AddFileProps {
  associationId?: number | string
  associationType: DocumentAssociationTypes
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  user?: MeFragment
  setSelectedDocument?: (documentId: string) => void
  addDocument?: (document:FileBasicInfoFragment) => void
  forceValues?: ForceValuesProps
  defaultValues?: ForceValuesProps
  queryDocument?: DocumentNode
  allowedSubtypes?: DocumentSubType[]
  inputRef?: RefObject<HTMLInputElement>
  auth: Auth
  allowedFileTypes?: string[]
  type?: "add"|"import" // add is the default
  // below required when type === "import"
  dataLoadState?: any
  templatesOptions?: {code: any, value: any}[]
  setDataLoadState?: React.Dispatch<React.SetStateAction<Maybe<CreateDataLoadInput>>>
}

const DocumentAddInput: FormInputField[] = [
  {
    property: "description",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    property: "type.code",
    label: "Type",
    type: "select",
    placeholder: '',
    subtype: "single",
    required: true,
    optionSource: "DocumentType",
  },
  {
    property: "subType.code",
    label: "Sub-Type",
    type: "select",
    placeholder: '',
    subtype: "single",
    required: true,
    optionSource: "DocumentSubType",
  },
  {
    property: "asOfDate",
    label: "As Of",
    type: "date",
    subtype: "month",
    required: true,
    containerId: AddFileModalId
  },
  {
    property: "access.code",
    label: "Access",
    type: "select",
    placeholder: "",
    subtype: "single",
    required: true,
    optionSource: "DocumentAccess",
  },
]

const HiddenClientFields: string[] = [
  "type.code",
  "access.code",
]

export interface ForceValuesProps {
  description?: string
  type?: DocumentType
  subType?: DocumentSubType
  asOfDate?: Maybe<string>
  access?: DocumentAccess
  manager_ids?: number[]
  product_ids?: number[]
  plan_ids?: number[]
  vehicle_ids?: string[]
  glidePath_ids?: number[]
  client_ids?: number[]
}

const defaultCheckForImport = {
  typeChecked: false,
  formatChecked: false,
}

const ImportedDataTypeOptions = [{
  code: null,
  value: "Client - Detailed cash flows",
  data: {template: {id: 1}, type: {code: "_2"}}
}]

const CancelNewToken = axios.CancelToken
export const AddFileModal: React.FC<AddFileProps> = ({
  modalOpen,
  setModalOpen,
  associationId,
  associationType,
  user,
  setSelectedDocument,
  addDocument,
  forceValues,
  defaultValues,
  queryDocument,
  allowedSubtypes,
  inputRef,
  auth,
  allowedFileTypes = null, // file extensions
  type = "add",
  dataLoadState,
  setDataLoadState,
  templatesOptions,
}) => {
  const baseRef = useRef<HTMLInputElement>(null)
  const uploadRef = inputRef || baseRef
  const selectTemplateRef = useRef<HTMLInputElement>(null)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [currentStatus, setCurrentStatus] = useState(uploadStatus.Ready)
  const [cancelToken] = useState(CancelNewToken.source())
  const [newFileUpload] = useUploadNewFileMutation()
  const [fileData, setFileData] = useState<File | null>()
  const [errorMessage, setError] = useState("")
  const { resetErrors } = useContext(EditButtonContext)

  const query = type === "add"? SIGNED_FILE_URL_QUERY: SIGNED_DATA_LOAD_URL_QUERY
  const [documentUploadSignedUrlQuery, {data, error}] = useLazyQuery(query, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setCurrentStatus(uploadStatus.StartUploading)
    },
    onError: () => {
      setCurrentStatus(uploadStatus.Error)
      setError(": Endpoint unavailable")
    }
  })

  const [getDataLoadTemplates, {loading: templatesLoading, data: templatesData, error: templatesError}] = useDownloadVersionLazyQuery()

  const { loading: fileTypeLoading, error: fileTypeError, data: fileTypeData } = useFileTypeMappingQuery({variables: {type: ["Client", "Plan"].includes(associationType) ? FileTypeMapType.Client : FileTypeMapType.Manager}})
  const callanUser = auth.checkPermissions(["view:all_managers"])
  const defaultAccess = callanUser ? DocumentAccess._2 : DocumentAccess._1

  const defaultDocument = (forceValues?:ForceValuesProps) => {
    // default type === "add"
    let file = {
        __typename: "File",
        id: "",
        versionId: "",
        name: "",
        mimeType: "",
        description: forceValues?.description || defaultValues?.description || "",
        type: {__typename: "DocumentTypeLookup", code: forceValues?.type || defaultValues?.type},
        subType: {__typename: "DocumentSubTypeLookup", code: forceValues?.subType || defaultValues?.subType},
        access: {__typename: "DocumentAccessLookup", code: forceValues?.access || defaultValues?.access || defaultAccess},
        lastUpdated: "",
        asOfDate: forceValues?.asOfDate || null,
      } as Document
    if(type === "import") {
      // TODO: setting for imported files.
      file = {
        __typename: "File",
        id: "",
        versionId: "",
        name: "",
        mimeType: "",
        lastUpdated: "",
      } as Document
    }
    return file
  }

  const [document, setDocument] = useState<Document>(defaultDocument(forceValues))

  const [checkForImport, setCheckForImport] = useState<{[property: string]: boolean}>(defaultCheckForImport)

  const resetModal = () => {
    if(uploadRef.current) uploadRef.current.value = ""
    setCurrentStatus(uploadStatus.Ready)
    setLoadingPercent(0)
    setFileData(null)
    setError("")
    setDocument(defaultDocument(forceValues))
    resetErrors()
  }

  const handleSetFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    let file = e.target.files && e.target.files[0]
    if (!file) {
      setError("No File selected.")
      setCurrentStatus(uploadStatus.Error)
      return
    }
    if (isFileEmpty(file)) {
      setFileData(null)
      setError("Empty File.")
      setCurrentStatus(uploadStatus.Error)
      return
    }
    // if (!checkFileSize(file)) {
    //   setFileData(null)
    //   setError(`${fileSizeLimit}MB file size limit exceeded.`)
    //   setCurrentStatus(uploadStatus.Error)
    //   return
    // }
    if (!checkMimeType(file) || (allowedFileTypes?.length && !allowedFileTypes?.includes(file.type))) {
      setFileData(null)
      setError("File type error.")
      setCurrentStatus(uploadStatus.Error)
      return
    }
    let newDocument = iassign(
      document,
      (currentState) => currentState?.name,
      () => file?.name
    )
    newDocument = iassign(
      newDocument,
      (currentState) => currentState?.mimeType,
      () => file?.type
    )
    setModalOpen(true)
    setDocument(newDocument)
    setFileData(file)
  }

  const title = type === "add"? "New Document Upload": "Import Data"
  let acceptedExtensions = ""
  if(fileTypeLoading){
    return (
      <>
        <input type="file" onChange={(event) => handleSetFile(event)} className="d-none" ref={uploadRef} accept={acceptedExtensions} />
        <Modal size="lg" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
          <ModalHeader className="fee-modal-header">
            {title}
          </ModalHeader>
          <PlaceHolder/>
        </Modal>
      </>
    )
  } else if (fileTypeError){
    return (
      <>
        <input type="file" onChange={(event) => handleSetFile(event)} className="d-none" ref={uploadRef} accept={acceptedExtensions} />
        <Modal size="lg" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
          <ModalHeader className="fee-modal-header">
            {title}
          </ModalHeader>
          Error Fetching file type/subtype mapping. Please contact Callan.
        </Modal>
      </>
    )
  }


  const onSubmit = () => {
    if (fileData) {
      if(type === "add" && (!document.type?.code || !document.subType?.code || !document.description || !document.asOfDate)){
        setCurrentStatus(uploadStatus.Error)
        setError("Please set Name, Type, Sub-Type and As Of.")
      } else if(type === "add" || type === "import"){
        setCurrentStatus(uploadStatus.FetchUrl)
        let mimeType = fileData.type
        let variables = type === "add"? {input: {mimeType}}: {mimeType}
        documentUploadSignedUrlQuery({
          variables
        })
      }
    } else {
      setError("No file Selected.")
      return
    }
  }

  const onClick = () => {
    uploadRef?.current?.click()
  }

  const closeModal = () => {
    resetModal()
    setModalOpen(false)
  }

  const onCancel = () => {
    if(currentStatus === uploadStatus.Uploading){
      cancelToken.cancel("Cancelled By User")
      setCurrentStatus(uploadStatus.Cancelled)
    }
  }

  if(error && currentStatus === uploadStatus.StartUploading){
    setCurrentStatus(uploadStatus.Error)
    setError(": Endpoint unavailable")
  } else if(!!data && (currentStatus === uploadStatus.StartUploading) && data.getFileUploadSignedUrl?.signedUrl?.url && fileData){
    setCurrentStatus(uploadStatus.Uploading)
    // Put the fileType in the headers for the upload
    var options = {
      headers: {
        'Content-Type': fileData.type
      },
      onUploadProgress: (progressEvent:ProgressEvent) => {
        setLoadingPercent(Math.round((80 * progressEvent.loaded) / progressEvent.total))
      },
      cancelToken: cancelToken.token
    };
    axios.put(data.getFileUploadSignedUrl.signedUrl.url,fileData,options)
    .then(axiosResult => {
      if(type === "add"){
        let formattedPatch = convertLookupToString(
          document,
          false,
        )
        let newData = excludePropertyArray(formattedPatch, ["__typename", "name", "versions", "body", "mimeType", "versionId", "id", "lastUpdated"])

        newData.managers = compact(union(associationType === "Manager" ? [associationId] : [], forceValues?.manager_ids))
        newData.plans = compact(union(associationType === "Plan" ? [associationId] : [], forceValues?.plan_ids))
        newData.products = compact(union(associationType === "Product" ? [associationId] : [], forceValues?.product_ids))
        newData.vehicles = compact(union(associationType === "Vehicle" ? [associationId] : [], forceValues?.vehicle_ids))
        newData.glidePaths = compact(union(associationType === "GlidePath" ? [associationId] : [], forceValues?.glidePath_ids))
        newData.clients = compact(union(associationType === "Client" ? [associationId] : [], forceValues?.client_ids))

        let uploadInput = {
          ...newData,
          id: data.getFileUploadSignedUrl?.signedUrl?.id || "",
          versionId: axiosResult.headers['x-amz-version-id'],
          name: fileData.name,
          mimeType: fileData.type,
        }

        newFileUpload({
          variables: {
            input: uploadInput
          },
          update: (cache, { data }) => {
            let query = undefined
            let fragment = undefined
            let documentLocation:string | undefined = undefined
            let fragmentName:string | undefined = undefined
            if(associationType === "Manager"){
              query = queryDocument || ManagerDocumentsDocument
              documentLocation = "org.documents"
            } else if(associationType === "Product"){
              query = queryDocument || ProductDocumentsDocument
              documentLocation = "product.product.documents"
            } else if(associationType === "GlidePath"){
              query = queryDocument || GlidePathDocumentsDocument
              documentLocation = "glidePath.documents"
            } else if(associationType === "Client"){
              query = queryDocument || ClientReportDocumentsDocument
              documentLocation = "org.documents"
            } else if(associationType === "Plan"){
              fragment = PlanDocumentsFragmentDoc
              fragmentName = "PlanDocumentsFragment"
              documentLocation = "documents"
            }

            if(query && documentLocation){
              const org: any = cache.readQuery({
                query,
                variables: { id: associationId },
              })
              let path = (documentLocation || "").split(".")
              let updatedOrg = iassign(
                org,
                path,
                (selectedOrg) => {
                  let rows = _.cloneDeep(selectedOrg) as any[]
                  rows.push(data?.createFileMetadata.file)
                  return rows
                }
              )
              cache.writeQuery({
                query,
                variables: { id: associationId },
                data: updatedOrg,
              })
            } else if (fragment && documentLocation && fragmentName){
              const org: any = cache.readFragment({
                id: `${associationType}:${associationId}`,
                fragment,
                fragmentName
              })
              let path = (documentLocation || "").split(".")
              let updatedOrg = iassign(
                org,
                path,
                (selectedOrg) => {
                  let rows = _.cloneDeep(selectedOrg) as any[]
                  rows.push(data?.createFileMetadata.file)
                  return rows
                }
              )
              cache.writeFragment({
                id: `${associationType}:${associationId}`,
                fragment,
                fragmentName,
                data: updatedOrg,
              })
            }
            // TODO: do for other document associations
          },
        })
        .then(result => {
          setCurrentStatus(uploadStatus.Uploaded)
          setModalOpen(false)
          const newId = result.data?.createFileMetadata.file?.id
          resetModal()
          if(setSelectedDocument && newId)
            setSelectedDocument(newId)
          if(addDocument && result.data?.createFileMetadata.file)
            addDocument(result.data?.createFileMetadata.file)
        })
        .catch(err => {
          setCurrentStatus(uploadStatus.Error)
          setError(" Confirming upload: " + err.message)
          console.log("Error", err.message)
        })
      }else if(type === "import"){
        setCurrentStatus(uploadStatus.Uploaded)
        setModalOpen(false)
        const newId = data.getFileUploadSignedUrl?.signedUrl?.id
        resetModal()
        if(setSelectedDocument && newId){
          setSelectedDocument(newId)
        }
        if(addDocument && newId) {
          let info: any = {id: newId, filename: fileData.name}
          addDocument(info)
        }
      }
    })
    .catch(error => {
      setCurrentStatus(uploadStatus.Error)
      setError(" Upload: " + error.message)
      alert("ERROR " + JSON.stringify(error));
    })
  }

  let uploadComponent;
  let saveText = "Upload"
  if(currentStatus === uploadStatus.Uploading || currentStatus === uploadStatus.StartUploading || currentStatus === uploadStatus.FetchUrl || currentStatus === uploadStatus.Uploaded){
    uploadComponent = (<div className="d-flex flex-grow-1 text-align-baseline">
      <FontAwesomeIcon icon="spinner-third" spin={true} className="hover-left-icon"/>
      Uploading
      &nbsp;
      <Progress animated value={loadingPercent} barClassName="background-blue-100" className="doc-uploading-indicator flex-grow-1 mb-1">{loadingPercent}%</Progress>
    </div>)
  } else {
    let note = <></>

    if (currentStatus === uploadStatus.Error) {
      note = (
        <span className="text-accent-red pb-2 d-flex">
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]} className="hover-left-icon"/>
          File Upload Error: {errorMessage}
        </span>
      )
    } else if (currentStatus === uploadStatus.Cancelled) {
      note = (
        <span className="text-accent-red pb-2">
          Cancelled
        </span>
      )
    }
    const groupedTypes = _.groupBy(fileTypeData?.fileTypeMap as FileTypeSubTypeMap[], 'type')

    let uploadInputs = <></>
    if(type === "add") {
      uploadInputs = <React.Fragment key="add">{DocumentAddInput.map(
      (
        {
          property,
          label,
          type,
          subtype,
          placeholder,
          optionSource,
          readonly,
          tooltip,
          subClasses,
          required,
          containerId
        },
        idx
      ) => {
        let propertyVal: any = _.get(
          document,
          property
        )
        let optionFilterRule
        let editMode = true
        let hidden = false
        if(!callanUser && HiddenClientFields.includes(property)){
          hidden = true
        }
        if(property === "version"){
          propertyVal = 1
        } else if (property === "type.code"){
          if(!!forceValues?.type){
            editMode = false
          }
          optionFilterRule = Object.keys(groupedTypes).reduce((result, entry) => {
            const firstEntry = _.first(groupedTypes[entry])
            return {...result, [firstEntry?.type || ""]: firstEntry?.typeOrder }
          }, {})
        }  else if (property === "subType.code"){
          const fileType = document.type?.code || ""
          const optionFilterMapping = groupedTypes[fileType] || []
          optionFilterRule = optionFilterMapping.reduce((acc, { type, subType, subTypeOrder }) => {
            if(allowedSubtypes && subType && !allowedSubtypes.includes(subType))
              return acc
            return {...acc, [subType || ""]: subTypeOrder }
          }, {})
          if(!!forceValues?.subType){
            editMode = false
          }
        } else if (property === "access.code"){
          if (!!forceValues?.access || !(callanUser || auth.checkPermissions(["edit:my_documents"]))) {
            editMode = false
          } else if (!callanUser && auth.checkPermissions(["edit:my_documents"])) {
            optionFilterRule = {
              [DocumentAccess._1]: 1,
              [DocumentAccess._4]: 2,
            }
          }
        } else if (property === "description" && !!forceValues?.description){
          editMode = false
        } else if (property === "asOfDate" && forceValues?.asOfDate) {
          // ManagerOverEsg, user can't input this field.
          editMode = false
        }
        let onChangeCallback = (value: any) => {
          let path = property.split(".")
          let newDocument = iassign(
            document,
            path,
            () => value
          )
          if (property === "type.code") {
            newDocument = iassign(
              newDocument,
              ["subType", "code"],
              () => null
            )
          }
          setDocument(newDocument)
        }
        return (
          <React.Fragment key={idx}>
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={idx}
              editMode={editMode}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              optionFilterRule={optionFilterRule}
              readonly={readonly}
              tooltip={tooltip}
              subClasses={subClasses}
              required={required}
              hidden={hidden}
              containerId={containerId}
            />
          </React.Fragment>
        )
      }
    )}</React.Fragment>
    }else if(type === "import") {
      saveText = "Import"
      acceptedExtensions = ".xlsx"
      let state = dataLoadState
      uploadInputs = <React.Fragment key="import">
        <h5 className="modal-title">What data is being imported?</h5>
        {Object.keys(LoadTypeCodeMapping).map((code, idx) => {
          let label = LoadTypeCodeMapping[code]
          if(state.type.code === code.toString() && !checkForImport.typeChecked) {
            setCheckForImport({...checkForImport, typeChecked: true})
          }
          return (
            <React.Fragment key={idx}>
              <CustomInput
                id={`${idx}-load-type`}
                className="boolean-radio ml-2 mt-2 full-width"
                bsSize="sm"
                type="radio"
                value={label}
                // TODO: preset for now.
                disabled={!["_1", "_2"].find(el=> code.toString() === el)}
                checked={state.type.code === code.toString()}
                onChange={(value) => setDataLoadState && setDataLoadState({...state, type: {value: label, code}})}
                label={label}
              />
            </React.Fragment>
          )
        })}
        {checkForImport.typeChecked && (<>
          <h5 className="modal-title">Step 2</h5>
          {<div onClick={() => {
            if(setDataLoadState) {
              setDataLoadState({...state, template: {id: 2, vendor: {name: "BNY Mellon"}}})
              setCheckForImport({...checkForImport, formatChecked: true})
              selectTemplateRef.current?.focus()
            }
          }}
          className="ml-2"
          >
            {/* <span className={_.get(dataLoadState || {}, "template.id") === 2? "text-callan-blue": ""}>Click to set Bank= BNY Mellon(template = 2) </span> */}
            Choose Source</div>}
          <FormInput
            property={"test template"}
            displayName={""}
            type={"select"}
            subtype={"single"}
            placeholder={"template id"}
            idx={"1-template"}
            editMode={true}
            propertyVal={_.get(dataLoadState || {}, "template.id") }
            updateValue={(value: any) => {
              setDataLoadState && setDataLoadState({...state, template: {...state.template, id: parseInt(value)}}); setDataLoadState && setCheckForImport({...checkForImport, formatChecked: true}) }}
            options={templatesOptions}
              required={true}
            validateAlsoOnChange={true}
            subClasses={{inputWrapperClasses: "col-sm-6"}}
          />
        </>)}
      </React.Fragment>
    }

    let checkAllPassed = Object.keys(checkForImport).every(code => !!checkForImport[code])
    let fileName = _.get(document, "name")
    uploadComponent = (
      <div>
        <div>
          {note}
        </div>
        <div className="ml-4 mr-2">
          {type === "import" && uploadInputs}
          {type === "import" && <Row className="pl-2 mt-2">
            {checkAllPassed && !fileName && <Button onClick={onClick} className="btn mr-auto ml-2" color="primary">
              Upload File
              &nbsp;
              <FontAwesomeIcon icon="upload" />
            </Button>}
            {checkAllPassed && fileName && 
              <React.Fragment key="uploaded">
                <Col xs={3} className="pr-0 pl-3 pt-2 pb-2">File Name: </Col>
                <Col xs={8} onClick={onClick} className="py-2 pl-3 text-gray-50">{_.get(document, "name")}</Col>
                <Col xs={3} className="pr-0 pl-3 pt-2 pb-2">Data: </Col>
                <Col xs={8} className="py-2 pl-3 text-gray-50">{_.get(dataLoadState, "type.value")}</Col>
                <Col xs={3} className="pr-0 pl-3 pt-2 pb-2">Bank: </Col>
                <Col xs={8} className="py-2 pl-3 text-gray-50">{_.find(templatesOptions, (el) => el?.code === _.get(dataLoadState, "template.id"))?.value}</Col>
              </React.Fragment>
            }
          </Row>
          }
          {type === "add" && <Row onClick={onClick}>
            <Col xs={4} className="pr-0 pl-0 pt-2 pb-2">File Name</Col>
            <Col xs={8} className="py-2 pl-3 text-gray-50">{_.get(document, "name")}</Col>
          </Row>
          }
          {type === "add" && uploadInputs}
        </div>
      </div>
    )
  }

  let checkAllPassed = Object.keys(checkForImport).every(code => !!checkForImport[code])
  return (
    <>
      <input type="file" onChange={(event) => handleSetFile(event)} className="d-none" ref={uploadRef} accept={acceptedExtensions} />
      <Modal size="lg" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
          <div className="d-flex justify-content-between">
            <div>
              {title}
            </div>
            <div onClick={() => closeModal()}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody id={AddFileModalId}>
          {uploadComponent}
        </ModalBody>
        <ModalFooter>
          <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={currentStatus === uploadStatus.Uploading || currentStatus === uploadStatus.StartUploading} onSubmit={onSubmit} disabled={!fileData || (type === "import" && !checkAllPassed)} saveText={saveText}/>
        </ModalFooter>
      </Modal>
    </>
  )
}

interface VersionModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  document: FileBasicInfoFragment
  type?: "add"|"import" // add is the default, import is for data loader.
  dataLoadState?: any
  allowedTypes?: string[] // allowed file extensions
  setSelectedDocument?: (value?: any) => void // after mutation callback.
}

const CancelToken = axios.CancelToken

export const VersionModal: React.FC<VersionModalProps> = ({
  modalOpen,
  setModalOpen,
  document,
  type = "add",
  allowedTypes = [],
  dataLoadState,
  setSelectedDocument,
}) => {
  const uploadRef = useRef<HTMLInputElement>(null)
  const [loadingPercent, setLoadingPercent] = useState(0)
  const [currentStatus, setCurrentStatus] = useState(uploadStatus.Ready)
  const [cancelToken] = useState(CancelToken.source())

  const query = type === "add"? SIGNED_FILE_URL_QUERY: SIGNED_DATA_LOAD_URL_QUERY
  const [documentUploadSignedUrlQuery, {data, error}] = useLazyQuery(query, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setCurrentStatus(uploadStatus.StartUploading)
    },
    onError: () => {
      setCurrentStatus(uploadStatus.Error)
      setError(": Endpoint unavailable")
    }
  })

  const mutationDocument = type === "add"? UPLOAD_NEW_VERSION_MUTATION: UPLOAD_NEW_DATA_LOAD_VERSION_MUTATION
  const [uploadNewVersionMutation] = useMutation(mutationDocument)
  const [fileData, setFileData] = useState<File | null>()
  const [errorMessage, setError] = useState("")
  const [version, setVersion] = useState<NewFileVersion>({
    id: document.id,
    patch: {
      versionId: "",
      reason: "",
    }
  })

  const resetModal = () => {
    setCurrentStatus(uploadStatus.Ready)
    setLoadingPercent(0)
    setFileData(null)
    setError("")
    setVersion({
      id: document.id,
      patch: {
        versionId: "",
        reason: "",
      }
    })
  }

  useEffect(() => {
    resetModal()
  }, [modalOpen]);

  const handleSetFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    let file = e.target.files && e.target.files[0]
    if (!file) {
      setError("No File selected.")
      return
    }
    if (isFileEmpty(file)) {
      setFileData(null)
      setError("Empty File.")
      return
    }
    if (!checkMimeType(file)) {
      setFileData(null)
      setError("File type error.")
      return
    }
    setFileData(file)
  }

  const onSubmit = () => {

    if (fileData) {
      setCurrentStatus(uploadStatus.FetchUrl)
      let mimeType = fileData.type
      let variables = type === "add"? { input: {id: document.id, mimeType}}: {mimeType}
      documentUploadSignedUrlQuery({
        variables
      })
    } else {
      setError("No file Selected.")
      return
    }
  }

  const onClick = () => {
    uploadRef?.current?.click()
  }

  const onCancel = () => {
    if(currentStatus === uploadStatus.Uploading){
      // cancelToken.cancel("Cancelled By User")
      setCurrentStatus(uploadStatus.Cancelled)
    }
  }

  if(error && currentStatus === uploadStatus.StartUploading){
    setCurrentStatus(uploadStatus.Error)
    setError(": Endpoint unavailable")
  } else if(!!data && (currentStatus === uploadStatus.StartUploading) && data.getFileUploadSignedUrl?.signedUrl?.url && fileData){
    setCurrentStatus(uploadStatus.Uploading)
    // Put the fileType in the headers for the upload
    var options = {
      headers: {
        'Content-Type': fileData.type
      },
      onUploadProgress: (progressEvent:ProgressEvent) => {
        setLoadingPercent(Math.round((80 * progressEvent.loaded) / progressEvent.total))
      },
      cancelToken: cancelToken.token
    };
    axios.put(data.getFileUploadSignedUrl.signedUrl.url,fileData,options)
    .then(axiosResult => {
      let variables = {}
      if(type === "add"){
        let fileVars = {
          id: version.id,
          patch: {
            versionId: axiosResult.headers['x-amz-version-id'],
            reason: version.patch.reason,
          }
        }
        variables = {input: fileVars}
      } else if(type === "import"){
        const newId = data.getFileUploadSignedUrl?.signedUrl?.id
        let input = {
          id: dataLoadState?.id,
          uploadFileInfo: {
            id: newId,
            filename: fileData.name,
          }
        }
        variables = {input}
      }

      uploadNewVersionMutation({variables})
        .then(result => {
          if (result && result.data) {
            if(type === "import") {
              if(setSelectedDocument) {
                setSelectedDocument(result.data?.newVersion)
              }
            }
            setCurrentStatus(uploadStatus.Uploaded)
            setModalOpen(false)
            resetModal()
          }
        })
        .catch(err => {
          console.error("Error uploading File", err.message)
          setCurrentStatus(uploadStatus.Error)
        })
    })
    .catch(error => {
      setCurrentStatus(uploadStatus.Error)
      setError(" Upload: " + error.message)
      alert("ERROR " + JSON.stringify(error));
    })
  }

  let uploadComponent;

  if(currentStatus === uploadStatus.Uploading || currentStatus === uploadStatus.StartUploading || currentStatus === uploadStatus.FetchUrl){
    uploadComponent = (<div className="d-flex flex-grow-1 text-align-baseline">
      <FontAwesomeIcon icon="spinner-third" spin={true} className="hover-left-icon"/>
      Uploading
      &nbsp;
      <Progress animated value={loadingPercent} barClassName="background-blue-100" className="doc-uploading-indicator flex-grow-1 mb-1">{loadingPercent}%</Progress>

    </div>)
  } else {
    let note = <></>

    if (currentStatus === uploadStatus.Error) {
      note = (
        <span className="text-accent-red">
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]} className="hover-left-icon"/>
          File Upload Error: {errorMessage}
        </span>
      )
    } else if (currentStatus === uploadStatus.Cancelled) {
      note = (
        <span className="text-accent-red">
          Cancelled
        </span>
      )
    }

    let onChangeCallback = (value: any, property:string) =>{
      let path = property.split(".")
      const newVersion = iassign(
        version,
        path,
        () => value
      )
      setVersion(newVersion)
    }

    const title = type === "add"? "Upload Version": "Import Version"
    uploadComponent = (
      <div>
        <div>
          {note}
          &nbsp;
          <input type="file" onChange={(event) => handleSetFile(event)} className="d-none" ref={uploadRef}/>
          {!!fileData &&
            <div onClick={() => onClick()}>
              Selected File: <b>{fileData.name}</b>
            </div>
          }
          {!fileData &&
            <Button className="btn ml-auto" color="primary" onClick={() => onClick()}>
              {title}
              &nbsp;
              <FontAwesomeIcon icon="upload" />
            </Button>
          }
        </div>
        {type === "add" && <div className="ml-2 mr-2">
          <FormInput
            property={"reason"}
            displayName={"Reason for Change:"}
            type={"text"}
            rows={1}
            placeholder={"Add Reason"}
            idx={1}
            editMode={true}
            propertyVal={version.patch.reason}
            subClasses={{
              labelClasses: "col-sm-12 pl-2",
              inputWrapperClasses: "col-sm-12 p-0"
            }}
            updateValue={(value:any) => onChangeCallback(value, "patch.reason")}
          />
        </div>}
      </div>
    )
  }

  return (
    <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
      <ModalHeader className="fee-modal-header">
        File Upload
      </ModalHeader>
      <ModalBody>
        {uploadComponent}
      </ModalBody>
      <ModalFooter>
        <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => setModalOpen(false)} saving={currentStatus === uploadStatus.Uploading || currentStatus === uploadStatus.StartUploading} onSubmit={onSubmit} disabled={!fileData}/>
      </ModalFooter>
    </Modal>
  )
}

interface SearchFileProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  addDocument?: (document:FileBasicInfoFragment[]) => void
  documents?: FileBasicInfoFragment[]
}

export const SearchFileModal: React.FC<SearchFileProps> = ({
  modalOpen,
  setModalOpen,
  addDocument,
  documents,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [search, setSearch] = useState("")

  const onSubmit = () => {
    if(addDocument) addDocument(selectedFiles)
    setModalOpen(false)
  }

  const setSelectedRows = (rows: any) => {
    setSelectedFiles(rows)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  let colDef = searchDocumentColumnDef()

  return (
    <>
      <Modal size="lg" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
          <div className="d-flex justify-content-between">
            <div>
              Find Document
            </div>
            <div onClick={() => closeModal()}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody id={AddFileModalId} className="view-port-60">
        <Container fluid className="d-flex flex-grow-1 flex-direction-column h-100">
          <Form className="">
            <FormGroup row className="relative m-0">
              <Input
                type="text"
                placeholder="Search by document name"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="mx-0 mb-2"
              />
              <span className="o-88 absolute center-v right-1 pe-none pb-1">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            </FormGroup>
          </Form>
          <SortableTable
            filterText={search}
            columnDefs={colDef}
            tableData={documents}
            setSelectedRows={setSelectedRows}
            rowId={"id"}
            noPagination={true}
          />
        </Container>
        </ModalBody>
        <ModalFooter>
          <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={false} onSubmit={onSubmit} disabled={selectedFiles.length === 0} saveText="Add Document"/>
        </ModalFooter>
      </Modal>
    </>
  )
}
