import React from 'react'
import { compact, get, groupBy, sortBy, uniq } from 'lodash'
import { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core'
import { currencyFormatter, dateFormatter, falsyFormatter, LoadStatusCodeMapping, LoadTypeCodeMapping, LoadTypeCodeValues, simpleMappingFormatter } from '../../../../helpers/helpers'
import moment from 'moment'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, DATE_TIME_API_FORMAT } from '../../../../helpers/constant'
import { importedFileLinkRenderer } from '../../../../helpers/agGridHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataLoaderDataAssetsDocument, DataLoaderDataCashFlowDocument, LoadTypeCode, Maybe } from '../../../../__generated__/graphql'

const LoadTypeCodeComparator = (valueA: string, valueB: string) => {
  if (valueA === valueB) return 0
  if (!valueA) return 1
  if (!valueB) return -1
  let vA = parseInt(valueA.replace('_', ''), 10)
  let vB = parseInt(valueB.replace('_', ''), 10)
  return vA > vB ? 1 : -1
}

export const fileNameFormatter = (params: ValueFormatterParams) => {
  let name = params.value
  if (!name) {
    return ''
  }
  let splitName = name.split('.')
  splitName.pop()
  return splitName.join('.')
}

const loadStatusRenderer = (
  params: ICellRendererParams,
  keywords: string[] = ['failed']
) => {
  let status = params?.valueFormatted || params.value
  let isValueFailed = keywords?.some((keyword) =>
    status?.toLowerCase().includes(keyword)
  )

  if (status) {
    return (
      <div>
        {isValueFailed && (
          <FontAwesomeIcon
            icon={['fas', 'exclamation-triangle']}
            style={{ color: '#c43b3b' }}
          />
        )}
        <span className={'pl-2'}> {status} </span>
      </div>
    )
  }
  return ''
}

const DateTimeComparator = (
  localDateObject: Date,
  userInputString: Maybe<string>,
  blankPosition?: 'bottom' | 'top'
) => {
  var dateAsString = userInputString
  let localDate = moment(localDateObject, DATE_TIME_API_FORMAT)
  let userInputDate = moment(dateAsString, DATE_TIME_API_FORMAT)
  // default falsy date at bottom
  let position = blankPosition || 'bottom'
  if (position === 'top') {
    if (!dateAsString && !localDateObject) {
      return 0
    } else if (!dateAsString) {
      return 1
    } else if (!localDateObject) {
      return -1
    }
  } else {
    // bottom
    if (!dateAsString && !localDateObject) {
      return 0
    } else if (!dateAsString) {
      return -1
    } else if (!localDateObject) {
      return 1
    }
  }

  // See example https://www.ag-grid.com/react-data-grid/filter-date/#example-date-filter
  if (userInputDate.isBefore(localDate, 'second')) {
    return -1
  }
  if (userInputDate.isAfter(localDate, 'second')) {
    return 1
  }
  return 0
}

export const ImportedFilesColumnDef = () => {
  return compact([
    {
      headerName: 'File name',
      field: 'uploadFileInfo.filename',
      valueFormatter: fileNameFormatter,
      // Not linked for now.
      cellRenderer: importedFileLinkRenderer,
      filter: 'agSetColumnFilter',
      filterParams: {
        valueFormatter: fileNameFormatter,
      },
      sortable: true,
      width: 280,
    },
    {
      headerName: 'ID(Test)',
      field: 'id',
      sortable: true,
      width: 110,
    },
    {
      headerName: 'Data',
      field: 'type.code',
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return ''
        }
        return simpleMappingFormatter(params, LoadTypeCodeMapping)
      },
      comparator: LoadTypeCodeComparator,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: LoadTypeCodeValues,
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return ''
          }
          return simpleMappingFormatter(params, LoadTypeCodeMapping)
        },
        comparator: LoadTypeCodeComparator,
      },
      width: 130,
    },
    {
      headerName: 'Bank',
      field: 'template.vendor.name',
      sortable: true,
      filter: 'agSetColumnFilter',
      width: 200,
    },
    {
      headerName: 'Status',
      field: 'status.code',
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        let status = params.data?.status?.code
        if (status === "_1" && !params.data?.messages) {
          status =  "_0"
        }
        return status
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return ''
        }
        return simpleMappingFormatter(params, LoadStatusCodeMapping)
      },
      cellRenderer: (params: ICellRendererParams) => loadStatusRenderer(params),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.keys(LoadStatusCodeMapping),
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return ''
          }
          return simpleMappingFormatter(params, LoadStatusCodeMapping)
        },
      },
      tooltipComponent: 'messageTooltip',
      tooltipValueGetter: (params: ValueGetterParams) => {
        let isStatusFailed = ["failed"].some((keyword) =>
        params.data?.status?.value?.toLowerCase().includes(keyword)
        )
        if (isStatusFailed) {
          return [params.data?.messages]
        }
        return []
      },
      width: 150,
    },
    // {
    //   headerName: "Source",
    //   field: "template",
    //   sortable: true,
    //   valueGetter: (params: ValueGetterParams) => {
    //     let template = params.data?.template
    //     if(!template?.type?.value || !template?.vendor?.name) {
    //       return ""
    //     }
    //     return `${template?.type?.value} - ${template?.vendor?.name}` as string
    //   },
    //   valueFormatter: falsyFormatter,
    //   filter: "agSetColumnFilter",
    //   filterParams: {
    //     valueFormatter: falsyFormatter,
    //   },
    //   width: 200,
    // } ,
    {
      headerName: 'Time(Test)',
      field: 'uploadFileInfo.uploaded',
      valueGetter: (params: ValueGetterParams) => {
        let dateTime = get(params.data, params?.colDef?.field || '')
        let date = moment(dateTime, DATE_TIME_API_FORMAT).format(
          DATE_TIME_API_FORMAT
        )
        return dateTime ? date : ''
      },
      sortable: true,
      width: 280,
    },
    {
      headerName: 'Updated',
      field: 'uploadFileInfo.uploaded',
      filter: 'agSetColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        let dateTime = get(params.data, params?.colDef?.field || '')
        let date = moment(dateTime, DATE_TIME_API_FORMAT).format(
          DATE_TIME_API_FORMAT
        )
        return dateTime ? date : ''
      },
      valueFormatter: (params: ValueFormatterParams) => {
        let dateTime = params.value
        let date = moment(dateTime, DATE_TIME_API_FORMAT).format(
          DATE_DISPLAY_FORMAT
        )
        return dateTime ? date : ''
      },
      // comparator: dateFilterParams.comparator,
      comparator: DateTimeComparator,
      filterParams: {
        valueGetter: (params: ValueFormatterParams) => {
          let dateTime = get(params.data, params?.colDef?.field || '')
          let date = moment(dateTime, DATE_TIME_API_FORMAT).format(
            DATE_API_FORMAT
          )
          return dateTime ? date : ''
        },

        comparator: DateTimeComparator,
      },
      sortable: true,
      sort: 'asc',
      width: 200,
    },
    {
      headerName: 'Owner',
      field: 'person',
      valueGetter: (params: ValueGetterParams) => {
        let person = params.data?.person
        if (person?.firstName && person?.lastName) {
          return person?.firstName + ' ' + person?.lastName
        }
      },
      sortable: true,
      width: 280,
    },
  ])
}

export const MessageSeverityMapping: { [key: string]: string } = {
  ERROR: 'Error',
  WARNING: 'Warning',
  INFO: 'Info',
}

export const MessageSeverityOrder = [
  {label: 'Error', code: "ERROR"}, 
  {label: 'Warning', code: "WARNING"},
  {label: 'Info', code: "INFO"}
]

export const ImportedFileMessagesColumnDef = () => {
  return compact([
    {
      headerName: 'Type',
      field: 'severity',
      filter: 'agSetColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return ''
        }
        return simpleMappingFormatter(params, MessageSeverityMapping)
      },
      filterParams: {
        values: Object.keys(MessageSeverityMapping),
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return ''
          }
          return simpleMappingFormatter(params, MessageSeverityMapping)
        },
        comparator: (valueA: string, valueB: string) => {
          let indexA = MessageSeverityOrder.findIndex((el) => el.code === valueA)
          let indexB = MessageSeverityOrder.findIndex((el) => el.code === valueB)
          if(indexA === indexB) return 0
          return indexA < indexB ? -1 : 1
        }
      },

      sortable: true,
      width: 150,
    },
    {
      headerName: 'Message',
      field: 'message',
      filter: 'agSetColumnFilter',
      sortable: true,
      width: 500,
    },
    {
      headerName: 'Value',
      field: 'value', // test
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data?.severity === 'ERROR') {
          return (
            <div>
              <FontAwesomeIcon
                icon={['fas', 'exclamation-triangle']}
                style={{ color: '#c43b3b' }}
              />
              <span className={'pl-2'}> {params.value} </span>
            </div>
          )
        } else {
          return (
            <div>
              <span className={'pl-2'}> {params.value} </span>
            </div>
          )
        }
      },
      tooltipComponent: 'messageTooltip',
      tooltipValueGetter: (params: ValueGetterParams) => {
        if (params.data?.severity === 'ERROR') {
          let { row, column } = params.data
          return [params.data?.message, `See Row ${row}, Column ${column}.`]
        } else {
          return []
        }
      },
      width: 180,
    },
    {
      headerName: 'Row',
      field: 'row',
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Column',
      field: 'column',
      sortable: true,
      width: 150,
    },
  ])
}

export const ImportedFileDataCashFlowColumnDef = (
  assetClass: number,
  template: any
) => {
  return compact([
    {
      headerName: 'As-of',
      field: 'periodEndDate',
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
      },
      sortable: true,
      width: 110,
    },
    {
      headerName: 'Date',
      field: 'transactionDate',
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
      },
      sortable: true,
      width: 110,
    },
    {
      headerName: 'Type',
      field: 'transactionType.value',
      filter: 'agSetColumnFilter',
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Amount',
      // TODO: Is it extension or accruedInterest?
      field: 'extension',
      valueFormatter: currencyFormatter,
      filter: 'agNumberColumnFilter',
      cellClass: "ag-right-aligned-cell",
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Effect',
      field: 'transactionType.cashEffect',
      sortable: true,
      width: 100,
    },
    {
      headerName: 'Portfolio ID',
      field: 'portfolio.id',
      sortable: true,
      width: 120,
    },
    {
      headerName: 'Portfolio',
      field: 'portfolio.name',
      sortable: true,
      width: 300,
    },
    {
      headerName: 'Client',
      field: 'portfolio.client.name',
      sortable: true,
      width: 300,
    },
    {
      headerName: 'Plan',
      field: 'portfolio.plan.shortName',
      sortable: true,
      width: 140,
    },
    {
      headerName: 'Message',
      field: 'messages',
      filter: 'agSetColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value?.length) {
          return ''
        }
        return  params.value.map((el: any) => {
          if (!el) {
            return ""
          } else {
            return MessageSeverityMapping[el]
          }
        })
      },
      valueGetter: (params: ValueGetterParams) => {
        let messages = params.data?.messages
        if (messages?.length) {
          let severities =  uniq(messages
            .map((message: any) => {
              return message.severity
            }))
          return sortBy(compact(severities), MessageSeverityOrder)
        }else {
          return []
        }
      },
      cellRenderer: (params: ICellRendererParams) => {
        let values = params.valueFormatted || params.value
        let hasError = Array.isArray(values) && values.some((el: any) => el === 'Error')
        if (values?.length && values[0] !== "") {
          return (
            <div>
              {hasError && (
                <FontAwesomeIcon
                  icon={['fas', 'exclamation-triangle']}
                  style={{ color: '#c43b3b' }}
                />
              )}
              <span className={'underline-dotted'}> {values.join(", ")} </span>
            </div>
          )
        }
        return ''
      },
      tooltipComponent: 'messageTooltip',
      tooltipValueGetter: (params: ValueGetterParams) => {
        let messages = params.data?.messages
        if (messages?.length) {
          let groupedMessage = groupBy(messages, (message: any) => message.severity)
          let result = MessageSeverityOrder.reduce((acc, {code}, key) => {
            let messages = groupedMessage[code]
            let formattedMessages =  messages?.map(el => `${el?.message}, See Row ${el.row}, Column ${el.column}.`)
            if(formattedMessages?.length) {
              acc[code] = formattedMessages
            }
            return acc
          },{} as any)
          return Object.values(result)
        }else {
          return []
        }
      },
      tooltipComponentParams: {
        divider: true,
      },
      filterParams: {
        values: Object.keys(MessageSeverityMapping),
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return ''
          }
          return simpleMappingFormatter(params, MessageSeverityMapping)
        },
        comparator: (valueA: string, valueB: string) => {
          let indexA = MessageSeverityOrder.findIndex((el) => el.code === valueA)
          let indexB = MessageSeverityOrder.findIndex((el) => el.code === valueB)
          if(indexA === indexB) return 0
          return indexA < indexB ? -1 : 1
        }
      },
      sortable: true,
      width: 150,
    },
  ])
}

export const ImportedFileDataAssetsColumnDef = (
  assetClass: number,
  template: any
) => {
  return compact([
    {
      headerName: 'As-of',
      field: 'date',
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
      },
      sortable: true,
      width: 110,
    },
    {
      headerName: 'Amount',
      // TODO: Is it extension or accruedInterest?
      field: 'marketValue',
      valueFormatter: currencyFormatter,
      filter: 'agNumberColumnFilter',
      cellClass: "ag-right-aligned-cell",
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Portfolio ID',
      field: 'portfolio.id',
      sortable: true,
      width: 120,
    },
    {
      headerName: 'Portfolio',
      field: 'portfolio.name',
      sortable: true,
      width: 300,
    },
    {
      headerName: 'Client',
      field: 'portfolio.client.name',
      sortable: true,
      width: 300,
    },
    {
      headerName: 'Plan',
      field: 'portfolio.plan.shortName',
      sortable: true,
      width: 140,
    },
    {
      headerName: 'Message',
      field: 'messages',
      filter: 'agSetColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value?.length) {
          return ''
        }
        return  params.value.map((el: any) => {
          if (!el) {
            return ""
          } else {
            return MessageSeverityMapping[el]
          }
        })
      },
      valueGetter: (params: ValueGetterParams) => {
        let messages = params.data?.messages
        if (messages?.length) {
          let severities =  uniq(messages
            .map((message: any) => {
              return message.severity
            }))
          return sortBy(compact(severities), MessageSeverityOrder)
        }else {
          return []
        }
      },
      cellRenderer: (params: ICellRendererParams) => {
        let values = params.valueFormatted || params.value
        let hasError = Array.isArray(values) && values.some((el: any) => el === 'Error')
        if (values?.length && values[0] !== "") {
          return (
            <div>
              {hasError && (
                <FontAwesomeIcon
                  icon={['fas', 'exclamation-triangle']}
                  style={{ color: '#c43b3b' }}
                />
              )}
              <span className={'underline-dotted'}> {values.join(", ")} </span>
            </div>
          )
        }
        return ''
      },
      tooltipComponent: 'messageTooltip',
      tooltipValueGetter: (params: ValueGetterParams) => {
        let messages = params.data?.messages
        if (messages?.length) {
          let groupedMessage = groupBy(messages, (message: any) => message.severity)
          let result = MessageSeverityOrder.reduce((acc, {code}, key) => {
            let messages = groupedMessage[code]
            let formattedMessages =  messages?.map(el => `${el?.message}, See Row ${el.row}, Column ${el.column}.`)
            if(formattedMessages?.length) {
              acc[code] = formattedMessages
            }
            return acc
          },{} as any)
          return Object.values(result)
        }else {
          return []
        }
      },
      tooltipComponentParams: {
        divider: true,
      },
      filterParams: {
        values: Object.keys(MessageSeverityMapping),
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return ''
          }
          return simpleMappingFormatter(params, MessageSeverityMapping)
        },
        comparator: (valueA: string, valueB: string) => {
          let indexA = MessageSeverityOrder.findIndex((el) => el.code === valueA)
          let indexB = MessageSeverityOrder.findIndex((el) => el.code === valueB)
          if(indexA === indexB) return 0
          return indexA < indexB ? -1 : 1
        }
      },
      sortable: true,
      width: 150,
    },
  ])
}

const Documents = [DataLoaderDataAssetsDocument, DataLoaderDataCashFlowDocument] as const

type DocumentType = (typeof Documents)[number]

export const ImportedFileDataColumnDefMapping: {
  [type in LoadTypeCode]: {
    colDef: (assetClass: number, template: number) => {}[]
    document: DocumentType
  }
} = {
  _2: {
    colDef: (assetClass: number, template: number) =>
      ImportedFileDataCashFlowColumnDef(assetClass, template),
    document: DataLoaderDataCashFlowDocument,
  },
  // TODO: placeholder below.
  _1: {
    colDef: (assetClass: number, template: number) =>
    ImportedFileDataAssetsColumnDef(assetClass, template),
    document: DataLoaderDataAssetsDocument,
  },
  _3: {
    colDef: (assetClass: number, template: number) =>
      ImportedFileDataCashFlowColumnDef(assetClass, template),
    document: DataLoaderDataCashFlowDocument,
  },
  _4: {
    colDef: (assetClass: number, template: number) =>
      ImportedFileDataCashFlowColumnDef(assetClass, template),
    document: DataLoaderDataCashFlowDocument,
  },
}
