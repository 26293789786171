import { gql } from "@apollo/client"
import { DASHBOARD_STOPLIGHT_FRAGMENT } from "./DashboardFragments"
import {
  FOOTNOTE_SHOW_FRAGMENT,
  FOOTNOTE_SHOW_TARGET_FRAGMENT,
  REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT,
} from "./FootnoteFragments"
import { REPORT_LIST_HEADER_FRAGMENT, REPORT_SETTINGS_LIST_FRAGMENT } from "./ListFragments"
import { REPORT_COLUMN_DATA_FRAGMENT, REPORT_MANAGER_PERFORMANCE_FETCH_FRAGMENT } from "./ManagerPerformanceFragments"
import { REPORT_PERFORMANCE_COMPARISON_FETCH_FRAGMENT } from "./PerformanceComparisonFragments"

export const REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportsListClientPortfolioFragment on ClientPortfolio {
    id
    plan {
      id
      fiscalMonth
    }
    name
    hasComponents
    dataType {
      code
      value
    }
  }
`

export const REPORT_SIMPLE_FRAGMENT = gql`
  fragment ReportSimpleFragment on Report {
    id
    name
    active
    owner {
      id
      firstName
      lastName
    }
    subCategory {
      code
      value
    }
    category {
      code
      value
    }
    client {
      id
      name
    }
    plans {
      id
      name
      client {
        id
      }
    }
    draftList {
      id
      lastSavedDate
    }
    liveList {
      id
      lastSavedDate
    }
    lastPublished
  }
`

export const REPORT_SIMPLE_LIST_MEMBER_FRAGMENT = gql`
  fragment ReportSimpleListMemberFragment on ListMember {
    order
    group
    item {
      ... on ListGroup {
        id
      }
      ... on ListHeader {
        ...ReportListHeaderFragment
      }
      ... on ClientPortfolio {
        ...ReportsListClientPortfolioFragment
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT}
`

export const REPORT_SIMPLE_LIST_FRAGMENT = gql`
  fragment ReportSimpleListFragment on List {
    id
    name
    items {
      ...ReportSimpleListMemberFragment
    }
  }
  ${REPORT_SIMPLE_LIST_MEMBER_FRAGMENT}
`

export const LISTS_FOR_ORG_FRAGMENT = gql`
  fragment ListsForOrgFragment on List {
    id
    name
    type
    subCategory {
      code
      value
    }
  }
`

export const REPORT_ASSET_ALLOCATION_FETCH_FRAGMENT = gql`
  fragment ReportAssetAllocationFetchFragment on AssetAllocation {
    list {
      ...ReportSettingsListFragment
    }
    date
    showTarget
    inPercent
    totalFundPid
    showFootnote
    showTargetFootnote
    monthlyOptions {
      show
      dates
    }
  }
  ${REPORT_SETTINGS_LIST_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportAssetAllocationClientPortfolioFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    inceptionDate
    endAssetDate
    showAssets
    assets(filter: { startDate: $date, endDate: $date }) {
      date
      totalMarketValue
    }
    performanceTargetMap {
      order
      target {
        targetId: id
        name
        endDate
        definition(date: $date) {
          assetClass {
            id
            fullName
            parent {
              id
              fullName
            }
          }
          weight
        }
      }
    }
    assetClass {
      id
      fullName
      parent {
        id
        fullName
      }
    }
    footnote {
      ...FootnoteShowFragment
    }
    targetFootnotes {
      ...FootnoteShowTargetFragment
    }
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${FOOTNOTE_SHOW_FRAGMENT}
  ${FOOTNOTE_SHOW_TARGET_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_TABLE_FRAGMENT = gql`
  fragment ReportAssetAllocationTableFragment on AssetAllocationTable {
    columns
    drilldownId
    rows {
      id
      productId
      color
      footnote
      targetFootnote
      name
      total
      columnData  {
        ...ReportColumnDataFragment
      }
      error
    }
  }
  ${REPORT_COLUMN_DATA_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_DATA_FRAGMENT = gql`
  fragment ReportAssetAllocationDataFragment on AssetAllocationData {
    chart
    tables {
      ...ReportAssetAllocationTableFragment
    }
  }
  ${REPORT_ASSET_ALLOCATION_TABLE_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_FETCH_FRAGMENT = gql`
  fragment ReportAssetDistributionFetchFragment on AssetDistribution {
    list {
      ...ReportSettingsListFragment
    }
    date
    showNNI
    showWeight
    assetDistributionPeriod: period
    showFootnote
    totalFundPid
    showParticipants
    monthlyOptions {
      show
      dates
    }
  }
  ${REPORT_SETTINGS_LIST_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_ROW_FRAGMENT = gql`
  fragment AssetDistributionRowFragment on AssetDistributionRow {
    id
    name
    productId
    portfolio
    target
    total
    header
    group
    footnote
    columnData {
      ...ReportColumnDataFragment
    }
  }
  ${REPORT_COLUMN_DATA_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_DATA_FRAGMENT = gql`
  fragment ReportAssetDistributionDataFragment on AssetDistributionData {
    columns {
      text
    }
    rows {
      ...AssetDistributionRowFragment
      children {
        ...AssetDistributionRowFragment
        children {
          ...AssetDistributionRowFragment
          children {
            ...AssetDistributionRowFragment
          }
        }
      }
    }
  }
  ${REPORT_ASSET_DISTRIBUTION_ROW_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment ReportAssetDistributionClientPortfolioFragment on ClientPortfolio {
    ...ReportCommonClientPortfolioFragment
    dataType {
      code
      value
    }
    showAssets
    inceptionDate
    endAssetDate
    endingValue: assets(filter: { startDate: $date, endDate: $date }) {
      date
      totalMarketValue
    }
    beginningValue: assets(
      filter: { startDate: $beginningDate, endDate: $beginningDate }
    ) {
      date
      totalMarketValue
    }
    cashFlowActivity(filter: { startDate: $beginningDate, endDate: $date })
      @include(if: $showNNI) {
      period
      date
      netFlow
    }
    footnote {
      ...FootnoteShowFragment
    }
    numberOfParticipants(filter: { startDate: $date, endDate: $date }) {
      date
      count
    }
  }
  ${REPORT_COMMON_CLIENT_PORTFOLIO_FRAGMENT}
  ${FOOTNOTE_SHOW_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_DETAIL_FRAGMENT = gql`
  fragment ReportAssetDistributionDetailFragment on List {
    id
    name
    items {
      order
      group
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          ...ReportListHeaderFragment
        }
        ... on ClientPortfolio {
          ...ReportAssetDistributionClientPortfolioFragment
        }
      }
    }
  }
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${REPORT_ASSET_DISTRIBUTION_CLIENT_PORTFOLIO_FRAGMENT}
`
export const REPORT_TEXT_FETCH_FRAGMENT = gql`
  fragment ReportTextFetchFragment on Text {
    text
  }
`

export const CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT = gql`
  fragment ClientPortfolioDetailComponentSettingsFragment on ComponentSettings {
    ... on AssetAllocation {
      ...ReportAssetAllocationFetchFragment
    }
    ... on AssetDistribution {
      ...ReportAssetDistributionFetchFragment
    }
    ... on ManagerPerformance {
      ...ReportManagerPerformanceFetchFragment
    }
    ... on Stoplight {
      ...DashboardStoplightFragment
    }
    ... on Text {
      ...ReportTextFetchFragment
    }
    ... on PerformanceComparison {
      ...ReportPerformanceComparisonFetchFragment
    }
  }
  ${REPORT_ASSET_ALLOCATION_FETCH_FRAGMENT}
  ${REPORT_ASSET_DISTRIBUTION_FETCH_FRAGMENT}
  ${REPORT_MANAGER_PERFORMANCE_FETCH_FRAGMENT}
  ${DASHBOARD_STOPLIGHT_FRAGMENT}
  ${REPORT_TEXT_FETCH_FRAGMENT}
  ${REPORT_PERFORMANCE_COMPARISON_FETCH_FRAGMENT}
`
