import classnames from "classnames"
import React, { EventHandler, useState, useEffect, useContext } from "react"
import { FormGroup, Input, InputProps, Label } from "reactstrap"
import { clone, get, set } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import "date-input-polyfill-react"
import moment from "moment"
import validate from "validate.js"
import { shouldUseYearInput } from "../../../helpers/object"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../../helpers/constant"
import { MonthPicker } from "./MonthPicker"
import { EditButtonContext } from "../../../Context/EditButtonContext"
import { InputTooltip } from "./TextFieldInput"

interface DateFieldInputProps {
  idx: string | number
  property: string
  displayName: string
  propertyValue: string
  editMode: boolean
  updateValue: (value: any) => void
  wrapperClasses?: string
  labelClasses?: string
  inputWrapperClasses?: string
  inputClasses?: string
  placeholder?: string
  disabled?: boolean
  inline?: boolean
  required?: boolean
  tooltip?: InputTooltip
  inputProps?: InputProps
  subtype?: string
  containerId?: string
  inputRef?: React.RefObject<HTMLInputElement>
  immediateUpdate?: boolean
}

const isDateSupported = function() {
  let input = document.createElement("input")
  const value = "a"
  input.setAttribute("type", "date")
  input.setAttribute("value", value)
  return input.value !== value
}

export const DateFieldInput: React.FC<DateFieldInputProps> = (props) => {
  const {
    idx,
    property,
    displayName,
    propertyValue,
    editMode,
    labelClasses,
    inputWrapperClasses,
    inputClasses,
    placeholder,
    disabled,
    tooltip,
    inputProps,
    inputRef,
    subtype,
    containerId,
    immediateUpdate,
  } = props
  const updateValue: EventHandler<any> = props.updateValue

  let shortProperty = property.split(".").pop() || property
  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || "")
  let [previousValue, setPreviousValue] = useState<string | null>(null)
  let [initialLoad, setInitialLoad] = useState(true)

  let required = props.required || false
  const context = useContext(EditButtonContext)
  const { setError } = context

  const displayLabel = displayName !== ""

  const formatField = (value: string) => {
    const date = moment(value, DATE_API_FORMAT)
    if (date.isValid() && (!editMode || (!isDateSupported() && !! value))) {
      return date.format(DATE_DISPLAY_FORMAT)
    } else {
      return value
    }
  }

  let [formattedValue, setFormattedValue] = useState(
    formatField(propertyValue || "")
  )

  useEffect(() => {
    setFormattedValue((value) => {
      if(!value && initialLoad ) return value
      return propertyValue
    })
    runValidation(propertyValue)
    setInitialLoad(false)
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [props.editMode, propertyValue, required])

  useEffect(() => {
    setWrapperClasses(props.wrapperClasses || "")
  }, [props.wrapperClasses])

  const runValidation = (value: string) => {
    var inputs: { [key: string]: string } = {}
    var constraints: { [key: string]: any } = {}
    inputs[shortProperty] = value

    if (required) {
      set(constraints, [shortProperty, "presence"], true)
    }

    let validations = validate(inputs, constraints)
    if (validations && props.editMode) {
      setError(`${idx}-${shortProperty}`, get(validations, shortProperty))
      setWrapperClasses(wrapperClasses + " has-error")
    } else if (!handleInvalidDate(value) && props.editMode && !props.disabled) {
      setError(`${idx}-${shortProperty}`, ['Invalid date'])
      setWrapperClasses(wrapperClasses + " has-error")
    } else {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g, ""))
    }
  }

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Clear value so that entering a date does not give null if partial date is invalid
    let targetValue = event.target.value
    if (targetValue && immediateUpdate) {
      setPreviousValue(targetValue)
      setFormattedValue("")
    }
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value
    let value: string | null

    if (targetValue == "") {
      if(previousValue){
        if (!isDateSupported()) {
          value = moment(previousValue, DATE_DISPLAY_FORMAT).format(DATE_API_FORMAT)
        } else {
          value = previousValue
        }
        setFormattedValue(formatField(previousValue || ""))
      } else {
        value = null
      }
    } else {
      if (!isDateSupported()) {
        value = moment(targetValue, DATE_DISPLAY_FORMAT).format(DATE_API_FORMAT)
      } else {
        value = targetValue
      }
    }

    runValidation(targetValue)
    updateValue(value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value

    setPreviousValue(null)
    setFormattedValue(formatField(targetValue || ""))
    if(!targetValue){
      updateValue(null)
    }
    if(immediateUpdate){
      let value: string | null
      if (targetValue === "") {
        value = null
      } else {
        if (!isDateSupported()) {
          value = moment(targetValue, DATE_DISPLAY_FORMAT).format(DATE_API_FORMAT)
        } else {
          value = targetValue
        }
      }
      runValidation(targetValue)
      updateValue(value)
    }
  }

  const handleInvalidDate = (value?: string) => {
    const date = value
    if (!inputProps?.min || moment(date).isSameOrAfter(inputProps?.min)) {
      if (!inputProps?.max || moment(date).isSameOrBefore(inputProps?.max)){
        return true
      }
    }
    return false
  }

  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses)}
      key={idx}
    >
      {displayLabel && (
        <Label
          className={classnames("col-form-label", labelClasses || "", {
            "col-sm-4": !labelClasses,
          })}
          for={(subtype && ["month", "quarter"].includes(subtype)? "_": "")+`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {
              "tooltip-icon": tooltip,
              "justify-content-start": tooltip?.onClick,
            })}
            id={tooltip ? tooltip.id : ""}
          >
            {displayName}
            {tooltip && (
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => {
                  tooltip.onClick && tooltip.onClick()
                }}
              />
            )}
          </div>
        </Label>
      )}
      <div
        className={classnames("exportable-form-input", inputWrapperClasses || "", {
          "col-sm-8": displayLabel && !inputWrapperClasses,
          "col-sm-12": !(displayLabel || inputWrapperClasses),
          "input-group-col-fixer": subtype && ["month", "quarter"].includes(subtype),
        })}
      >
        {subtype && ["month", "quarter"].includes(subtype) ? (
          <MonthPicker
            id={`${idx}-${shortProperty}`}
            name={property}
            label={property}
            value={propertyValue || ""}
            disabled={!editMode || disabled}
            required={required}
            updateValue={updateValue}
            runValidation={runValidation}
            period={subtype as "month" | "quarter"}
            containerId={containerId || ""}
            inputClasses={classnames(inputClasses)}
            min={inputProps?.min as string}
            max={inputProps?.max as string}
          />
        ) : (
          <Input
            id={`${idx}-${shortProperty}`}
            bsSize="sm"
            name={property}
            label={property}
            type={editMode ? "date" : "text"} // Firefox: hide default placeholders from display mode
            value={formattedValue || ""}
            placeholder={
              (editMode &&
                (placeholder
                  ? placeholder
                  : shouldUseYearInput(shortProperty)
                  ? "YYYY"
                  : DATE_DISPLAY_FORMAT)) ||
              undefined
            }
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            disabled={!editMode || disabled}
            required={required}
            innerRef={inputRef}
            className={classnames(inputClasses, {
              "text-right": !inputClasses?.match(/text-left/),
            })}
            date-format="mm/dd/yyyy"
            // invalid={!handleInvalidDate()}
            {...inputProps}
          ></Input>
        )}
      </div>
    </FormGroup>
  )
}
