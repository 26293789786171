// import { JqName } from "../../../__generated__/graphql"
import { GetLookupDataToOptions } from "../../ui/LookupOptions"
import { FormInputSubClasses } from "../../../helpers/constant"

export interface JobSettingParameterFields {
  parameter: string
  format: string
  type?: string
  subtype?: string
  required?: boolean
  options?: {code: string, value: string}[] | JSX.Element
  subClasses?: { [name in FormInputSubClasses]?: string }
}

export interface JobSettingParametersInterface {
  // Key is JqName
  [key: string]: JobSettingParametersTable
}

export interface JobSettingParametersTable {
  jobName?: string
  parameters: JobSettingParameterFields[]
}

export const methodologyMappingOptions = GetLookupDataToOptions({
  data: [
    {
      code: "time-weighted",
      value: 'Time Weighted',
    },
    {
      code: "MBAI-with-proxy",
      value: 'MBAI with proxy',
    },
    {
      code: "MBAI-straight-line",
      value: 'MBAI straight line',
    },
  ]
}) as JSX.Element

export const returnGoalTypeOptions = GetLookupDataToOptions({
  data: [
    {
      code: "gross",
      value: 'Gross',
    },
    {
      code: "net",
      value: 'Net',
    },
  ]
}) as JSX.Element

export const PortfolioJobSettingDefinitions: JobSettingParametersInterface = {
  "DNA_SUMWEALTH": {
    jobName: "",
    parameters: [
      {
        parameter: "METHODOLOGY",
        format: "string",
        type: "select",
        options: methodologyMappingOptions
      },
      {
        parameter: "END_DATE",
        format: "string",
        type: "date",
        subClasses: {
          inputClasses: "text-left",
        },
      },
      {
        parameter: "FREQUENCY",
        format: "string",
        type: "select",
        options: [
          {
            code: "monthly",
            value: "Monthly",
          },
          {
            code: "quarterly",
            value: "Quarterly",
          }
        ]
      },
      {
        parameter: "USE_FEE_TRANSACTIONS",
        format: "boolean",
        type: "radio",
        subtype: "boolean",
      },
      {
        parameter: "RETURN_GOAL",
        format: "string",
        type: "float",
        subtype: "percent",
      },
      {
        parameter: "GOAL_TYPE",
        format: "string",
        type: "select",
        options: returnGoalTypeOptions,
      },
    ],
  },
}


