import classnames from 'classnames'
import { get, isEmpty, isNil, isNull, isNumber, isUndefined, replace, set } from 'lodash'
import numbro from 'numbro'
import React, { EventHandler, useContext, useEffect, useState } from 'react'
import { FormGroup, Input, Label, InputProps, InputGroupText } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from '@fortawesome/fontawesome-svg-core'
import validate from 'validate.js'

import { NumberInputTrailingStyleType } from '../../../helpers/constant'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { InputTooltip } from './TextFieldInput'

export interface NumberFieldValidationProps {
  min?: number
  max?: number
}

interface NumberFieldInputProps {
  idx: string | number,
  property: string
  displayName: string
  propertyValue: string
  editMode: boolean
  updateValue: (event: any) => void
  wrapperClasses?: string
  labelClasses?: string
  inputWrapperClasses?: string
  inputClasses?: string
  placeholder: string
  currency: boolean
  percent?: boolean
  year?: boolean
  trailing?: NumberInputTrailingStyleType | undefined
  disabled?: boolean
  type?: string
  subtype?: string
  required?: boolean
  noFormat?: boolean
  tooltip?: InputTooltip
  showZero?: boolean
  inputProps?: InputProps
  baseDecimalPlaces?: number
  editDecimalPlaces?: number
  nonNegative?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  charactersLimit?: number
  fieldValidations?: NumberFieldValidationProps
}

export const NumberFieldInput: React.FC<NumberFieldInputProps> = props => {
  const {
    idx,
    property,
    displayName,
    editMode,
    trailing,
    type,
    subtype,
    placeholder,
    labelClasses,
    inputWrapperClasses,
    inputClasses,
    disabled,
    tooltip,
    inputProps,
    inputRef,
    charactersLimit,
    fieldValidations,
  } = props

  let propertyValue = ""
  const context = useContext(EditButtonContext)
  const { setError } = context

  // eslint-disable-next-line eqeqeq
  if ((props.propertyValue == "0" && props.showZero) || props.propertyValue) {
    propertyValue = props.propertyValue
  }
  const baseDecimalPlaces = subtype === "integerPercent" ? 0 : (props.baseDecimalPlaces || 2)
  const editDecimalPlaces = isNil(props.editDecimalPlaces) ? baseDecimalPlaces : props.editDecimalPlaces
  const updateValue: EventHandler<any> = props.updateValue
  let shortProperty = property.split(".").pop() || property
  let currency = props.currency || false
  let percent = props.percent || false
  let year = props.year || false
  let required = props.required || false
  let noFormat = props.noFormat || false
  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || '')
  let fieldSuffix = ""
  if(subtype === 'yearCount') fieldSuffix = " yrs"
  if(subtype === 'multiplier') fieldSuffix = "x"
  if(subtype === 'monthCount') fieldSuffix = " mos"
  if(subtype === 'sqftCount') fieldSuffix = " sq ft"
  if(subtype === 'bps') fieldSuffix = " bps"
  if(percent || subtype === "integerPercent") fieldSuffix = "%"

  const displayLabel = displayName !== ""

  const fieldFormat = () => {
    let decimalPlaces = '00'
    if (editMode) {
      decimalPlaces = '0'.repeat(editDecimalPlaces)
    } else if (baseDecimalPlaces) {
      decimalPlaces = '0'.repeat(baseDecimalPlaces)
    }

    if (currency) {
      if (noFormat) {
        return '$0,0'
      }
      return '$0,0.' + decimalPlaces
    }

    if (type === 'float') {
      if (noFormat) {
        return '0.'+ decimalPlaces
      }
      return '0,0.'+ decimalPlaces
    }

    if (year || noFormat) {
      return '0'
    }

    return '0,0'
  }

  const format = fieldFormat()


  const formatField = (value: string) => {
    value = replace(value, /%/,'')

    if (props.nonNegative) {
      value = replace(value,/-/g,'')
    }

    if (value === '') {
      return ''
    }
    if (value === "NaN" && subtype === "year") {
      // Do not populate blank year fields with 0
      return ''
    }
    return numbro(value).format(format) + fieldSuffix
  }

  let [formattedValue, setFormattedValue] = useState(formatField(propertyValue))

  useEffect(() => {
    setFormattedValue(formatField(propertyValue))
    runValidation(propertyValue === "" ? null : numbro(propertyValue).value())
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [props.editMode, year, required])

  /* this effect fixes input mask residue when clicking cancel button.*/
  useEffect(() => {
    if (props.editMode && document.activeElement?.id === `${idx}-${shortProperty}`) {
      if(formattedValue.includes(".") && [0, undefined].includes(numbro(propertyValue).value()) ) {
        // setFormattedValue(".")
      } else {
        setFormattedValue(propertyValue)
      }
    } else {
      setFormattedValue(formatField(propertyValue))
    }
    runValidation(propertyValue === "" ? null : numbro(propertyValue).value())
  }, [props.propertyValue, props.editMode])

  useEffect(() => {
    runValidation(propertyValue === "" ? null : numbro(propertyValue).value())
  }, [props.fieldValidations])

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value
    let value: number | null

    if (
      isEmpty(targetValue) ||
      isUndefined(targetValue) ||
      isNull(targetValue)
    ) {
      value = null
    } else {

      let newTargetValue
      if (!isNumber(targetValue)) {
        newTargetValue = targetValue.replace(/[^0-9\.\-]/g,'')
      }
      value = numbro(newTargetValue || targetValue).value()
      if(charactersLimit && targetValue.toString().length > charactersLimit) return // prevent input of more than charactersLimit characters
      if(value > Number(1e21)) return // prevent conversion to scientific notation (1e+21)

      if (props.nonNegative) {
        value = Math.abs(value)
      }
    }

    setFormattedValue(targetValue || '')
    runValidation(targetValue === "" ? null : numbro(targetValue).value())
    updateValue(value)
  }

  const handleBlur = (event:React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = event.target.value

    if (!currency && type !== 'float' && targetValue !== "") {
      targetValue = parseInt(targetValue).toString()
    }

    setFormattedValue(targetValue === "" ? "" : formatField(targetValue))
    runValidation(targetValue === "" ? null : numbro(targetValue).value())
  }

  const handleFocus = (event:React.ChangeEvent<HTMLInputElement>) => {
    if (propertyValue !== "") {
      if(percent || subtype === "integerPercent"){
        return setFormattedValue((numbro(propertyValue || 0).value()).toFixed(editDecimalPlaces).toString())
      }
      setFormattedValue(numbro(propertyValue).value().toString())
    }
  }

  const runValidation = (value:number | null) => {
    var inputs:{[key:string]: number | null} = {}
    var constraints:{[key:string]: any} = {}
    inputs[shortProperty] = value


    if (year) {
      set(constraints, [shortProperty, 'numericality'], { greaterThan: 1500, lessThan: 3000 })
    }

    if (required) {
      set(constraints, [shortProperty, 'presence'], true)
    }

    // if (props.nonNegative) {
    //   set(constraints, [shortProperty, 'numericality'], { greaterThanOrEqualTo: 0})
    // }
    if(fieldValidations?.min){
      set(constraints, [shortProperty, 'numericality'], { greaterThanOrEqualTo: fieldValidations.min})
    }

    if(fieldValidations?.max){
      set(constraints, [shortProperty, 'numericality'], { lessThanOrEqualTo: fieldValidations.max})
    }

    if(subtype === "integerPercent"){
      set(constraints, [shortProperty, 'numericality'], { greaterThanOrEqualTo: 0, lessThanOrEqualTo: 100})
    }

    let validations = validate(inputs, constraints)

    if (validations && props.editMode) {
      setError(`${idx}-${shortProperty}`, get(validations, shortProperty))
      setWrapperClasses(wrapperClasses + ' has-error')
    } else {
      setError(`${idx}-${shortProperty}`, [])
      setWrapperClasses(wrapperClasses.replace(/has\-error/g,''))
    }
  }

  return (
    <FormGroup
      className={classnames("form-group", wrapperClasses || "", {row: !wrapperClasses.includes("no-row")})}
      key={idx}
    >
      {displayLabel && (
        <Label
          className={classnames(
            "col-form-label",
            { "col-sm-4": !labelClasses },
            labelClasses || ""
          )}
          for={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
            id={tooltip ? tooltip.id : ""}
          >
            {displayName}
            {tooltip &&
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
              />
            }
          </div>
        </Label>
      )}
      <div
        className={classnames(
          "NumberFieldInput exportable-form-input",
          inputWrapperClasses || "",
          {
            "col-sm-8": displayLabel && !inputWrapperClasses,
            "col-sm-12": !(displayLabel || inputWrapperClasses)
          },
          { "input-group input-group-right": !!trailing },
        )}
      >
        <Input
          id={`${idx}-${shortProperty}`}
          bsSize="sm"
          label={property}
          type={"text"}
          value={formattedValue || ""}
          placeholder={editMode ? placeholder : undefined}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={!editMode || disabled}
          className={classnames({ "text-right": !inputClasses?.match(/text-left/) && !year }, inputClasses || "")}
          innerRef={inputRef}
          data-export-type={"number"}
          {...inputProps}
        ></Input>
        {trailing && (!editMode || disabled) ? (
          <span className={classnames("input-group-addon dollar", {[trailing]: !!trailing})}>{trailing}</span>
        ) : (
          <></>
        )}
        {trailing && (editMode && !disabled) ? (
          <InputGroupText className={classnames("input-group-addon dollar", {[trailing]: !!trailing})}>
            {trailing}
          </InputGroupText>
          ) : (
            <></>
          )}
      </div>
    </FormGroup>
  )
}
