import { compact, findIndex, get, groupBy, intersection, isEqual, map, mapValues, set, sortBy, uniq } from "lodash"
import { AutocompleteItemTypes } from "../Components/Search/SearchAutocomplete"
import { ListDetailFragment, ListDetailItemsFragment, PlanClientPortfolioFragment, StaticListMembersInput } from "../__generated__/graphql"

export type listExpanded = {
  name?: string
  id: string | number
  uniqId: string
  subGroup?: listExpanded[]
  subGroupId?: number
  order?: number
  group?: number
  currentHierarchy?: number[]
  item?: any
}

export enum draggingStatus{
  Initial = 1,
  Dragging = 2,
}

export type ListHeaderTracker = {
  id: number
  status: "created" | "updated" | "deleted"
  value: string
}

export type UpdateListItemsProps = {
  add: StaticListMembersInput,
  remove: StaticListMembersInput,
}

export const typeIdMapping =  {
  'fundid': "vehicle.id",
  'portfolio_num': "id",
  'product_id': "product.id",
  'org_id': "id",
  'indexid': "id",
  'groupid': "id",
  'fund_num': "id",
  'version_id': "id",
  'list_group': "id",
  'list_header': "id",
  'page': "id",
}

export const autocompleteMapping =  {
  'VehicleAutocomplete': 'fundid',
  'ClientPortfolio': 'portfolio_num',
  'ProductAutocomplete': 'product_id',
  'ManagerAutocomplete': 'org_id',
  'CustodianAutocomplete': 'org_id',
  'RecordKeeperAutocomplete': 'org_id',
  'ClientAutocomplete': 'org_id',
  'IndexAutocomplete': 'indexid',
  'GroupAutocomplete': 'groupid',
  'PlanAutocomplete': 'fund_num',
  'TargetDateAutocomplete': 'version_id',
  'PeopleAutocomplete': 'NA',
  'FileAutocomplete': 'NA',
}

export const listMembersMapping =  {
  'fundid': "vehicles",
  'portfolio_num': "clientPortfolios",
  'product_id': "products",
  'org_id': "orgs",
  'fund_num': "plans",
  'version_id': "glidePathVersions",
}

// Convert the data from a list to a tree with correct ordering
export const expandList = (list:ListDetailFragment) => {
  let groupedLists = groupBy(list.items, "group")

  const recursivelyCombineGroups = (group:ListDetailItemsFragment[], currentHierarchy?: number[]):listExpanded[] => {
    return compact(sortBy(group, "order").map((groupMember) => {
      const baseParams = {
        order: groupMember.order || undefined,
        group: groupMember.group || undefined,
        currentHierarchy: currentHierarchy,
        item: groupMember.item,
      }
      if(!groupMember.item){
        return undefined
      }
      if(groupMember.item?.__typename === "ClientPortfolio"){
        return {
          name: groupMember.item.portfolioName || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          ...baseParams
        }
      } else if (groupMember.item?.__typename === "ListGroup" && !currentHierarchy?.includes(groupMember.item.id)) {
        const fetchedGroup = groupedLists[groupMember.item.id]
        let combinedGroup = recursivelyCombineGroups(fetchedGroup, [...(currentHierarchy || []), groupMember.item.id])
        const firstMember = combinedGroup.shift()
        if(firstMember){
          return {
            name: firstMember.name,
            id: firstMember.id,
            uniqId: firstMember.uniqId,
            subGroup: combinedGroup,
            subGroupId: groupMember.item.id,
            ...baseParams,
            item: firstMember.item,
          }
        }
      } else if (groupMember.item?.__typename === "ListHeader") {
        return {
          name: groupMember.item.text || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          ...baseParams
        }
      } else if ("orgName" in groupMember.item){
        return {
          name: groupMember.item.orgName || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          ...baseParams
        }
      } else if ("planName" in groupMember.item){
        return {
          name: groupMember.item.planName || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          ...baseParams
        }
      } else if ("product" in groupMember.item){
        return {
          name: groupMember.item.product?.productName || "",
          id: groupMember.item.product?.id || 0,
          uniqId: `${groupMember.type}:${groupMember.item.product?.id}`,
          ...baseParams
        }
      } else if ("glidePathName" in groupMember.item){
        return {
          name: groupMember.item.glidePathName || "",
          id: groupMember.item.id,
          uniqId: `${groupMember.type}:${groupMember.item.id}`,
          ...baseParams
        }
      } else if ("vehicle" in groupMember.item){
        return {
          name: groupMember.item.vehicle?.vehicleName || "",
          id: groupMember.item.vehicle?.id || "",
          uniqId: `${groupMember.type}:${groupMember.item.vehicle?.id}`,
          ...baseParams
        }
      }
      return undefined
    }))
  }
  // Add this in to make the whole thing join up correctly
  const returnedValue = recursivelyCombineGroups(groupedLists["1"], [])
  return returnedValue
}

// Return List of ids of all expandable rows
export const expandableList = (list: listExpanded[], currentList: string[] = []):string[] => {
  return compact(uniq(list.flatMap((listItem) => {
    if(listItem.subGroup && !currentList.includes(listItem.uniqId)){
      return expandableList(listItem.subGroup, [...currentList, listItem.uniqId])
    }
    return currentList
  })))
}

// update list item with function
export const updateListItem = (items:ListDetailItemsFragment[], uniqId: string, updateFunction: (item:ListDetailItemsFragment) => ListDetailItemsFragment | undefined):ListDetailItemsFragment[] => {
  const [type, id] = uniqId.split(":")

  return compact(map(items, (item) => {
    if(item.type === type && get(item, `item.${typeIdMapping[type]}`, "")?.toString() === id){
      return updateFunction(item)
    }
    return item
  }))
}

// Nicely order a list so it goes from 0-n only increment 1 each time
export const nicelyOrderList = (items:ListDetailItemsFragment[]):ListDetailItemsFragment[] => {
  let groupedSortedItems = mapValues(groupBy(items, "group"),(group) => sortBy(group, "order") )
  let presentGroups = compact(map(items, (item) => item.item?.__typename === "ListGroup" ? item.item.id : null))
  const stringPresentGroups = presentGroups.map(pg => pg.toString()).concat(['1', 'null'])
  let extraGroups = Object.keys(groupedSortedItems).filter(si => !stringPresentGroups.includes(si))
  extraGroups.forEach((eg) => {
    const extraGroup = get(groupedSortedItems, eg)
    const baseGroup = get(groupedSortedItems, '1')
    set(groupedSortedItems, eg, [])
    set(groupedSortedItems, '1', [...extraGroup.map((item) => {
      return {
        ...item,
        group: 1,
      }
    }), ...(baseGroup || [])])
  })

  let niceItems = map(items, (item) => {
    if(item.group){
      let orderModifier = 0
      let group = item.group
      if(!presentGroups.includes(item.group)){
        group = 1
      }
      if(group === 1){
        orderModifier = 1
      } else if (!group){
        return {
          ...item,
          order: null,
          group: null,
        }
      }

      let itemIndex = findIndex(groupedSortedItems[group], (sortedItem) => { return isEqual(sortedItem.item, item.item) })
      return {
        ...item,
        group: group,
        order: orderModifier + itemIndex
      }
    }
    return item
  })

  return niceItems
}

// Get the ordering of the items from tree to array
export const recursivelyOrderItems = (lists: listExpanded[]):listExpanded[] => {
  return lists.reduce((listArray:listExpanded[], listItem) => {
    if(listItem.subGroup){
      return [...listArray, listItem, ...recursivelyOrderItems(listItem.subGroup)]
    }
    return [...listArray, listItem]
  }, [])
}

// Remove items that would be included by their parent already being selected
export const removeRecursiveItems = (allItems:string[], orderedItems:listExpanded[], currentHierarchy:string[]) => {
  // get order and remove children of selected Items
  if(allItems.length > 0){
    // Get the items in the correct order to move
    let selectedParents:number[] = []
    allItems = compact(orderedItems.map((item) => {
      if(allItems.includes(item.uniqId) && intersection(item.currentHierarchy, selectedParents).length === 0 && item.group){
        if(item.subGroupId){
          selectedParents = selectedParents.concat([item.subGroupId])
          if(currentHierarchy.includes(item.uniqId)){
            return undefined
          }
          return `list_group:${item.subGroupId}`
        }
        return item.uniqId
      }
      return undefined
    }))
  }
  // Remove recursive items and current item
  return allItems.filter(n => !currentHierarchy.includes(n))
}

export const listMembersAny = (list:UpdateListItemsProps) => {
  const addMembers = list.add
  const removeMembers = list.remove
  const membersCheck = (members:StaticListMembersInput) => {
    return (members.clientPortfolios && members.clientPortfolios.length > 0) ||
      (members.glidePathVersions && members.glidePathVersions.length > 0) ||
      (members.orgs && members.orgs.length > 0) ||
      (members.plans && members.plans.length > 0) ||
      (members.products && members.products.length > 0) ||
      (members.vehicles && members.vehicles.length > 0)
  }
  return membersCheck(addMembers) || membersCheck(removeMembers)
}

export const autocompleteToItem = (autocomplete:AutocompleteItemTypes) => {
  switch(autocomplete.__typename){
    case "ManagerAutocomplete":
      return {
        __typename: "Manager",
        id: autocomplete.id,
        orgName: autocomplete.name,
        name: autocomplete.name,
      }
    case "CustodianAutocomplete":
      return {
        __typename: "Custodian",
        id: autocomplete.id,
        orgName: autocomplete.custodianName,
        name: autocomplete.custodianName,
      }
    case "RecordKeeperAutocomplete":
      return {
        __typename: "Custodian",
        id: autocomplete.id,
        orgName: autocomplete.recordKeeperName,
        name: autocomplete.recordKeeperName,
      }
    case "ClientAutocomplete":
      return {
        __typename: "Client",
        id: autocomplete.id,
        orgName: autocomplete.clientName,
        name: autocomplete.clientName,
      }
    case "ProductAutocomplete":
      return {
        __typename: "Product",
        product: {
          __typename: "ProductFields",
          id: autocomplete.id,
          productName: autocomplete.productName,
          name: autocomplete.productName,
        }
      }
    case "VehicleAutocomplete":
      return {
        __typename: "Vehicle",
        vehicle: {
          __typename: "VehicleFields",
          id: autocomplete.vehicleId,
          vehicleName: autocomplete.vehicleName,
          name: autocomplete.vehicleName,
          managerName: autocomplete.managerName,
          category: {
            value: autocomplete.vehicleType,
          },
          product: {
            __typename: "Product",
            product: {
              id: autocomplete.productId,
              name: autocomplete.vehicleProductName,
            }
          }
        }
      }
    case "TargetDateAutocomplete":
      return {
        __typename: "GlidePathVersion",
        id: autocomplete.id,
        glidePathName: autocomplete.versionName,
        name: autocomplete.versionName,
      }
    case "ClientPortfolio":
      return {
        __typename: "ClientPortfolio",
        id: autocomplete.id,
        portfolioName: autocomplete.name,
        name: autocomplete.name,
      }
    case "PlanAutocomplete":
      return {
        __typename: "Plan",
        id: autocomplete.id,
        planName: autocomplete.planName,
        name: autocomplete.planName,
      }
    case "PeopleAutocomplete":
      return {
        __typename: "Person",
        id: autocomplete.id,
        firstName: autocomplete.firstName,
        lastName: autocomplete.lastName,
      }
    case "FileAutocomplete":
      return {
        __typename: "File",
        id: autocomplete.fileid,
        name: autocomplete.fileName,
      }
    case "IndexAutocomplete":
      return {
        __typename: "Index",
        id: autocomplete.indexId,
        indexName: autocomplete.indexName,
      }
    case "GroupAutocomplete":
      return {
        __typename: "Group",
        id: autocomplete.groupId,
        groupName: autocomplete.groupName,
      }
  }
}