import { ApolloClient, ApolloProvider, createHttpLink, DefaultOptions } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { LicenseManager } from "@ag-grid-enterprise/core"
import LogRocket from 'logrocket'

import introspectionQueryResultData from '../src/__generated__/introspection-result.json'
import { name, uri, version, gtmId, gtmAuth, gtmPreview, logrocketId } from './environment'
import MainRoutes from './mainRoutes'
import { CrumbRoute } from './routes/crumb-route'
import * as serviceWorker from './serviceWorker'
import { PathnameProvider } from './Context/PathnameContext'

import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import { CsvExportModule } from "@ag-grid-community/csv-export"
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export"
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    CsvExportModule,
    ExcelExportModule,
    MenuModule,
    RowGroupingModule,
    SetFilterModule,
    ServerSideRowModelModule,
    RichSelectModule,
]);

if (gtmId != "") {
  TagManager.initialize({
    gtmId: gtmId,
    auth: gtmAuth,
    preview: gtmPreview,
  })
}

if (logrocketId != "" && process.env.NODE_ENV !== 'development') {
  LogRocket.init(logrocketId)
}


LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-052685}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Callan_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Callan_DNA}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Callan_DNA}_need_to_be_licensed___{Callan_DNA}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_March_2025}____[v3]_[01]_MTc0MDc4NzIwMDAwMA==b93b7b7b3c80825a9cf0888883c46e36");

// authentication
const httpLink = createHttpLink({uri})
const authLink = setContext((_, { headers }) => {
  const authorization = localStorage.getItem("access_token")
    ? `Bearer ${localStorage.getItem("access_token")}`
    : undefined
  return {
    headers: {
      ...headers,
      authorization
    }
  }})

const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
  typePolicies: {
    WeightedExposures: {
      // Singleton types that have no identifying field can use an empty
      // array for their keyFields.
      keyFields: [],
    },
    Characteristics: {
      // Singleton types that have no identifying field can use an empty
      // array for their keyFields.
      keyFields: [
        "active",
        "country",
        "date",
        "region",
        "sector",
        "substitution",
      ],
    }
  },
})
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-first"
  },
  query: {
    fetchPolicy: "cache-first"
  }
}
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  name,
  version,
  cache,
  defaultOptions,
})

const history = createBrowserHistory()

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={history}>
      {/** wrap on parent component of MainRoutes to catch pathname value.*/}
      <PathnameProvider>
        <CrumbRoute title="Home" path="/" component={MainRoutes} />
      </PathnameProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
