import { useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { cloneDeep, compact, filter, find, findIndex, first, get, groupBy, isNaN, isNil, maxBy, set, sortBy, uniqueId } from 'lodash'
import moment from 'moment'
import numbro from 'numbro'
import { default as React, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row, Table, UncontrolledTooltip } from 'reactstrap'
import Auth from '../../../Auth/Auth'

import { AutocompleteFilters, ClientPerformanceTypeCode, ClientPortfolioDetailLayoutFragment, ComponentOverrideSettings, ComponentType, Error, Fact, FootnoteShowFragment, FootnoteShowTargetFragment, GroupPerformanceTypeCode, LayoutSectionType, ManagerPerformanceType, Maybe, PlanClientPortfolioFragment, ReportCategoryCode, ReportingPerformancePeriod, ReportPerformanceComparisonDcPortfolioFragment, ReportPerformanceComparisonFetchFragment, ReportPerformanceComparisonPerformanceFragment, ReportPerformanceComparisonRankingFragment, ReportPerformanceComparisonTrailingClientPortfolioFragment, ReportPerformanceComparisonTrailingGroupDocument, ReportPerformanceComparisonTrailingGroupFragment, ReportPerformanceComparisonTrailingGroupQueryVariables, ReportPerformanceComparisonTrailingIndexDocument, ReportPerformanceComparisonTrailingIndexFragment, ReportPerformanceComparisonTrailingPortfolioDocument, ReportPerformanceComparisonTrailingPortfolioQueryVariables, ReportPerformanceComparisonTrailingTargetFragment, ReportPerformanceComparisonTrailingVehicleDefaultDocument, ReportPerformanceComparisonTrailingVehicleDocument, ReportPerformanceComparisonTrailingVehicleFragment, ReportPerformanceComparisonYearsClientPortfolioFragment, ReportPerformanceComparisonYearsGroupDocument, ReportPerformanceComparisonYearsGroupFragment, ReportPerformanceComparisonYearsGroupQueryVariables, ReportPerformanceComparisonYearsIndexDocument, ReportPerformanceComparisonYearsIndexFragment, ReportPerformanceComparisonYearsPortfolioDocument, ReportPerformanceComparisonYearsPortfolioQueryVariables, ReportPerformanceComparisonYearsTargetFragment, ReportPerformanceComparisonYearsVehicleDefaultDocument, ReportPerformanceComparisonYearsVehicleDocument, ReportPerformanceComparisonYearsVehicleFragment, ReportsFragment, usePlanPortfoliosQuery, useReportPerformanceComparisonDcPortfolioQuery, VehiclePerformanceTypeCode } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, DATE_TOOLTIP_FORMAT } from '../../../helpers/constant'
// import { ClientAssets, ReportsListFragment, useListSimpleQuery, ComponentType, ClientPortfolioDetailLayoutFragment, ReportsFragment, LayoutSectionType, ComponentOverrideSettings, ReportAssetAllocationFetchFragment } from '../../../__generated__/graphql'
import SearchAddStatic from '../../Search/SearchAddStatic'
import { AutocompleteItemTypes } from '../../Search/SearchAutocomplete'
import { ApprovalsReportDisplay } from '../Shared/ReportApprovals'
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, currencyFormat, ErrorComponent, FootnotableComponent, FootnoteLink, getReportLinks, IconMapping, IndividualComponentProps, LoadingComponent, percentFormat, rankFormat, rankingFormat, ReportTab, TemplateComponent, ToolbarProps, LinkableReportFragments, OptionsModal } from '../Shared/ReportComponent'
import { ReportDisplayType, selectedComponentProp } from '../Shared/ReportComponent'
import { AggregatedReportEditProps, ReportEditField, ReportEditFootnoteFields, ReportEditInfoFields, ReportEditTemplate, SimpleReportEdit } from '../Shared/ReportEdit'
import { FormInput } from '../../ui/Forms/FormInput'
import { highContrastColors } from '../../../helpers/highCharts'
import { GetLookupDataToOptions } from '../../ui/LookupOptions'
import iassign from 'immutable-assign'

const PerformanceComparison: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportPerformanceComparisonFetchFragment>
      expectedTypename={"PerformanceComparison"}
      reactComponent={PerformanceComparisonDisplay}
      {...props}
    />
  )
}

// Used to store state that must be shared with export
type componentState = {
  tab?: ReportTab
  hoveredData?: any | null
}

type BaseDataPoint = {
  color?: string
}

type ScatterDataPoint = BaseDataPoint & {
  y?: number
  rank?: number
}

type ColumnDataPoint = BaseDataPoint & {
  low?: number
  high?: number
}

type GraphData = {
  name: string
  id?: string
  color: string
  data: (ColumnDataPoint | ScatterDataPoint | undefined)[]
  type?: string
  sourceType?: string
  pointPlacement?: number
  marker?: {
    symbol?: string
    radius?: number
  }
  footnote?: FootnoteShowFragment | null
  targetFootnotes?: Maybe<FootnoteShowTargetFragment>[] | null
  dataLabels?: {
    align?: string
  }
  links?: FootnoteLink[]
  extraLabel?: "Gross" | "Net"
  source?: SourceTypes
}

type HoveredData = {
  category: string
  name: string
}

type HeadingDefinition = {
  booleanVariable?: string
  displayText: string
  graphqlVariableGross: string
  graphqlVariableNet: string
  graphqlVariableTarget: string
  counter: number
}

export const headingOrder = (settings:ReportPerformanceComparisonFetchFragment, fiscalMonths:number, searchDate:string) => {
  const ytdMonths = moment(searchDate, DATE_API_FORMAT).month() + 1
  const shouldShowTrailing = (heading:HeadingDefinition) => {
    // months are 0 indexed
    if(heading.booleanVariable === 'lastQuarter' && ((settings.performanceComparisonPeriod?.fiscalYear && fiscalMonths === 3) || (settings.performanceComparisonPeriod?.yearToDate && ytdMonths === 3))){
      return false
    } else if(heading.booleanVariable === 'lastMonth' && ((settings.performanceComparisonPeriod?.fiscalYear && fiscalMonths === 1) || (settings.performanceComparisonPeriod?.yearToDate && ytdMonths === 1))){
      return false
    } else if(heading.booleanVariable === 'last1Year' && ((settings.performanceComparisonPeriod?.fiscalYear && fiscalMonths === 12) || (settings.performanceComparisonPeriod?.yearToDate && ytdMonths === 12))){
      return false
    } else if(heading.booleanVariable === 'fiscalYear' && settings.performanceComparisonPeriod?.yearToDate && ytdMonths === fiscalMonths){
      return false
    }
    // console.log(get(settings.performanceComparisonPeriod, heading.booleanVariable || ""), settings.performanceComparisonPeriod, heading.booleanVariable, settings, heading.booleanVariable)
    return get(settings.performanceComparisonPeriod, heading.booleanVariable || "") || (get(settings, heading.booleanVariable || "")) || false
  }

  let baseTrailingOrder:HeadingDefinition[] = [
    // {booleanVariable: "lastMonth", displayText: "1 Month", graphqlVariableGross: "monthlyGross", graphqlVariableNet: "monthlyNet", graphqlVariableTarget: "monthly", counter: 1},
    {booleanVariable: "lastQuarter", displayText: "1 Quarter", graphqlVariableGross: "quarterlyGross", graphqlVariableNet: "quarterlyNet", graphqlVariableTarget: "quarterly", counter: 3},
    {booleanVariable: "fiscalYear", displayText: "Fiscal YTD", graphqlVariableGross: "fiscalYearGross", graphqlVariableNet: "fiscalYearNet", graphqlVariableTarget: "fiscalYear", counter: fiscalMonths},
    {booleanVariable: "yearToDate", displayText: "YTD", graphqlVariableGross: "ytdGross", graphqlVariableNet: "ytdNet", graphqlVariableTarget: "ytd", counter: ytdMonths},
    {booleanVariable: "last1Year", displayText: "1 Year", graphqlVariableGross: "yearlyGross", graphqlVariableNet: "yearlyNet", graphqlVariableTarget: "yearly", counter: 12},
    {booleanVariable: "last2Years", displayText: "2 Years", graphqlVariableGross: "twoYearlyGross", graphqlVariableNet: "twoYearlyNet", graphqlVariableTarget: "twoYearly", counter: 24},
    {booleanVariable: "last3Years", displayText: "3 Years", graphqlVariableGross: "threeYearlyGross", graphqlVariableNet: "threeYearlyNet", graphqlVariableTarget: "threeYearly", counter: 36},
    {booleanVariable: "last5Years", displayText: "5 Years", graphqlVariableGross: "fiveYearlyGross", graphqlVariableNet: "fiveYearlyNet", graphqlVariableTarget: "fiveYearly", counter: 60},
    {booleanVariable: "last7Years", displayText: "7 Years", graphqlVariableGross: "sevenYearlyGross", graphqlVariableNet: "sevenYearlyNet", graphqlVariableTarget: "sevenYearly", counter: 84},
    {booleanVariable: "last10Years", displayText: "10 Years", graphqlVariableGross: "tenYearlyGross", graphqlVariableNet: "tenYearlyNet", graphqlVariableTarget: "tenYearly", counter: 120},
    {booleanVariable: "last15Years", displayText: "15 Years", graphqlVariableGross: "fifteenYearlyGross", graphqlVariableNet: "fifteenYearlyNet", graphqlVariableTarget: "fifteenYearly", counter: 180},
    {booleanVariable: "last20Years", displayText: "20 Years", graphqlVariableGross: "twentyYearlyGross", graphqlVariableNet: "twentyYearlyNet", graphqlVariableTarget: "twentyYearly", counter: 240},
    {booleanVariable: "sinceInception", displayText: "Since Inception", graphqlVariableGross: "sinceInceptionGross", graphqlVariableNet: "sinceInceptionNet", graphqlVariableTarget: "sinceInception", counter: 600},
  ]

  const calendarYears = settings.reportingPerformancePeriod === ReportingPerformancePeriod.CalendarYears
  const preYearText = settings.reportingPerformancePeriod === ReportingPerformancePeriod.FiscalYears ? "FY " : ""
  let yearDisplayText = `${moment(searchDate).year()}`
  if(calendarYears) {
    if(ytdMonths !== 12) yearDisplayText = "YTD " + yearDisplayText
  } else {
    if(fiscalMonths === 12){
      yearDisplayText = preYearText + yearDisplayText
    } else {
      yearDisplayText = "FYTD " + yearDisplayText
    }
  }
  let baseYearsOrder:HeadingDefinition[] = [
    {displayText: yearDisplayText, graphqlVariableGross: calendarYears ? "ytdGross" : "fiscalGross", graphqlVariableNet: calendarYears ? "ytdNet" : "fiscalNet", graphqlVariableTarget: calendarYears ? "ytd" : "fiscal", counter: 1},
    {displayText: `${preYearText}${moment(searchDate).subtract(1, 'year').year()}`, graphqlVariableGross: "yearTwoGross", graphqlVariableNet: "yearTwoNet", graphqlVariableTarget: "yearTwo", counter: 2},
    {displayText: `${preYearText}${moment(searchDate).subtract(2, 'year').year()}`, graphqlVariableGross: "yearThreeGross", graphqlVariableNet: "yearThreeNet", graphqlVariableTarget: "yearThree", counter: 3},
    {displayText: `${preYearText}${moment(searchDate).subtract(3, 'year').year()}`, graphqlVariableGross: "yearFourGross", graphqlVariableNet: "yearFourNet", graphqlVariableTarget: "yearFour", counter: 4},
    {displayText: `${preYearText}${moment(searchDate).subtract(4, 'year').year()}`, graphqlVariableGross: "yearFiveGross", graphqlVariableNet: "yearFiveNet", graphqlVariableTarget: "yearFive", counter: 5},
    {displayText: `${preYearText}${moment(searchDate).subtract(5, 'year').year()}`, graphqlVariableGross: "yearSixGross", graphqlVariableNet: "yearSixNet", graphqlVariableTarget: "yearSix", counter: 6},
    {displayText: `${preYearText}${moment(searchDate).subtract(6, 'year').year()}`, graphqlVariableGross: "yearSevenGross", graphqlVariableNet: "yearSevenNet", graphqlVariableTarget: "yearSeven", counter: 7},
    {displayText: `${preYearText}${moment(searchDate).subtract(7, 'year').year()}`, graphqlVariableGross: "yearEightGross", graphqlVariableNet: "yearEightNet", graphqlVariableTarget: "yearEight", counter: 8},
    {displayText: `${preYearText}${moment(searchDate).subtract(8, 'year').year()}`, graphqlVariableGross: "yearNineGross", graphqlVariableNet: "yearNineNet", graphqlVariableTarget: "yearNine", counter: 9},
    {displayText: `${preYearText}${moment(searchDate).subtract(9, 'year').year()}`, graphqlVariableGross: "yearTenGross", graphqlVariableNet: "yearTenNet", graphqlVariableTarget: "yearTen", counter: 10},
  ]

  if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing){
    return sortBy(filter(baseTrailingOrder, shouldShowTrailing) as HeadingDefinition[], (o) => o.counter)
  } else {
    return filter(baseYearsOrder, (o) => o.counter <= (settings.numberOfYearsToInclude || 5)) as HeadingDefinition[]
  }
}


// type TableTotals = {
//   name: string,
//   y: number,
//   weight: number,
//   target: number,
//   portfolio?: ReportPerformanceComparisonClientPortfolioFragment
// }

export const blankTrailingPerformancePeriod = {
  lastQuarter: false,
  yearToDate: false,
  fiscalYear: false,
  last1Year: false,
  last2Years: false,
  last3Years: false,
  last5Years: false,
  last7Years: false,
  last10Years: false,
  last15Years: false,
  last20Years: false,
  sinceInception: false,
}

export const yearsPerformanceKeys = ["Two", "Three" ,"Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"]

type PeriodSettings = {
  [key: string]: boolean | string
}

interface getPerformanceComparisonTrailingVariablesProps {
  settings: ReportPerformanceComparisonFetchFragment
  report?: ReportsFragment
  searchDate: string
}

export const getPerformanceComparisonTrailingVariables = ({settings, searchDate, report}:getPerformanceComparisonTrailingVariablesProps) => {
  let trailingSettingsSplit:PeriodSettings = {}
  let trailingSettingsBase:PeriodSettings = {}
  let yearsSettingsSplit:PeriodSettings = {}
  let yearsSettingsBase:PeriodSettings = {}
  const portfolioNetResults = settings.portfolioPerformanceType === ManagerPerformanceType.Net || (settings.portfolioPerformanceType === ManagerPerformanceType.Default && ((settings.clientPortfolio?.performanceType?.code && [ClientPerformanceTypeCode.BOTH, ClientPerformanceTypeCode.NET].includes(settings.clientPortfolio?.performanceType?.code)) || !settings.clientPortfolio))
  const portfolioGrossResults = settings.portfolioPerformanceType === ManagerPerformanceType.Gross || (settings.portfolioPerformanceType === ManagerPerformanceType.Default && settings.clientPortfolio?.performanceType?.code && [ClientPerformanceTypeCode.BOTH, ClientPerformanceTypeCode.GROSS].includes(settings.clientPortfolio?.performanceType?.code))
  const groupPerformanceType = !!settings?.group ? (settings?.groupPerformanceType?.code || settings.clientPortfolio?.groupPerformanceType?.code) : settings.clientPortfolio?.groupPerformanceType?.code
  // const groupNetResults = groupPerformanceType !== GroupPerformanceTypeCode.GROSS
  // const groupGrossResults = !groupPerformanceType || ![GroupPerformanceTypeCode.EQNET, GroupPerformanceTypeCode.INSNET].includes(groupPerformanceType)
  const grossResults = portfolioGrossResults
  const netResults = portfolioNetResults
  const group = settings?.group || settings?.clientPortfolio?.styleGroup || undefined
  const years = settings?.numberOfYearsToInclude || 5
  Object.entries({...blankTrailingPerformancePeriod, ...settings.performanceComparisonPeriod}).forEach(([key, value]) => {
    if(key !== "__typename"){
      if(!!value){
        trailingSettingsSplit[`${key}Gross`] = grossResults || false
        trailingSettingsSplit[`${key}Net`] = netResults || false
        trailingSettingsBase[`${key}`] = true
      } else {
        trailingSettingsSplit[`${key}Gross`] = false
        trailingSettingsSplit[`${key}Net`] = false
        trailingSettingsBase[`${key}`] = false
      }
    }
  })

  const planMonth = moment(`${first(report?.plans)?.fiscalMonth} 1 2022`).month() || 0
  const componentMonth = moment(searchDate).month()
  let fiscalMonths, fiscalQuarters
  if(planMonth === componentMonth){
    fiscalMonths = 12
    fiscalQuarters = 4
  } else {
    fiscalMonths = (componentMonth + 12 - planMonth) % 12
    fiscalQuarters = Math.floor(fiscalMonths / 3) || 1
  }

  const baseDate = settings.reportingPerformancePeriod === ReportingPerformancePeriod.FiscalYears ?
    moment(searchDate, DATE_API_FORMAT).subtract(fiscalQuarters, 'quarter').endOf('month') :
    moment(searchDate, DATE_API_FORMAT).subtract(1, 'year').endOf('year')

  yearsPerformanceKeys.forEach((key, idx) => {
    yearsSettingsBase[`year${key}Date`] = moment(baseDate).subtract(idx, 'year').format(DATE_API_FORMAT)
    if((idx + 2) <= years){
      yearsSettingsSplit[`year${key}Gross`] = grossResults || false
      yearsSettingsSplit[`year${key}Net`] = netResults || false
      yearsSettingsBase[`year${key}`] = true
    } else {
      yearsSettingsSplit[`year${key}Gross`] = false
      yearsSettingsSplit[`year${key}Net`] = false
      yearsSettingsBase[`year${key}`] = false
    }
  })
  if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.FiscalYears){
    yearsSettingsSplit.fiscalGross = grossResults || false
    yearsSettingsSplit.fiscalNet = netResults || false
    yearsSettingsBase.fiscal = true
    yearsSettingsSplit.yearToDateGross = false
    yearsSettingsSplit.yearToDateNet = false
    yearsSettingsBase.yearToDate = false
  } else {
    yearsSettingsSplit.fiscalGross = false
    yearsSettingsSplit.fiscalNet = false
    yearsSettingsBase.fiscal = false
    yearsSettingsSplit.yearToDateGross = grossResults || false
    yearsSettingsSplit.yearToDateNet = netResults || false
    yearsSettingsBase.yearToDate = true
  }

  const trailingVariables = { monthDate: searchDate, groupId: group?.id || "", actualPerformance: false, groupPerformanceType, fiscalQuarters, ...trailingSettingsBase }
  const trailingVariablesPortfolio = { ...trailingVariables, ...trailingSettingsSplit }
  const yearsVariables = { monthDate: searchDate, groupId: group?.id || "", actualPerformance: false, groupPerformanceType, fiscalQuarters, ...yearsSettingsBase }
  const yearsVariablesPortfolio = { ...yearsVariables, ...yearsSettingsSplit }
  // const queryMonthlyVariables = { ...baseVariables, fiscalMonths } as ReportManagerPerformanceMonthDetailQueryVariables
  // const { lastMonthGross, lastMonthNet, fiscalMonths: removedFiscalMonths, ...queryQuarterlyVariables } = { ...queryMonthlyVariables, fiscalQuarters }
  return { trailingVariables, trailingVariablesPortfolio, yearsVariables, yearsVariablesPortfolio, fiscalMonths }
}

const PerformanceComparisonDisplay: React.FC<IndividualComponentProps<ReportPerformanceComparisonFetchFragment>> = (props) => {
  const {componentNumber, settings, report, setReportState, overwriteDate} = props
  const reportState = props.reportState as componentState
  const draftView = props.view === ReportDisplayType.Draft
  // const primaryTab = {label: currentDate.format("[Q]Q YYYY"), value: settings.date, primary: true}
  // const tabs = sortBy([primaryTab, ...(settings.monthlyOptions?.dates?.map((date) => ({label: moment(date).format("MMM YYYY"), value: date})) || [])] as ReportTab[], (tab) => (tab.primary ? 0 : 1) - moment(tab.value).valueOf() )
  // // const [tab, setTab] = useState(primaryTab)
  // useEffect(()=>{
  //   setReportState({tab: primaryTab})
  // }, [])
  // const tab = reportState?.tab || primaryTab
  // const setTab = (value:any) => {
  //   setReportState({tab: value})
  // }

  const asOfDate = moment(overwriteDate || settings.date).format(DATE_API_FORMAT)
  const { trailingVariables, trailingVariablesPortfolio, yearsVariables, yearsVariablesPortfolio, fiscalMonths } = getPerformanceComparisonTrailingVariables({settings, searchDate: asOfDate, report})

  // const {data, refetch} = usePerformanceOrderIdQuery({variables: {request: JSON.stringify({variables: trailingVariables, queryHash: stringHash(JSON.stringify(REPORT_PERFORMANCE_COMPARISON_TRAILING_PORTFOLIO_QUERY))}) }})

  // const {data: userData} = useMeReportQuery()

  // useEffect(()=>{
  //   if(refetch){
  //     refetch()
  //   }
  // }, [props.settings])

  if(!(settings.clientPortfolio?.styleGroup?.id || settings.group?.id) && settings.vehicles?.length === 0){
    return <ErrorComponent name={props.component.name || ""} error={"Data points missing. The component must contain one group and one portfolio or vehicle."}/>
  } else if(!(settings.clientPortfolio?.styleGroup?.id || settings.group?.id)){
    return <ErrorComponent name={props.component.name || ""} error={"Group missing. The component must contain one group."}/>
  } else if(!(settings.clientPortfolio?.styleGroup?.id || settings.vehicles?.length != 0)){
    return <ErrorComponent name={props.component.name || ""} error={"Data points missing. The component must contain one portfolio or vehicle."}/>
  }

  if(true){//(data && data.performanceOrderID)){
    return (
      <PerformanceComparisonDisplayWithId
        // key={data?.performanceOrderID}
        // orderId={data?.performanceOrderID}
        // refetchId={refetch}
        {...props}
        trailingVariables={trailingVariables as ReportPerformanceComparisonTrailingGroupQueryVariables}
        trailingVariablesPortfolio={trailingVariablesPortfolio as ReportPerformanceComparisonTrailingPortfolioQueryVariables}
        yearsVariables={yearsVariables as ReportPerformanceComparisonYearsGroupQueryVariables}
        yearsVariablesPortfolio={yearsVariablesPortfolio as ReportPerformanceComparisonYearsPortfolioQueryVariables}
        fiscalMonths={fiscalMonths}
        // user={userData?.me}
      />
    )
  }
  return <LoadingComponent name={props.component.name || ""} componentNumber={componentNumber}/>
}

type ManagerPerformanceDisplayWithIdProps = IndividualComponentProps<ReportPerformanceComparisonFetchFragment> & {
  // orderId: number
  // refetchId: () => void
  trailingVariables: ReportPerformanceComparisonTrailingGroupQueryVariables
  trailingVariablesPortfolio: ReportPerformanceComparisonTrailingPortfolioQueryVariables
  yearsVariables: ReportPerformanceComparisonYearsGroupQueryVariables
  yearsVariablesPortfolio: ReportPerformanceComparisonYearsPortfolioQueryVariables
  fiscalMonths: number
}

const PerformanceComparisonDisplayWithId: React.FC<ManagerPerformanceDisplayWithIdProps> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, componentNumber, report, setEditedDraftLayout, editedDraftLayout, portfolioId, setSelectedComponentId, view, setReportState, reportState: inState, trailingVariables, trailingVariablesPortfolio, yearsVariables, yearsVariablesPortfolio, fiscalMonths, clientId, updateSettings, overwriteDate }) => {
  const apolloClient = useApolloClient()
  const name = component.name || ""
  const reportState = inState as componentState
  const componentId = component.id
  const usedDate = overwriteDate || settings.date
  const asOfDate = moment(usedDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)
  const [group, setGroup] = useState<ReportPerformanceComparisonTrailingGroupFragment | ReportPerformanceComparisonYearsGroupFragment | undefined>(undefined)
  const [portfolio, setPortfolio] = useState<ReportPerformanceComparisonTrailingClientPortfolioFragment | ReportPerformanceComparisonYearsClientPortfolioFragment | undefined>(undefined)
  const [sources, setSources] = useState<SourceTypes[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const abortControllerRef = useRef(new AbortController()) // Turned off as it was causing crashes
  // const primaryTab = {label: currentDate.format("[Q]Q YYYY"), value: settings.date, primary: true} as ReportTab
  // const tabs = sortBy([primaryTab, ...(settings.monthlyOptions?.dates?.map((date:any) => ({label: moment(date).format("MMM YYYY"), value: date})) || [])] as ReportTab[], (tab) => (tab.primary ? 0 : 1) - moment(tab.value).valueOf() )
  // useEffect(()=>{
  //   setReportState({tab: primaryTab})
  // }, [])
  // const tab = reportState?.tab || primaryTab
  // const setTab = (value:any) => {
  //   setReportState({tab: value})
  // }
  const {data: dcData, loading: dcLoading, error: dcError} = useReportPerformanceComparisonDcPortfolioQuery({
    variables: {
      id: settings.DCTotalFundPortfolio?.id || 0,
      monthDate: usedDate,
      // nextDate: moment(usedDate).add(1, 'quarter').endOf('quarter').format(DATE_API_FORMAT),
      aum: 1
    },
    skip: !(settings.showDCInfo && settings.DCTotalFundPortfolio?.id)
  })

  // Load the required queries into an array to be fetched
  let queries = useMemo(() => {
    let queries = []
    // PORTFOLIO QUERY
    if(settings.clientPortfolio){
      const clientPortfolioExtraVariables = {
        id: settings.clientPortfolio.id,
        priorDate: moment(usedDate).subtract(1, 'quarter').endOf('quarter').format(DATE_API_FORMAT),
        withGroup: settings.group?.id ? false : true,
        // nextDate: moment(usedDate).add(1, 'quarter').endOf('quarter').format(DATE_API_FORMAT),
      }
      if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing){
        queries.push({query: ReportPerformanceComparisonTrailingPortfolioDocument, variables: {...trailingVariablesPortfolio, ...clientPortfolioExtraVariables}, type: "portfolio"})
      } else {
        queries.push({query: ReportPerformanceComparisonYearsPortfolioDocument, variables: {...yearsVariablesPortfolio, ...clientPortfolioExtraVariables}, type: "portfolio"})
      }
    }

    // VEHICLE QUERIES
    settings.vehicles?.forEach((vehicle, idx) => {
      let vehicleExtraVariables:{id: string | undefined,vehiclePerformanceType?: VehiclePerformanceTypeCode} = {
        id: vehicle?.vehicle?.fundid,
      }
      if(!settings.vehiclePerformanceType?.code || settings.vehiclePerformanceType?.code === VehiclePerformanceTypeCode.DEF){
        if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing){
          queries.push({query: ReportPerformanceComparisonTrailingVehicleDefaultDocument, variables: {...trailingVariables, ...vehicleExtraVariables}, type: "vehicle"})
        } else {
          queries.push({query: ReportPerformanceComparisonYearsVehicleDefaultDocument, variables: {...yearsVariables, ...vehicleExtraVariables}, type: "vehicle"})
        }
      } else {
        vehicleExtraVariables.vehiclePerformanceType = settings.vehiclePerformanceType.code
        if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing){
          queries.push({query: ReportPerformanceComparisonTrailingVehicleDocument, variables: {...trailingVariables, ...vehicleExtraVariables}, type: "vehicle"})
        } else {
          queries.push({query: ReportPerformanceComparisonYearsVehicleDocument, variables: {...yearsVariables, ...vehicleExtraVariables}, type: "vehicle"})
        }
      }
    })

    // TARGET/INDEX QUERIES
    settings.indices?.forEach((index, idx) => {
      const indexId = index?.id
      if(!indexId) return
      if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing){
        queries.push({query: ReportPerformanceComparisonTrailingIndexDocument, variables: {...trailingVariables, id: indexId}, type: "index"})
      } else {
        queries.push({query: ReportPerformanceComparisonYearsIndexDocument, variables: {...yearsVariables, id: indexId}, type: "index"})
      }
    })

    // GROUP QUERIES
    if(settings.group?.id){
      const groupExtraVariables = {
        id: settings.group.id,
        // groupPerformanceType: settings.groupPerformanceType?.code,
      }
      if(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing){
        queries.push({query: ReportPerformanceComparisonTrailingGroupDocument, variables: {...trailingVariables, ...groupExtraVariables}, type: "group"})
      } else {
        queries.push({query: ReportPerformanceComparisonYearsGroupDocument, variables: {...yearsVariables, ...groupExtraVariables}, type: "group"})
      }
    }

    return queries
  }, [settings, usedDate])

  useEffect(() => {
    setLoading(true)
    // Reset data so that it does not add together
    setGroup(undefined)
    setSources([])
    if(abortControllerRef.current){
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    const executeQueries = async () => {
      const results = await Promise.all(
        queries.map(async ({ query, variables }) => {
          return apolloClient.query({
            query,
            variables,
            context: {
              fetchOptions: {
                signal: abortControllerRef.current.signal
              }
            }
          })
        })
      );

      // Send the data to the required state variables
      results.forEach((result) => {
        const data = result.data
        if(result.errors) setErrors((errors) => compact([...errors, ...(data?.errors?.map((error:Error) => error.message) || [])]))
        if("clientPortfolio" in data){
          if(data.clientPortfolio?.styleGroup?.id) setGroup(data.clientPortfolio.styleGroup)
          setSources((sources) =>
            compact([
              ...sources,
              data.clientPortfolio,
              ...(data.clientPortfolio.performanceTargetMap?.map(
                (ptm: any) => ({
                  ...ptm?.target,
                  footnote: first(
                    data.clientPortfolio.targetFootnotes?.filter(
                      (tf: FootnoteShowTargetFragment) =>
                        tf?.clientPortfolioTargets?.some(
                          (cpt) =>
                            cpt?.clientPortfolio?.id ===
                              data.clientPortfolio.id &&
                            ptm?.target?.targetId === ptm.target?.targetId
                        )
                    )
                  ),
                })
              ) || []),
            ])
          )
          setPortfolio(data.clientPortfolio)
        }
        if("vehicle" in data){
          setSources((sources) => compact([...sources, data.vehicle?.vehicle]))
        }
        if("index" in data){
          setSources((sources) => compact([...sources, data.index]))
        }
        if("group" in data){
          setGroup(data.group)
        }
      })
      setLoading(false)
    };

    executeQueries();
  }, [queries]);

  // useEffect(() => {
  //   setTab(primaryTab)
  // }, [settings.date, settings.monthlyOptions])

  if(loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} selected={selected} onClick={handleSelect}/>
  if(errors.length > 0) return <ErrorComponent name={name} error={errors.join(', ')} rightText={`As of ${asOfDate}`} selected={selected} onClick={handleSelect}/>

  if(group && sources.length > 0){
    return <PerformanceComparisonDetail
      name={name}
      group={group}
      sources={sources}
      portfolio={portfolio}
      settings={settings}
      asOfDate={asOfDate}
      selected={selected}
      handleSelect={handleSelect}
      // tab={tab}
      // tabs={tabs}
      // setTab={setTab}
      editMode={editMode}
      sectionNumber={sectionNumber}
      componentNumber={componentNumber}
      report={report}
      setEditedDraftLayout={setEditedDraftLayout}
      editedDraftLayout={editedDraftLayout}
      updateSettings={updateSettings}
      portfolioId={portfolioId}
      setSelectedComponentId={setSelectedComponentId}
      view={view}
      componentId={componentId}
      dcPortfolio={dcData?.clientPortfolio || undefined}
      auth={auth}
      setReportState={setReportState}
      reportState={reportState}
      fiscalMonths={fiscalMonths}
      clientId={clientId}
      usedDate={usedDate}
    />
  }

  return <ErrorComponent name={name} error={"No data returned"} rightText={`As of ${asOfDate}`} selected={selected} onClick={handleSelect}/>
}

export type SourceTypes = ReportPerformanceComparisonTrailingTargetFragment | ReportPerformanceComparisonTrailingIndexFragment | ReportPerformanceComparisonTrailingClientPortfolioFragment | ReportPerformanceComparisonTrailingVehicleFragment | ReportPerformanceComparisonYearsTargetFragment | ReportPerformanceComparisonYearsIndexFragment | ReportPerformanceComparisonYearsClientPortfolioFragment | ReportPerformanceComparisonYearsVehicleFragment

const markerTypeMapping:{[key in SourceTypes["__typename"]]: string} = {
  "ClientPortfolio": "circle",
  "Target": "triangle",
  "Index": "triangle",
  "VehicleFields": "square",
}
const placementTypeMapping:{[key in SourceTypes["__typename"]]: number} = {
  "ClientPortfolio": 0.2,
  "VehicleFields": 0.2,
  "Target": -0.2,
  "Index": -0.2,
}

const placementMappingBycount = (count: number) => {
  let maxOffset = 0.25
  let minOffset = -0.25
  if(count > 3){
    maxOffset = 0.375
    minOffset = -0.375
  }
  var arr = []
  var step = (maxOffset - minOffset) / (count - 1)
  for (var i = 0; i < count; i++) {
    arr.push(minOffset + (step * i))
  }
  return arr
}

interface ConvertSourceProps {
  source: SourceTypes
  graphqlFetch:"graphqlVariableTarget" | "graphqlVariableNet" | "graphqlVariableGross"
  name:string
  extraLabel?: "Gross" | "Net"
}

interface PerformanceComparisonDetailProps {
  name: string
  group: ReportPerformanceComparisonTrailingGroupFragment | ReportPerformanceComparisonYearsGroupFragment
  sources: SourceTypes[]
  portfolio?: ReportPerformanceComparisonTrailingClientPortfolioFragment | ReportPerformanceComparisonYearsClientPortfolioFragment
  settings: ReportPerformanceComparisonFetchFragment
  asOfDate: string
  selected?: boolean
  handleSelect?: (e: React.MouseEvent) => void
  // tab: ReportTab
  // tabs: ReportTab[]
  // setTab: (value:ReportTab) => void
  editMode: boolean
  sectionNumber?: number
  componentNumber?: number
  report?: ReportsFragment
  setEditedDraftLayout?: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout?: ClientPortfolioDetailLayoutFragment
  updateSettings?: (value:ReportPerformanceComparisonFetchFragment) => void
  portfolioId?: number
  setSelectedComponentId?: (value:selectedComponentProp) => void
  view: ReportDisplayType
  componentId: number
  dcPortfolio?: ReportPerformanceComparisonDcPortfolioFragment
  auth: Auth
  setReportState?: (value: componentState) => void
  reportState?: componentState
  fiscalMonths: number
  clientId?: number
  usedDate: string
}

export const PerformanceComparisonDetail: React.FC<PerformanceComparisonDetailProps> = ({
  name,
  group,
  sources,
  portfolio,
  settings,
  asOfDate,
  selected,
  handleSelect,
  // tab,
  // tabs,
  // setTab,
  editMode,
  sectionNumber,
  report,
  setEditedDraftLayout,
  editedDraftLayout,
  updateSettings,
  componentNumber,
  portfolioId,
  setSelectedComponentId,
  view,
  componentId,
  dcPortfolio,
  auth,
  setReportState,
  reportState,
  fiscalMonths,
  clientId,
  usedDate,
}) => {
  const [hoveredData, setHoveredData] = useState<HoveredData | null>(null)
  const [optionModalOpen, setOptionModalOpen] = useState<boolean>(false)
  // const hoveredData = reportState?.hoveredData || null
  // const setHoveredData = (value:any) => {
  //   setReportState({hoveredData: value})
  // }
  // useEffect(() => {
  //   setHoveredData(null)
  // }, [])
  let useNonDefaultRankingMapping = {
    "ClientPortfolio": false,
    "Group": false,
    "VehicleFields": false,
    "Target": false,
    "Index": false,
  }
  const groupPerformanceType = !!settings?.group ? (settings?.groupPerformanceType?.code || settings.clientPortfolio?.groupPerformanceType?.code) : settings.clientPortfolio?.groupPerformanceType?.code
  if(["GROSS", "INSNET", "EQNET", "RETNET"].includes(groupPerformanceType || "")){
    useNonDefaultRankingMapping["ClientPortfolio"] = true
    useNonDefaultRankingMapping["Group"] = true
    useNonDefaultRankingMapping["Target"] = true
    useNonDefaultRankingMapping["Index"] = true
  }
  if((settings.vehiclePerformanceType?.code || "") !== VehiclePerformanceTypeCode.DEF){
    useNonDefaultRankingMapping["VehicleFields"] = true
  }
  const showSupporting = true //TODO workout code
  const portfolioNetResults = settings.portfolioPerformanceType === ManagerPerformanceType.Net || (settings.portfolioPerformanceType === ManagerPerformanceType.Default && ((settings.clientPortfolio?.performanceType?.code && [ClientPerformanceTypeCode.BOTH, ClientPerformanceTypeCode.NET].includes(settings.clientPortfolio?.performanceType?.code)) || !settings.clientPortfolio))
  const portfolioGrossResults = settings.portfolioPerformanceType === ManagerPerformanceType.Gross || (settings.portfolioPerformanceType === ManagerPerformanceType.Default && settings.clientPortfolio?.performanceType?.code && [ClientPerformanceTypeCode.BOTH, ClientPerformanceTypeCode.GROSS].includes(settings.clientPortfolio?.performanceType?.code))

  const [tableData, graphData] = useMemo(() =>{
    let tableData:GraphData[] = []
    let graphData:GraphData[] = []
    let headings = headingOrder(settings, fiscalMonths, usedDate)
    let groupGraphData:GraphData[] = [
      {
        name: "10th Percentile",
        type: "columnrange",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "25th Percentile",
        type: "columnrange",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "75th Percentile",
        type: "columnrange",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "90th Percentile",
        type: "columnrange",
        color: "#E1E1E1",
        data: [],
      },
    ]
    let groupTableData:GraphData[] = [
      {
        name: "10th Percentile",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "25th Percentile",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "Median",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "75th Percentile",
        color: "#E1E1E1",
        data: [],
      },
      {
        name: "90th Percentile",
        color: "#E1E1E1",
        data: [],
      },
    ]
    headings.forEach((heading) => {
      // GroupData
      let headingData = get(group, heading.graphqlVariableTarget)
      let rankingData
      const latestInstance = maxBy(headingData, (i: ReportPerformanceComparisonPerformanceFragment) => (moment(i.endDate, DATE_API_FORMAT).valueOf()))
      let ranking = (useNonDefaultRankingMapping["Group"] ? latestInstance?.ranking : latestInstance?.defaultRanking) as ReportPerformanceComparisonRankingFragment
      let graphUsed = false
      let tableUsed = false
      if(ranking?.__typename === "GroupRanking") rankingData = ranking?.percentiles
      if(!rankingData){
        ranking = (useNonDefaultRankingMapping["Group"] ? latestInstance?.defaultRanking : latestInstance?.ranking) as ReportPerformanceComparisonRankingFragment
        if(ranking?.__typename === "GroupRanking") rankingData = ranking?.percentiles
      }
      if(rankingData){
        const percentile10 = rankingData.find((p) => p.percentile === 10)?.value
        const percentile25 = rankingData.find((p) => p.percentile === 25)?.value
        const percentile50 = rankingData.find((p) => p.percentile === 50)?.value
        const percentile75 = rankingData.find((p) => p.percentile === 75)?.value
        const percentile90 = rankingData.find((p) => p.percentile === 90)?.value
        if(!isNil(percentile10) && !isNil(percentile25) && !isNil(percentile50) && !isNil(percentile75) && !isNil(percentile90)){
          graphUsed = true
          groupGraphData[0].data.push({low: percentile10, high: percentile25})
          groupGraphData[1].data.push({low: percentile25, high: percentile50})
          groupGraphData[2].data.push({low: percentile50, high: percentile75})
          groupGraphData[3].data.push({low: percentile75, high: percentile90})
        }
        tableUsed = true
        groupTableData[0].data.push({y: percentile10 || undefined})
        groupTableData[1].data.push({y: percentile25 || undefined})
        groupTableData[2].data.push({y: percentile50 || undefined})
        groupTableData[3].data.push({y: percentile75 || undefined})
        groupTableData[4].data.push({y: percentile90 || undefined})
      }
      if(!graphUsed){
        groupGraphData[0].data.push({low: undefined, high: undefined})
        groupGraphData[1].data.push({low: undefined, high: undefined})
        groupGraphData[2].data.push({low: undefined, high: undefined})
        groupGraphData[3].data.push({low: undefined, high: undefined})
      }
      if(!tableUsed){
        groupTableData[0].data.push(undefined)
        groupTableData[1].data.push(undefined)
        groupTableData[2].data.push(undefined)
        groupTableData[3].data.push(undefined)
        groupTableData[4].data.push(undefined)
      }
    })
    graphData = graphData.concat(groupGraphData)
    if(settings.showPerformanceTable){
      if(settings.showMedianColumn){
        tableData = tableData.concat(groupTableData.slice(2, 3))
      } else {
        tableData = tableData.concat(groupTableData)
      }
    }

    let unifiedSources:ConvertSourceProps[] = sources.reduce((result:ConvertSourceProps[], source) => {
      if(source.__typename === "ClientPortfolio"){
        if(portfolioGrossResults) result.push({source, graphqlFetch: "graphqlVariableGross", name: (source?.name || ""), extraLabel: portfolioNetResults ? "Gross" : undefined})
        if(portfolioNetResults) result.push({source, graphqlFetch: "graphqlVariableNet", name: (source?.name || ""), extraLabel: portfolioGrossResults ? "Net" : undefined})
      } else if (source.__typename === "VehicleFields") {
        result.push({source, graphqlFetch: "graphqlVariableTarget", name: (source?.graphNameComputed || source?.name || "")})
      } else {
        result.push({source, graphqlFetch: "graphqlVariableTarget", name: (source?.name || "")})
      }
      return result
    }, [])
    let orderList = placementMappingBycount(unifiedSources.length)

    // SourceData
    let colorCounter = 0
    unifiedSources.forEach((props:ConvertSourceProps) => {
      let {source, graphqlFetch, extraLabel, name} = props
      const sourceType = source.__typename
      let sourceData:GraphData = {
        name: name,
        data: [],
        type: "scatter",
        sourceType: sourceType,
        // pointPlacement: placementTypeMapping[sourceType] > 0 ? orderList.pop() : orderList.shift(),
        marker: {
          symbol: markerTypeMapping[sourceType],
          radius: 6,
        },
        color: highContrastColors[colorCounter].hex,
        footnote: "footnote" in source ? source.footnote : undefined,
        dataLabels: {
          align: "left",
        },
        links: getReportLinks({
          component: source as LinkableReportFragments,
          reportId: report?.id,
          clientId,
          portfolioId,
          view
        }),
        extraLabel: extraLabel,
        source: source,
      }
      colorCounter += 1
      headings.forEach((heading, idx) => {
        const graphqlVariable = get(heading, graphqlFetch)
        let headingData = get(source, graphqlVariable)
        let latestInstance = maxBy(headingData, (i: ReportPerformanceComparisonPerformanceFragment) => (moment(i.endDate, DATE_API_FORMAT).valueOf()))
        let ranking = (useNonDefaultRankingMapping[sourceType] ? latestInstance?.ranking : latestInstance?.defaultRanking) as ReportPerformanceComparisonRankingFragment
        const value = latestInstance?.value?.active
        if(value && latestInstance){
          sourceData.data.push({y: value, rank: "rank" in ranking && ranking.rank ? ranking.rank : undefined})
        } else {
          sourceData.data.push({y: undefined})
        }
      })
      graphData.push(sourceData)
      tableData.push(cloneDeep(sourceData))
    })
    return [tableData,  graphData]
  }, [settings, group, sources]);

  let tooltipProps:ToolbarProps = {}

  // TODO update export
  if(componentNumber === -1){
    tooltipProps = {'exportOptions': {
      name: name,
      slides: [{
        title: name,
        sections: [{
          components: [componentId],
          type: LayoutSectionType.SingleColumnSection
        }]
      }],
      settings: {
        live: view === ReportDisplayType.Live,
        footerName: report?.exportSettings?.footerName || report?.client?.name,
        componentOverrideSettings: {
          // PerformanceComparison: [
          //   {
          //     componentId: componentId,
          //     selectedDate: tab.value,
          //     drillDownGroup: parseInt(selectedDrillDown || "") || undefined
          //   }
          // ]
        }
      }
    },
    'moveButtons': true,
    }
  }

  tooltipProps['openOptionsModal'] = (view !== ReportDisplayType.External || auth.checkPermissions(["edit:product_analysis"])) ? () => setOptionModalOpen(true) : undefined

  return (
    <TemplateComponent
      name={name}
      componentTypeName='Performance Comparisons'
      rightText={`As of ${asOfDate}`}
      selected={selected}
      onClick={handleSelect}
      tooltipProps={tooltipProps}
      // tabs={settings?.monthlyOptions?.show ? tabs : undefined}
      // currentTab={tab}
      // onTabChange={(tab) => setTab(tab)}
      editMode={editMode}
      sectionNumber={sectionNumber}
      report={report}
      setEditedDraftLayout={setEditedDraftLayout}
      editedDraftLayout={editedDraftLayout}
      componentNumber={componentNumber}
      setSelectedComponentId={setSelectedComponentId}
      view={view}
      auth={auth}
    >
      <PerformanceComparisonOptionsModal
        settings={settings}
        optionModalOpen={optionModalOpen}
        setOptionModalOpen={setOptionModalOpen}
        report={report}
        updateSettings={updateSettings}
        componentId={componentId}
        view={view}
      />
      <Row className="no-gutters">
        {showSupporting &&
          <Col sm="12">
            <PerformanceComparisonSupporting
                settings={settings}
                portfolio={portfolio}
                dcPortfolio={dcPortfolio}
              />
          </Col>
        }
        {/* <Col sm="12" className="overflow-auto">
          <PerformanceComparisonGraph
            graphData={graphData}
            setHoveredData={setHoveredData}
            hoveredData={hoveredData}
            settings={settings}
          />
        </Col> */}
        <Col sm="12" className="overflow-auto">
          <div className="form-section-title headline underline small-font-size mb-0 pb-0">Performance vs {group.veryLongName}</div>
          <PerformanceComparisonTable
            graphData={graphData}
            settings={settings}
            tableData={tableData}
            hoveredData={hoveredData}
            setHoveredData={setHoveredData}
            date={usedDate}
            editMode={editMode}
            fiscalMonths={fiscalMonths}
            usedDate={usedDate}
          />
        </Col>
      </Row>
    </TemplateComponent>
  )
}

interface PerformanceComparisonSupportingProps {
  settings: ReportPerformanceComparisonFetchFragment
  portfolio?: ReportPerformanceComparisonTrailingClientPortfolioFragment | ReportPerformanceComparisonYearsClientPortfolioFragment
  dcPortfolio?: ReportPerformanceComparisonDcPortfolioFragment
}

const PerformanceComparisonSupporting: React.FC<PerformanceComparisonSupportingProps> = ({settings, portfolio, dcPortfolio}) => {
  const factWriteUps = groupBy(portfolio?.relatedVehicle?.vehicle?.product?.product?.writeUps?.facts, "researchCategory.code")
  const philosophy = maxBy(factWriteUps["POVER"] as Fact[], (f:Fact) => moment(f.lastUpdated).valueOf())
  const firstParagraph = (philosophy?.text || "").split("\n")[0]

  const showPhilosophy = settings.showInvestmentPhilosophy && !!philosophy
  const showDcInfo = settings.showDCInfo && !!dcPortfolio
  const showAssetGrowth = settings.showAssetGrowth && !!portfolio

  const beginningMarketValue = get(portfolio, "priorAssets[0].totalMarketValue")
  const netNewInvestment = get(portfolio, "cashFlowActivity[0].netFlow")
  const endingMarketValue = get(portfolio, "assets[0].totalMarketValue")
  const gains_losses = endingMarketValue - beginningMarketValue - netNewInvestment

  const dcAssets = get(dcPortfolio, "assets[0].totalMarketValue")
  const dcParticipants = get(dcPortfolio, "numberOfParticipants[0].count")
  const dcRevenueShare = get(dcPortfolio, "datedFees[0].revenueShare")
  const dcFee = get(dcPortfolio, "relatedVehicle.stableValueCollectiveInvestmentFund.fees[0].allInFee") || get(dcPortfolio, "relatedVehicle.stableValueSeparateAccount.feeAtAum.fee") || get(dcPortfolio, "datedFees[0].totalExpense")
  const dcTicker = get(dcPortfolio, "relatedVehicle.vehicle.identifiers.ticker") || get(dcPortfolio, "relatedVehicle.vehicle.category.shortValue")

  const colCount = (showPhilosophy ? 1 : 0) + (showDcInfo ? 1 : 0) + (showAssetGrowth ? 1 : 0)
  const colSize = 12 / colCount

  return (
    <Row noGutters>
      {showPhilosophy &&
        <Col sm={colSize} className='mb-2'>
          <div className="form-section-title headline underline smaller-font-size mb-2 pb-0">Investment Philosophy</div>
          <p className="pl-2" dangerouslySetInnerHTML={{__html: firstParagraph}}></p>
        </Col>
      }
      {showDcInfo &&
        <Col sm={colSize} className={classNames("mb-3",{"pl-2": showPhilosophy})}>
          <div className="form-section-title headline underline smaller-font-size mb-2 pb-0">Plan Information</div>
          <Row noGutters className='border-bottom border-gray-20 pl-2 mb-1 pb-1'>
            <Col sm={4}>
              <strong>Assets</strong>
              <br />
              <span className={classNames({"text-danger": (!!dcAssets && dcAssets < 0)})}>
                {dcAssets ? numbro(dcAssets).format(currencyFormat) : '-'}
              </span>
            </Col>
            <Col sm={4}>
              <strong>% of Total Assets</strong>
              <br />
              {(dcAssets && endingMarketValue) ? numbro(endingMarketValue/dcAssets).format(percentFormat) : '-'}
            </Col>
            <Col sm={4}>
              <strong># of Participants</strong>
              <br />
              {dcParticipants || '-'}
            </Col>
          </Row>
          <Row noGutters className='pl-2'>
            <Col sm={4}>
              <strong>Fee</strong>
              <br />
              {dcFee ? numbro(dcFee).format(currencyFormat) : '-'}
            </Col>
            <Col sm={4}>
              <strong>Revenue Share</strong>
              <br />
              {dcRevenueShare ? numbro(dcRevenueShare).format(currencyFormat) : '-'}
            </Col>
            <Col sm={4}>
              <strong>Vehicle/Ticker</strong>
              <br />
              {dcTicker || "-"}
            </Col>
          </Row>
        </Col>
      }
      {showAssetGrowth &&
        <Col sm={Math.min(colSize, 6)} className={classNames("mb-3",{"pl-2": showPhilosophy || showDcInfo})}>
          <div className="form-section-title headline underline smaller-font-size mb-2 pb-0">Quarterly Asset Growth</div>
          <div className='pl-2'>
            <div className="d-flex justify-content-between">
              <div>Beginning Assets</div>
              <div className={classNames("text-right", {"text-danger": (!!beginningMarketValue && beginningMarketValue < 0)})}>{numbro(beginningMarketValue).format(currencyFormat)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Net Cash Activity</div>
              <div className={classNames("text-right", {"text-danger": (!!netNewInvestment && netNewInvestment < 0)})}>{numbro(netNewInvestment).format(currencyFormat)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Investment Gains/(Losses)</div>
              <div className={classNames("text-right", {"text-danger": (!!gains_losses && gains_losses < 0)})}>{numbro(gains_losses).format(currencyFormat)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Ending Assets</div>
              <div className={classNames("text-right", {"text-danger": (!!endingMarketValue && endingMarketValue < 0)})}>{numbro(endingMarketValue).format(currencyFormat)}</div>
            </div>
          </div>
        </Col>
      }
    </Row>
  )
}

interface PerformanceComparisonGraphProps {
  graphData: GraphData[]
  hoveredData: HoveredData | null
  setHoveredData: React.Dispatch<React.SetStateAction<HoveredData | null>>
  settings: ReportPerformanceComparisonFetchFragment
  editMode?: boolean
  fiscalMonths: number
  usedDate: string
}

type PointData = Highcharts.Point & {
  graphic: {
    symbolName: string
  }
}

type TooltipFormatterProps = Highcharts.TooltipFormatterContextObject & {
  point: PointData
}

const getSymbol = (symbolName:string) => {
  let symbol = ""
  switch (symbolName) {
    case "circle":
      symbol = "●"
      break
    case "diamond":
      symbol = "♦"
      break
    case "square":
      symbol = "■"
      break
    case "triangle":
      symbol = "▲"
      break
    case "triangle-down":
      symbol = "▼"
      break
  }
  return symbol
}

const PerformanceComparisonGraph: React.FC<PerformanceComparisonGraphProps> = ({
  graphData,
  setHoveredData,
  hoveredData,
  settings,
  editMode,
  fiscalMonths,
  usedDate,
}) => {
  const graphRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement>; }>(null)
  // if(graphRef.current) console.log(graphRef.current.chart, graphRef.current.container)

  useEffect(() => {
    if (graphRef.current) {
      const chart = graphRef.current.chart
      if(!hoveredData){
        chart.series.forEach((subSeries) => {
          if(subSeries.userOptions.type === "columnrange")
            subSeries.data.forEach((item) => {
              if(item.color != "#E1E1E1"){
                item.update({
                  color: '#E1E1E1',
                }, true, false)
              }
            })
        })
      } else {
        chart.series.forEach((subSeries) => {
          // subSeries.setState('hover')
          if(subSeries.userOptions.type === "columnrange")
            subSeries.data.forEach((item) => {
              if(item.category === hoveredData?.category){
                // console.log(subSeries, item, hoveredData)
                if(hoveredData?.name === subSeries.name){
                  item.update({
                    color: '#055f8e',
                  })
                } else {
                  item.update({
                    color: '#e4eff5',
                  })
                }
              } else {
                // item.update({
                //   color: '#F5F5F5',
                // })
              }
            })
        })
      }
    }
    // if(hoveredData){
    //   set(graphData, [0, "data", 0, "color"], "#055f8e")
    // }
  }, [hoveredData])

  useEffect(() => {
    if (graphRef.current) {
      const chart = graphRef.current.chart
      // Need to do it three to get it to fill the component
      chart.reflow()
      chart.reflow()
      chart.reflow()
    }
  }, [editMode])

  const graph = useMemo(() => {
    return (
      <HighchartsReact
        ref={graphRef}
        options={{
          chart: {
            // styledMode: true,
            zoomType: "x",
            type: "columnrange",
            plotBorderWidth: 1,
            margin: [0,1,0,85]
          },
          title: {
            text: "",
          },
          legend: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          xAxis: {
            categories: headingOrder(settings, fiscalMonths, usedDate).map((heading) => heading.displayText),
            gridLineWidth: 1,
            labels: {
              enabled: false,
            }
          },
          yAxis: {
            tickWidth: 1,
            tickLength: 5,
            gridLineWidth: 0,
            lineWidth: 1,
            plotLines: [
              {
                width: 1,
                value: 0,
              },
            ],
            title: {
              text: "Returns",
              style: {
                fontSize: "16px",
              }
            },
            showFirstLabel: false,
            showLastLabel: false,
            labels: {
              formatter(this: Highcharts.AxisLabelsFormatterContextObject<number>) {
                return `<span class="${this?.value && this.value < 0 && "negative-value"}">${numbro(this?.value).format(rankFormat)}%</span>`
              },
              style: {
                fontSize: "14px",
              }
            },
          },
          tooltip: {
            formatter(this: TooltipFormatterProps) {
              const symbol = getSymbol(this?.point.graphic.symbolName)
              const value = this?.point.high
                ? `From ${(this?.point.high).toFixed(2)}%
                To ${((this?.point?.low || 0)).toFixed(2)}%`
                : (this?.y).toFixed(2) + "%"
              return `<span style="color:${this?.point.color}">${symbol}</span> ${this?.point.series.name}: <b>${value}</b><br/>`
            },
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver(this: Highcharts.Point) {
                    setHoveredData({
                      category: this?.category,
                      name: this?.series?.name,
                    })
                  },
                  mouseOut(this: Highcharts.Point) {
                    setHoveredData(null)
                  },
                },
              },
              // states: {
              //   hover: {
              //     enabled: false,
              //   }
              // },
            },
            columnrange: {
              grouping: false,
              borderWidth: 1,
              borderColor: "#A7A7A7",
              states: {
                hover: {
                  enabled: false,
                },
                inactive: {
                  enabled: false,
                }
              },
              pointRange: 1,
            },
            scatter: {
              dataLabels: {
                enabled: true,
                y: 0,
                x: 20,
                // position: "right",
                formatter(this: Highcharts.PointLabelObject) {
                  let rank = numbro((this.point?.options as ScatterDataPoint | undefined)?.rank).format(rankFormat)
                  if (rank && rank !== "NaN")
                    return `<span style="color:${this?.point.color}">(${rank})</span>`
                  else return null
                },
                allowOverlap: true,
                verticalAlign: 'middle',
                style: {
                  fontSize: "13px",
                }
              },
              pointRange: 1,
              // states: {
              //   hover: {
              //     enabled: false,
              //   }
              // },
            },
            // states: {
            //   hover: {
            //     enabled: false,
            //   }
            // },
          },
          series: graphData,
        }}
        highcharts={Highcharts}
        containerProps={{ style: { height: `400px` } }}
      />
    )
  }, [graphData])

  return graph
}

interface PerformanceComparisonTableProps {
  graphData: GraphData[]
  settings: ReportPerformanceComparisonFetchFragment
  tableData: GraphData[]
  date: string
  hoveredData: HoveredData | null
  setHoveredData: React.Dispatch<React.SetStateAction<HoveredData | null>>
  editMode?: boolean
  fiscalMonths: number
  usedDate: string
}

const PerformanceComparisonTable: React.FC<PerformanceComparisonTableProps> =({graphData, tableData, settings, date, hoveredData, setHoveredData, editMode, fiscalMonths, usedDate}) => {
  //TODO fix values
  const headings = headingOrder(settings, fiscalMonths, date)
  let scatterData = false
  const tooltipId = useMemo(() => `value-tooltip-${uniqueId()}`, [])
  return (
    <Table className={classNames("report-table equal-width performance-comparison-table")}>
      <thead>
        <tr>
          <td className="text-left" colSpan={2}></td>
          <td colSpan={headings.length} className='p-0'>
            <div style={{marginLeft: -80}}>
              <PerformanceComparisonGraph
                graphData={graphData}
                setHoveredData={setHoveredData}
                hoveredData={hoveredData}
                settings={settings}
                editMode={editMode}
                fiscalMonths={fiscalMonths}
                usedDate={usedDate}
              />
            </div>
          </td>
        </tr>
        <tr className="">
          <td className="text-left" colSpan={2}>
          </td>
          {headings.map((heading) => {
            return (
              <td className="text-center" key={heading.graphqlVariableTarget}>
                <strong>
                  {heading.displayText}
                </strong>
              </td>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {tableData.map((graphObject: GraphData, idx: number) => {
          // const portfolio = undefined
          const symbol = getSymbol(graphObject?.marker?.symbol || "")
          const isTarget = graphObject.sourceType === "Target" || graphObject.sourceType === "Index"
          let greenBorder = false
          if(graphObject.type === "scatter" && !scatterData){
            greenBorder = true
            scatterData = true
          }
          return(
            <React.Fragment key={idx}>
              <tr className={classNames("report-tr-title", {"row-border-olive-100": greenBorder})}>
                <td className="pl-0 text-right" colSpan={2}>
                  <div className={""}>
                    <FootnotableComponent
                      footnote={graphObject?.footnote}
                      showFootnote={isTarget ? settings.showTargetFootnote : settings.showFootnote}
                      selectedDate={date}
                      links={graphObject?.links}
                    >
                      {graphObject.name}
                      {graphObject.extraLabel &&
                        <span className={classNames("ml-2 badge text-uppercase text-text-color", "badge-pill", { "background-blue-30": graphObject.extraLabel === "Net", "background-olive-10": graphObject.extraLabel === "Gross" })}>
                          {graphObject.extraLabel}
                        </span>
                      }
                      {symbol &&
                        <span style={{color: graphObject.color, fontSize: graphObject?.marker?.symbol === "circle" ? 24 : 18}} className='pl-2'>
                          {symbol}
                        </span>
                      }
                    </FootnotableComponent>
                  </div>
                </td>
                {headings.map((heading, idx2) => {
                  const value = get(graphObject.data[idx2], "y")
                  let cellClass = ""
                  let inceptionDate = undefined
                  const onMouseOver = () => {
                    setHoveredData({
                      category: heading.displayText,
                      name: graphObject.name,
                    })
                  }
                  const onMouseOut = () => {
                    setHoveredData((hoveredData) => {
                     if(hoveredData?.name === graphObject.name && hoveredData?.category === heading.displayText) return null
                     return hoveredData
                    })
                  }
                  if(heading.graphqlVariableTarget === "sinceInception"){
                    if(graphObject.source?.__typename === "ClientPortfolio"){
                      cellClass += " hover-transition-underline"
                      inceptionDate = moment(graphObject.source?.serviceStartDate).add(1, "month").format(DATE_TOOLTIP_FORMAT)
                    } else if (graphObject.source?.__typename === "VehicleFields") {
                      cellClass += " hover-transition-underline"
                      inceptionDate = moment(graphObject.source?.inceptionDate).format(DATE_TOOLTIP_FORMAT)
                    }
                  }
                  const tooltipCellId = tooltipId+"-"+idx+"-"+heading.graphqlVariableTarget

                  return (
                    <td className={classNames("text-center", {"negative-value": (!!value && value < 0),"background-blue-30": hoveredData?.name === graphObject.name && hoveredData?.category === heading.displayText})} key={heading.graphqlVariableTarget} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                      {heading.graphqlVariableTarget === "sinceInception" && inceptionDate &&
                        <UncontrolledTooltip placement='top' target={tooltipCellId} delay={200} autohide={false}>
                          Since {inceptionDate}
                        </UncontrolledTooltip>
                      }
                      <div className={classNames("data-cell-inner position-relative",cellClass)} id={tooltipCellId}>
                        {value ? numbro(value).format(rankingFormat) : "-"}
                      </div>
                    </td>
                  )
                })}
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  )
}

// const PerformanceComparisonActualPerformanceFields:ReportEditField[] = [
//   {
//     property: "actualPerformance",
//     label: "Actual Performance Only",
//     type: "radio",
//     subtype: "boolean",
//     required: true,
//   },
// ]

const PerformanceComparisonPortfolioPerformanceFields:ReportEditField[] = [
  {
    property: "portfolioPerformanceType",
    label: "Portfolio Performance",
    type: "select",
    optionSource: "ManagerPerformanceType",
    required: true,
  },
]

const PerformanceComparisonPerformanceFields:ReportEditField[] = [
  {
    property: "groupPerformanceType.code",
    label: "Group Performance",
    type: "select",
    optionSource: "GroupPerformanceTypeCode",
    required: true,
  },
]

const PerformanceComparisonVehiclePerformanceFields:ReportEditField[] = [
  {
    property: "vehiclePerformanceType.code",
    label: "Vehicle Performance",
    type: "select",
    optionSource: "VehiclePerformanceTypeCode",
    required: true,
  },
]

const PerformanceComparisonPercentileTableFields:ReportEditField[] = [
  {
    property: "showPerformanceTable",
    label: "Percentile Table",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const PerformanceComparisonPercentileMedianFields:ReportEditField[] = [
  {
    property: "showMedianColumn",
    label: "Percentiles",
    type: "select",
    options: GetLookupDataToOptions({
      data: [{
        code: "true",
        value: "Median Only",
      },{
        code: "false",
        value: "All Percentiles",
      }],
      hideBlank: true
    }) || [],
  },
]

const PerformanceComparisonReportingPeriodFields:ReportEditField[] = [
  {
    property: "reportingPerformancePeriod",
    label: "",
    type: "radio",
    options: [{
      code: "Trailing",
      value: "Trailing",
    },{
      code: "CalendarYears",
      value: "Calendar Year",
    },{
      code: "FiscalYears",
      value: "Fiscal Year",
    }],
    required: true,
    subClasses: {
      inputWrapperClasses: "col-sm-12 radio-input-with-divider mt-1 mb-2 w-100",
    },
  },
]

const PerformanceComparisonSupportingDetailsFields = (portfolios:PlanClientPortfolioFragment[] | undefined):ReportEditField[] => {

  return [
    {
      property: "showInvestmentPhilosophy",
      label: "Investment Philosophy",
      type: "checkbox",
      subtype: "show",
    },
    {
      property: "showAssetGrowth",
      label: "Quarterly Asset Growth (DB only)",
      type: "checkbox",
      subtype: "show",
    },
    {
      property: "showDCInfo",
      label: "DC Fund Info",
      type: "checkbox",
      subtype: "show",
    },
    {
      property: "DCTotalFundPortfolio.id",
      label: "Total Fund (DC only)",
      type: "select",
      options: portfolios?.map((p) => ({
        code: p.id.toString() || "",
        value: p.name || "",
      })) || [],
    },
  ]
}

const PerformanceComparisonTableFields:ReportEditField[] = [
  {
    property: "performanceComparisonPeriod.lastQuarter",
    label: "1 Quarter",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.yearToDate",
    label: "Year to date (YTD)",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.fiscalYear",
    label: "Fiscal YTD",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last1Year",
    label: "1 Year",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last2Years",
    label: "2 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last3Years",
    label: "3 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last5Years",
    label: "5 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last7Years",
    label: "7 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last10Years",
    label: "10 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last15Years",
    label: "15 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last20Years",
    label: "20 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.sinceInception",
    label: "Since Inception",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const PerformanceComparisonYearsFields:ReportEditField[] = [
  {
    property: "numberOfYearsToInclude",
    label: "Years to include",
    type: "select",
    subtype: "single",
    options: [{
      code: "1",
      value: " 1",
    },{
      code: "2",
      value: " 2",
    },{
      code: "3",
      value: " 3",
    },{
      code: "4",
      value: " 4",
    },{
      code: "5",
      value: " 5",
    },{
      code: "6",
      value: " 6",
    },{
      code: "7",
      value: " 7",
    },{
      code: "8",
      value: " 8",
    },{
      code: "9",
      value: " 9",
    },{
      code: "10",
      value: "10",
    }],
    required: true,
  },
]

const FiscalMonthEndFields:ReportEditField[] = [
  {
    property: "plan.fiscalMonth",
    label: "Fiscal Month End",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "performanceComparisonFiscalMonthEndTooltip",
    },
  },
]

const PerformanceComparisonFootnoteFields:ReportEditField[] = [
  {
    property: "showFootnote",
    label: "Portfolio",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "showTargetFootnote",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const getColumnsByPeriod = (settings: ReportPerformanceComparisonFetchFragment) => {
  let result = PerformanceComparisonTableFields
  switch (settings.reportingPerformancePeriod) {
    case ReportingPerformancePeriod.CalendarYears:
      result = PerformanceComparisonYearsFields
      break
    case ReportingPerformancePeriod.FiscalYears:
      result = [...PerformanceComparisonYearsFields, ...FiscalMonthEndFields]
      break
    default: // ReportingPerformancePeriod.Trailing
      break
  }
  return result
}

export const PerformanceComparisonEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, report}) => {
  const settings = component.draftSettings as ReportPerformanceComparisonFetchFragment
  // const { data: listData } = useListSimpleQuery({
  //   variables: {id: settings.list?.id || 0},
  //   skip: !settings || !settings.list?.id,
  //   errorPolicy: "all",
  // })
  if(!settings){
    return(
      <></>
    )
  }
  // console.log('settings', settings)
  const { data } = usePlanPortfoliosQuery({ variables: {id: settings.clientPortfolio?.plan?.id || 0, includeTargetsAndGroup: false}})
  const totalFunds = data?.plan?.clientPortfolios?.filter((p) => p?.dataType?.code === "TOT") || []
  let supportingColumns = [...PerformanceComparisonSupportingDetailsFields(totalFunds as PlanClientPortfolioFragment[])]
  const ownedComponent = component.owner?.id === user?.person?.id
  let infoColumns = [...ReportEditInfoFields(handleInputChange, {hideList: true})]
  let reportingPeriodColumns = [...PerformanceComparisonReportingPeriodFields, ...getColumnsByPeriod(settings)]
  const hasPortfolio = !!settings.clientPortfolio?.id
  const isFundProfile = report.category?.code === ReportCategoryCode.FPROF
  let performanceFields:ReportEditField[] = [] //PerformanceComparisonActualPerformanceFields
  if(hasPortfolio) performanceFields = performanceFields?.concat(PerformanceComparisonPortfolioPerformanceFields)
  let colorCounter = 0
  const getColor = () => {
    const color = highContrastColors[colorCounter].name
    colorCounter++
    if(colorCounter >= highContrastColors.length) colorCounter = 0
    return color
  }
  if(!!settings.group) performanceFields = performanceFields?.concat(PerformanceComparisonPerformanceFields)
  if((settings.vehicles?.length || 0) > 0) performanceFields = performanceFields?.concat(PerformanceComparisonVehiclePerformanceFields)
  performanceFields = performanceFields?.concat(PerformanceComparisonPercentileTableFields)
  if(settings.showPerformanceTable) performanceFields = performanceFields?.concat(PerformanceComparisonPercentileMedianFields)
  return (
    <>
      {/* <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Asset Allocation"}
        componentType={ComponentType.PerformanceComparison}
        reportId={reportId}
        handleInputChange={handleInputChange}
      /> */}
      <ReportEditTemplate className={classNames('text-white', {'background-blue-100': ownedComponent, 'background-gray-50': !ownedComponent })}>
        <div className="d-flex my-2">
          <div className="d-flex">
            <FontAwesomeIcon
              icon={IconMapping[ComponentType.ManagerPerformance] || 'table'}
              className="text-white unlock-icon-height mr-2 fa-2-5x"
            />
          </div>
          <div>
            <strong>Performance Comparison</strong> | ID {component.id}
            <br />
            Owned by {ownedComponent ? 'me' : `${component.owner?.firstName} ${component.owner?.lastName}`}
            <br />
            <small className={classNames('small-text', {'text-blue-50': ownedComponent, 'text-gray-30': !ownedComponent })}>
              Data from
              <span className='ml-1 cursor-pointer tooltip-icon hover-transition-underline' onClick={(e) => {e.stopPropagation(); window.open(`/clientportfolios/${portfolio.id}`,'_blank')}}>
                Portfolio ID {portfolio.id}
                <FontAwesomeIcon icon={"external-link"} className={classNames('ml-2', {'light-blue-color': ownedComponent, 'light-gray-color': !ownedComponent })}/>
              </span>
            </small>
          </div>
        </div>
      </ReportEditTemplate>
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      {/* <ReportEditInstances component={component}/> */}
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <ReportEditTemplate name={"Data Points"} retractable>
        {settings.clientPortfolio &&
          <div className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())} style={{fontSize: 18}}>{getSymbol("circle")}</span>{settings.clientPortfolio?.name}
              </div>
              {!isFundProfile &&
                <div className='cursor-pointer' onClick={() => handleInputChange(null, "clientPortfolio")}>
                  <FontAwesomeIcon
                    icon="trash"
                    className='show-on-container-hover'
                  />
                </div>
              }
            </div>
            {settings?.portfolioPerformanceType === ManagerPerformanceType.Default && settings.clientPortfolio.performanceType?.code === ClientPerformanceTypeCode.BOTH && getColor() && ""} {/* skip a color if gross and net would be shown so they line up correctly */}
            {settings.clientPortfolio?.performanceTargetMap?.map((ptm, idx) => {
              return (
                <div className="ml-3 mt-1" key={idx}>
                  <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")}</span>{ptm?.target?.name}
                </div>
              )
            })}
            {settings.clientPortfolio?.styleGroup &&
              <div className="d-flex justify-content-between align-items-center ml-3 mt-1">
                <div>
                  <span>
                    <FontAwesomeIcon
                      icon="bars"
                      className='text-olive-100 mr-2'
                    />
                  </span>{settings.clientPortfolio?.styleGroup?.shortName}
                </div>
                <div>
                  <FormInput
                    property={"category"}
                    displayName={""}
                    type={"checkbox"}
                    subtype={"show"}
                    idx={(settings.clientPortfolio?.performanceTargetMap?.length || 0) + 1}
                    editMode={false}
                    propertyVal={!settings.group?.id}
                    optionSource={"ReportCategoryCode"}
                    updateValue={(value) => null}
                    readonly={true}
                    subClasses={{wrapperClasses: "no-gutters"}}
                  />
                </div>
              </div>
            }
          </div>
        }
        {settings.vehicles?.map((vehicle, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("square")} </span>{vehicle?.vehicle?.name}
              </div>
              <div className='cursor-pointer' onClick={() => handleInputChange(settings.vehicles?.filter(item => item?.vehicle?.fundid !== vehicle?.vehicle?.fundid), "vehicles")}>
                <FontAwesomeIcon
                  icon="trash"
                  className='show-on-container-hover'
                />
              </div>
            </div>
            <div className='d-flex justify-content-between px-1'>
              <div className='ml-3'>
                <small>
                  {vehicle?.vehicle?.product?.product?.manager?.name}
                </small>
              </div>
              <div className='mr-3'>
                <small>
                  {vehicle?.vehicle?.fundid}
                </small>
              </div>
            </div>
          </div>
        ))}
        {settings.indices?.map((index, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")} </span>{index?.name}
            </div>
            <div className='cursor-pointer' onClick={() => handleInputChange(settings.indices?.filter(item => item?.id !== index?.id), "indices")}>
              <small className='mr-2'>
                {index?.id}
              </small>
              <FontAwesomeIcon
                icon="trash"
                className='show-on-container-hover'
              />
            </div>
          </div>
        ))}
        {settings.group &&
          <div className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className='text-olive-100 mr-2'>
                <FontAwesomeIcon
                  icon="bars"
                  className='text-olive-100'
                />
              </span>{settings.group?.shortName}
            </div>
            <div className='cursor-pointer' onClick={() => handleInputChange(null, "group")}>
              <small className='mr-2'>
                {settings.group?.id}
              </small>
              <FontAwesomeIcon
                icon="trash"
                className='show-on-container-hover'
              />
            </div>
          </div>
        }
        <ReportAddStatic
          settings={settings}
          handleInputChange={handleInputChange}
          isFundProfile={isFundProfile}
        />
      </ReportEditTemplate>
      {performanceFields.length > 0 &&
        <SimpleReportEdit
          name={"Performance"}
          retractable={true}
          fields={performanceFields}
          handleInputChange={handleInputChange}
          currentState={settings}
        />
      }
      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={(value, property) => {
          if(property === "numberOfYearsToInclude"){
            handleInputChange(parseInt(value), property)
          } else if (property === "reportingPerformancePeriod") {
            if(!get(settings, "numberOfYearsToInclude")) handleInputChange(5, "numberOfYearsToInclude")
            handleInputChange(value, property)
          } else {
            handleInputChange(value, property)
          }
        }}
        currentState={{...settings, plan: (report?.plans ? report?.plans[0] : null)} as any}
      />
      {hasPortfolio &&
        <SimpleReportEdit
          name={"Supporting Details"}
          retractable={true}
          fields={supportingColumns}
          handleInputChange={(value, property) => {
            if(property === "DCTotalFundPortfolio.id"){
              const matchingPortfolio = find(totalFunds, (p) => p?.id === parseInt(value))
              handleInputChange(matchingPortfolio, "DCTotalFundPortfolio")
            } else {
              handleInputChange(value, property)
            }
          }}
          currentState={settings}
        />
      }
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={PerformanceComparisonFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

interface ReportAddStaticProps {
  settings: ReportPerformanceComparisonFetchFragment
  handleInputChange: (value: any, property: string) => void
  isFundProfile: boolean
  externalView?: boolean
}

const ReportAddStatic: React.FC<ReportAddStaticProps> = ({ settings, handleInputChange, isFundProfile, externalView }) => {
  const [showSearch, setShowSearch] = useState(false)
  const handleAdd = (addItem:AutocompleteItemTypes, linkUrl: string) => {
    if(addItem.__typename === "ClientPortfolio"){
      handleInputChange(addItem, "clientPortfolio")
    } else if(addItem.__typename === "GroupAutocomplete"){
      handleInputChange({id: addItem.groupId, shortName: addItem.groupName, __typename: "Group"}, "group")
    } else if (addItem.__typename === "VehicleAutocomplete"){
      let currentVehicles = settings.vehicles || []
      handleInputChange([...currentVehicles, {vehicle: {id: addItem.vehicleId, fundid: addItem.vehicleId, name: addItem.vehicleName, product: { product: { manager: { name: addItem.managerName }}} , __typename: "VehicleFields"}}], "vehicles")
    } else if (addItem.__typename === "IndexAutocomplete"){
      let currentIndices = settings.indices || []
      handleInputChange([...currentIndices, {id: addItem.indexId, name: addItem.indexName, __typename: "Index"}], "indices")
    }
    setShowSearch(false)
  }

  let includedRows:string[] = []
  let filters:AutocompleteFilters = {}
  if(externalView){
    filters = {
      group: {
        classCode: ["s","m","l","5","c","h"]
      }
    }
  }

  if(settings.clientPortfolio) includedRows.push(`ClientPortfolio:${settings.clientPortfolio?.id}`)
  if(settings.group) includedRows.push(`Group:${settings.group?.id}`)

  includedRows = includedRows.concat(settings.indices?.map((index) => {
    return `Index:${index?.id}`
  }) || [])

  includedRows = includedRows.concat(settings.vehicles?.map((vehicle) => {
    return `Vehicle:${vehicle?.vehicle?.fundid}`
  }) || [])

  let searchedTypes = ["Portfolios", "Vehicles", "Indices", "Groups"]
  if(isFundProfile) searchedTypes = ["Vehicles", "Indices", "Groups"]

  if(showSearch){
    return <SearchAddStatic
      handleAdd={handleAdd}
      includedRows={includedRows}
      // auth={auth}
      searchedTypes={searchedTypes}
      dropdown
      forPerformanceComparison
      filters={filters}
    />
  }

  return (
    <div className='report-data-points-box mb-2 d-flex justify-content-center text-blue-120 p-2 font-weight-bold cursor-pointer' onClick={() => setShowSearch(true)}>
      <div>
        Add Data Point
        <FontAwesomeIcon
          className='text-blue-120 ml-2'
          icon="plus-circle"
        />
      </div>
    </div>
  )
}

interface PerformanceComparisonOptionsModalProps {
  settings: ReportPerformanceComparisonFetchFragment
  optionModalOpen: boolean
  setOptionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  report?: ReportsFragment
  updateSettings?: (settings: ReportPerformanceComparisonFetchFragment) => void
  componentId: number
  view: ReportDisplayType
}

const PerformanceComparisonOptionsModal = (props: PerformanceComparisonOptionsModalProps) => {
  const { settings, optionModalOpen, setOptionModalOpen, report, updateSettings, componentId, view } = props
  const [settingsCopy, setSettingsCopy] = useState<ReportPerformanceComparisonFetchFragment>(settings)
  let reportingPeriodColumns = [...(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing ? PerformanceComparisonTableFields : PerformanceComparisonYearsFields)]
  if(view === ReportDisplayType.External) reportingPeriodColumns = reportingPeriodColumns.filter((col) => !["performanceComparisonPeriod.fiscalYear","performanceComparisonPeriod.sinceInception"].includes(col.property))

  useEffect(() => {
    setSettingsCopy(settings)
  }, [optionModalOpen])

  const externalView = view === ReportDisplayType.External
  const isFundProfile = report?.category?.code === ReportCategoryCode.FPROF
  const stopPortfolioChange = isFundProfile || externalView
  let colorCounter = 0
  const getColor = () => {
    const color = highContrastColors[colorCounter].name
    colorCounter++
    if(colorCounter >= highContrastColors.length) colorCounter = 0
    return color
  }
  const handleInputChange = (value:any, property: string) => {
    setSettingsCopy((prevState) => {
      let newState = cloneDeep(prevState)
      set(newState, property, value)
      return newState
    })
  }
  const handleSubmit = () => {
    if(updateSettings) updateSettings(settingsCopy)
  }
  // if(settingsCopy.clientPortfolio){
  //   getColor() // Portfolio
  //   if(settingsCopy?.portfolioPerformanceType === ManagerPerformanceType.Default && settingsCopy.clientPortfolio.performanceType?.code === ClientPerformanceTypeCode.BOTH) getColor() // Portfolio Net
  //   settingsCopy.clientPortfolio?.performanceTargetMap?.forEach((ptm) => {
  //     getColor() // Portfolio Targets
  //   })
  // }
  return (
    <OptionsModal show={optionModalOpen} setShow={setOptionModalOpen} onSubmit={handleSubmit}>
      <ReportEditTemplate name={"Data Points"}>
        {settingsCopy.clientPortfolio &&
          <div className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())} style={{fontSize: 18}}>{getSymbol("circle")}</span>{settingsCopy.clientPortfolio?.name}
              </div>
              {!stopPortfolioChange &&
                <div className='cursor-pointer' onClick={() => handleInputChange(null, "clientPortfolio")}>
                  <FontAwesomeIcon
                    icon="trash"
                    className='show-on-container-hover'
                  />
                </div>
              }
            </div>
            {settingsCopy.portfolioPerformanceType === ManagerPerformanceType.Default && settingsCopy.clientPortfolio.performanceType?.code === ClientPerformanceTypeCode.BOTH && getColor() && ""} {/* skip a color if gross and net would be shown so they line up correctly */}
            {settingsCopy.clientPortfolio?.performanceTargetMap?.map((ptm, idx) => {
              return (
                <div className="ml-3 mt-1" key={idx}>
                  <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")}</span>{ptm?.target?.name}
                </div>
              )
            })}
            {settingsCopy.clientPortfolio?.styleGroup &&
              <div className="d-flex justify-content-between align-items-center ml-3 mt-1">
                <div>
                  <span>
                    <FontAwesomeIcon
                      icon="bars"
                      className='text-olive-100 mr-2'
                    />
                  </span>{settingsCopy.clientPortfolio?.styleGroup?.shortName}
                </div>
                <div>
                  <FormInput
                    property={"category"}
                    displayName={""}
                    type={"checkbox"}
                    subtype={"show"}
                    idx={(settingsCopy.clientPortfolio?.performanceTargetMap?.length || 0) + 1}
                    editMode={false}
                    propertyVal={!settingsCopy.group?.id}
                    optionSource={"ReportCategoryCode"}
                    updateValue={(value) => null}
                    readonly={true}
                    subClasses={{wrapperClasses: "no-gutters"}}
                  />
                </div>
              </div>
            }
          </div>
        }
        {settingsCopy.vehicles?.map((vehicle, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("square")} </span>{vehicle?.vehicle?.name}
              </div>
              {(!externalView || (settingsCopy.vehicles?.length || 0) > 1) &&
                <div className='cursor-pointer' onClick={() => handleInputChange(settingsCopy.vehicles?.filter(item => item?.vehicle?.fundid !== vehicle?.vehicle?.fundid), "vehicles")}>
                  <FontAwesomeIcon
                    icon="trash"
                    className='show-on-container-hover'
                  />
                </div>
              }
            </div>
            <div className='d-flex justify-content-between px-1'>
              <div className='ml-3'>
                <small>
                  {vehicle?.vehicle?.product?.product?.manager?.name}
                </small>
              </div>
              <div className='mr-3'>
                <small>
                  {vehicle?.vehicle?.fundid}
                </small>
              </div>
            </div>
          </div>
        ))}
        {settingsCopy.indices?.map((index, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")} </span>{index?.name}
            </div>
            <div className='cursor-pointer' onClick={() => handleInputChange(settingsCopy.indices?.filter(item => item?.id !== index?.id), "indices")}>
              <small className='mr-2'>
                {index?.id}
              </small>
              <FontAwesomeIcon
                icon="trash"
                className='show-on-container-hover'
              />
            </div>
          </div>
        ))}
        {settingsCopy.group &&
          <div className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className='text-olive-100 mr-2'>
                <FontAwesomeIcon
                  icon="bars"
                  className='text-olive-100'
                />
              </span>{settingsCopy.group?.shortName}
            </div>
            <div className={classNames({'cursor-pointer': !externalView})} onClick={() =>  externalView? null : handleInputChange(null, "group")}>
              <small className='mr-2'>
                {settingsCopy.group?.id}
              </small>
              {!externalView &&
                <FontAwesomeIcon
                  icon="trash"
                  className='show-on-container-hover'
                />
              }
            </div>
          </div>
        }
        <ReportAddStatic
          settings={settingsCopy}
          handleInputChange={handleInputChange}
          isFundProfile={stopPortfolioChange}
          externalView={view === ReportDisplayType.External}
        />
      </ReportEditTemplate>
      <SimpleReportEdit
        name={"Time Periods"}
        fields={reportingPeriodColumns}
        handleInputChange={(value, property) => {
          if(property === "numberOfYearsToInclude"){
            handleInputChange(parseInt(value), property)
          } else if (property === "reportingPerformancePeriod") {
            if(!get(settingsCopy, "numberOfYearsToInclude")) handleInputChange(5, "numberOfYearsToInclude")
            handleInputChange(value, property)
          } else {
            handleInputChange(value, property)
          }
        }}
        currentState={settingsCopy}
      />
    </OptionsModal>
  )
}

export const PerformanceComparisonExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  const { component } = props
  const reportState = props.reportState as componentState
  const tab = reportState?.tab
  // const selectedDrillDown = reportState?.selectedDrillDown

  // return {PerformanceComparison: [
  //   {
  //     componentId: component?.id,
  //     selectedDate: tab?.value,
  //     drillDownGroup: parseInt(selectedDrillDown || "") || undefined
  //   }
  // ]}
  return {}
}

export default PerformanceComparison