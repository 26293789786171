import { cloneDeep, compact, findIndex, get, isNil, pick, set, uniqBy, unset } from 'lodash'
import moment from 'moment'
import numbro from 'numbro'
import omitDeep from 'omit-deep-lodash'
import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { Alert, Col, Row, Table } from 'reactstrap'

import { DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS_PERFORMANCE } from '../../../queries/Portfolio'
import { AssetClassAbbreviationCode, CashFlowCashActivity, CashFlowDefaultKey, CashFlowNetAssetValue, CashFlows, CashFlowsInput, CashFlowTransaction, CashFlowTransactionInput, CashFlowTransactionType, ClientPortfolioPerformanceCashflowPerformanceQuery, ClientPortfolioPerformanceCashflowQuery, ClosedEndedPrivateNonRegisteredMulitAssetClass, ClosedEndedPrivateNonRegisteredPrivateCredit, ClosedEndedPrivateNonRegisteredPrivateEquity, ClosedEndedPrivateNonRegisteredRealAssets, DeleteCashFlowTransactionInput, UniqueTransactionTypeCode, UniqueTransactionTypeCodeMapItems } from '../../../__generated__/graphql'
import { FormInput } from '../../ui/Forms/FormInput'
import PlaceHolder from '../../ui/PlaceHolder'
import { CashActivityTable, PerformanceTable, TransactionTypeTable, ValuationsTable } from './VehiclesCashFlow'

type AllowedVehicleType = ClosedEndedPrivateNonRegisteredMulitAssetClass | ClosedEndedPrivateNonRegisteredPrivateCredit | ClosedEndedPrivateNonRegisteredPrivateEquity | ClosedEndedPrivateNonRegisteredRealAssets

interface ProductPerformanceClientsCashflowProps {
  id: number
  editMode: boolean
  date: string
  data: ClientPortfolioPerformanceCashflowQuery
  handleChange: (newDiff:CashFlowsInput) => void
  removeTransaction: (removedTransactionInput:DeleteCashFlowTransactionInput) => void
  editCancelled: boolean
  clearChanges: boolean
}

interface ProductPerformanceClientsState {
  stateDiff: CashFlowsInput
  removedInteractions: DeleteCashFlowTransactionInput[]
}

const TransactionTypeOrder = [
  { code: 'C', text: "Called Capital" },
  { code: 'CO', text: "Called Capital Outside Commitment" },
  { code: 'OR', text: "Return Called Capital Outside Commitment"}, // CAL-3171
  { code: 'IP', text: "Income" },
  { code: 'F', text: "Management Fee" },
  { code: 'RF', text: "Return of Management Fee" },
  { code: 'FO', text: "Management Fee Outside Commitment" },
  { code: 'RO', text: "Return Management Fee Outside Commitment" },
  { code: 'FE', text: "Fund Expense" },
  { code: 'RE', text: "Return of Fund Expense" },
  { code: 'CI', text: "Carried Interest" },
  { code: 'CL', text: "Clawback" },
  { code: 'D', text: "Distributed Earnings/ Gains" },
  { code: 'RC', text: "Return of Capital" },
  { code: 'DR', text: "Distributed Recallable Capital" },
  { code: 'DU', text: "Return of Uninvested Capital" },
  { code: 'IL', text: "Income-Loss" },
  { code: 'CD', text: "Adjustment of Distributed Capital" },
  { code: 'RD', text: "Adjust of Distributed Recallable Capital" },
]

class ProductPerformanceClientsCashflow extends Component<ProductPerformanceClientsCashflowProps,ProductPerformanceClientsState> {
  constructor(props: ProductPerformanceClientsCashflowProps) {
    super(props)

    this.state = {
      stateDiff: {},
      removedInteractions: []
    }
  }

  static getDerivedStateFromProps(nextProps:ProductPerformanceClientsCashflowProps, prevState:ProductPerformanceClientsState){
    if(nextProps.editCancelled || nextProps.clearChanges) {
      return { stateDiff: {} };
    }

    return null;
  }

  setStateDiff = (cashFlowInput:CashFlowsInput) => {
    this.setState({stateDiff: cashFlowInput}, () => {
      let newInput = cloneDeep(cashFlowInput)
      newInput.cashFlowTransactionTypes = newInput?.cashFlowTransactionTypes?.map(transactionType => {
        let newTransactionType = cloneDeep(transactionType)

        if (isNil(newTransactionType)) {
          return null
        }

        newTransactionType.transactions = newTransactionType?.transactions?.map(transaction => {
          let newTransaction = cloneDeep(transaction)
          if ((newTransaction?.transactionNumber && newTransaction?.transactionNumber <= 0) || isNil(newTransaction?.transactionNumber)) {
            unset(newTransaction, 'transactionNumber')
          }
          return newTransaction
        })
        return newTransactionType
      }) || []

      this.props.handleChange(newInput)
    })
  }

  handleActivityChange = (property:string, value: number) => {

    let cashFlowInput = cloneDeep(this.state.stateDiff)
    set(cashFlowInput, property, value)
    set(cashFlowInput, "cashFlowCashActivity.periodEndDate", this.props.date)
    // let cashFlowInput = omitDeep(updatedCashflow, '__typename')
    // set(cashFlowInput, 'beginningNetAssetValue.currency', updatedCashflow.beginningNetAssetValue?.currency?.code)
    // set(cashFlowInput, 'beginningNetAssetValue.country', updatedCashflow.beginningNetAssetValue?.country?.code)
    // set(cashFlowInput, 'endingNetAssetValue.currency', updatedCashflow.endingNetAssetValue?.currency?.code)
    // set(cashFlowInput, 'endingNetAssetValue.country', updatedCashflow.endingNetAssetValue?.country?.code)

    // cashFlowInput?.cashFlowTransactionTypes?.map((transactionType:CashFlowTransactionType, ind:number) => {
    //   set(cashFlowInput, `cashFlowTransactionTypes[${ind}].transactionType`, transactionType.transactionType.code)
    //   transactionType?.transactions?.map((transaction:Maybe<CashFlowTransaction>, ind2:number) => {
    //     set(cashFlowInput, `cashFlowTransactionTypes[${ind}].transactions[${ind2}].currency`, transaction?.currency.code)
    //   })
    // })

    // console.log({cashFlowInput})
    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }

  handleEndAssetChange = (endingNetAsset:CashFlowNetAssetValue) => {
    let cashFlowInput = cloneDeep(this.state.stateDiff)

    let endingNetAssetInput = omitDeep(endingNetAsset,'__typename')
    set(endingNetAssetInput, 'currency', endingNetAsset.currency.code)
    set(endingNetAssetInput, 'country', endingNetAsset.country.code)

    cashFlowInput.endingNetAssetValue = endingNetAssetInput
    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }

  handleTransactionChange = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    const portfolio = this.props.data.portfolio_cashflows
    const product = portfolio?.relatedVehicle?.vehicle?.product

    if (portfolio && product) {
      const { cashFlowDefaultKeys } = portfolio
      let cashFlowInput = cloneDeep(this.state.stateDiff)

      let newTransactionTypesInput = cloneDeep(cashFlowInput.cashFlowTransactionTypes)
      if (isNil(newTransactionTypesInput)) {
        newTransactionTypesInput = []
      }

      let updatedTransactionTypeInputIndex = newTransactionTypesInput?.findIndex(type => type?.transactionType === transactionTypeCode)

      let updatedTransactionTypeInput = cloneDeep(get(newTransactionTypesInput, updatedTransactionTypeInputIndex))
      if (updatedTransactionTypeInputIndex < 0 || isNil(updatedTransactionTypeInput)) {
        updatedTransactionTypeInput = {
          transactionType: transactionTypeCode,
          transactions: []
        }
      }

      if (isNil(updatedTransactionTypeInput.transactions)) {
        updatedTransactionTypeInput.transactions = []
      }

      let existingTransactionInputInd = updatedTransactionTypeInput?.transactions?.findIndex(transaction => {
        return (
          transaction?.transactionNumber === updatedTransaction.transactionNumber &&
          transaction?.periodEndDate === updatedTransaction.periodEndDate
        )
      })


      let updatedTransactionInput:CashFlowTransactionInput = {
        periodEndDate: updatedTransaction.periodEndDate,
        transactionDate: updatedTransaction.transactionDate,
        amount: updatedTransaction.amount,
        transactionNumber: updatedTransaction.transactionNumber,
        currency: updatedTransaction.currency.code,
        assetClassAbbreviation: updatedTransaction?.assetClassAbbreviation?.code as AssetClassAbbreviationCode,
        country: updatedTransaction?.country?.code
      }

      if (isNil(existingTransactionInputInd) || existingTransactionInputInd < 0) {
        updatedTransactionTypeInput.transactions.push(updatedTransactionInput)
      } else {
        set(updatedTransactionTypeInput, `transactions[${existingTransactionInputInd}]`, updatedTransactionInput)
      }

      if (updatedTransactionTypeInputIndex < 0) {
        newTransactionTypesInput?.push(updatedTransactionTypeInput)
      } else {
        newTransactionTypesInput[updatedTransactionTypeInputIndex] = updatedTransactionTypeInput
      }

      cashFlowInput.cashFlowTransactionTypes = newTransactionTypesInput
      this.setStateDiff(cashFlowInput as CashFlowsInput)
    }
  }

  handleTransactionRemove = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    this.props.removeTransaction(pick(updatedTransaction, ['periodEndDate','transactionDate', 'transactionNumber']))

    let cashFlowInput = cloneDeep(this.state.stateDiff)
    let newTransactionTypesInput = cloneDeep(cashFlowInput.cashFlowTransactionTypes)
    if (isNil(newTransactionTypesInput)) {
      return
    }

    let updatedTransactionTypeInputIndex = newTransactionTypesInput?.findIndex(type => type?.transactionType === transactionTypeCode)

    let updatedTransactionTypeInput = cloneDeep(get(newTransactionTypesInput, updatedTransactionTypeInputIndex))

    if (updatedTransactionTypeInputIndex < 0 || isNil(updatedTransactionTypeInput) || isNil(updatedTransactionTypeInput.transactions)) {
      return
    }

    let newTransactionInputs = updatedTransactionTypeInput.transactions
    let removedTransactionInputInd = -1
    if (updatedTransaction.transactionNumber !== 0) {
      removedTransactionInputInd = newTransactionInputs?.findIndex(transaction => {
        return (
          transaction?.transactionNumber === updatedTransaction.transactionNumber &&
          transaction?.transactionDate === updatedTransaction.transactionDate
        )
      })
    }
    if (isNil(removedTransactionInputInd) || removedTransactionInputInd < 0) {
      return
    }
    newTransactionInputs.splice(removedTransactionInputInd,1)
    updatedTransactionTypeInput.transactions = newTransactionInputs
    newTransactionTypesInput[updatedTransactionTypeInputIndex] = updatedTransactionTypeInput

    cashFlowInput.cashFlowTransactionTypes = newTransactionTypesInput
    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }


  render() {
    const { editMode, date, data, editCancelled } = this.props

    const portfolio = data.portfolio_cashflows
    const relatedVehicle = portfolio?.relatedVehicle
    const product = relatedVehicle?.vehicle?.product

    let transactionTypeCodes = compact(data.assetClassMaps?.uniqueTransactionTypeCodeAssetClassMap?.filter(assetMap => assetMap?.assetMixNum === product?.product?.assetClass?.parent?.id)) || []
    // filter out transaction types that are not in the order list
    transactionTypeCodes = transactionTypeCodes.filter(typeCode => findIndex(TransactionTypeOrder, c => c.code === typeCode.code) >= 0)
    transactionTypeCodes = transactionTypeCodes.sort((a,b) => findIndex(TransactionTypeOrder, c => c.code === a.code) - findIndex(TransactionTypeOrder, c => c.code === b.code))
    transactionTypeCodes = uniqBy(transactionTypeCodes, 'code')
    const cashflowDefaultKeys = portfolio?.cashFlowDefaultKeys
    // const cashflowDefaultKeys:CashFlowDefaultKey = {
    //   __typename: 'CashFlowDefaultKey',
    //   currency: PortfolioCurrencyCode.USADOL,
    //   country: CountryCode.USA,
    //   assetClassAbbreviation: AssetClassAbbreviationCode.RE
    // }
    
    if (portfolio && product && cashflowDefaultKeys && relatedVehicle) {
      const finalCommitSize = "closedEnded" in product ? product.closedEnded?.finalCommitSize : 0
    
      return (
        <CashflowDisplay
          key={`cashflow-${portfolio.id}-display-${date}-${editCancelled}`}
          editMode={editMode}
          cashflows={portfolio.cashFlows as CashFlows || null}
          serviceStartDate={portfolio.serviceStartDate || undefined}
          cashflowDefaults={cashflowDefaultKeys}
          transactionTypeMap={transactionTypeCodes}
          finalCommitSize={finalCommitSize || 0}
          vehicleType={relatedVehicle.__typename}
          handleActivityChange={this.handleActivityChange}
          handleTransactionChange={this.handleTransactionChange}
          handleTransactionRemove={this.handleTransactionRemove}
          handleEndAssetChange={this.handleEndAssetChange}
          date={date}
          portfolioId={portfolio.id}
          editCancelled={editCancelled}
        />
      )
    }
    return <></>
  }
}

interface CashflowDisplayProps {
  editMode: boolean
  cashflows: CashFlows | null
  cashflowDefaults: CashFlowDefaultKey
  vehicleType: string
  finalCommitSize: number
  handleActivityChange: (property:string, value:number) => void
  handleTransactionChange: (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => void
  handleTransactionRemove: (transactionTypeCode: UniqueTransactionTypeCode, removedTransaction:CashFlowTransaction) => void
  handleEndAssetChange: (endingNetAsset:CashFlowNetAssetValue) => void
  date: string
  portfolioId: number
  editCancelled: boolean
  transactionTypeMap: UniqueTransactionTypeCodeMapItems[]
  serviceStartDate?: string
}

interface CashflowDisplayState {
  currentState: CashFlows
  initialState: CashFlows
}

class CashflowDisplay extends Component<CashflowDisplayProps,CashflowDisplayState> {
  constructor(props: CashflowDisplayProps) {
    super(props)

    let cashflows:CashFlows = {
      beginningNetAssetValue: props.cashflows?.beginningNetAssetValue || null,
      endingNetAssetValue: props.cashflows?.endingNetAssetValue || null,
      cashFlowTransactionTypes: props.cashflows?.cashFlowTransactionTypes || [],
      cashFlowCashActivity: props.cashflows?.cashFlowCashActivity || null,
      __typename: "CashFlows"
    }

    this.state = {
      currentState: cashflows,
      initialState: cashflows
    }
  }

  static getDerivedStateFromProps(nextProps:CashflowDisplayProps, prevState:CashflowDisplayState){
    if(nextProps.editCancelled || !nextProps.editMode) {
      return { currentState: nextProps.cashflows, initialState: nextProps.cashflows };
    }

    return null;
  }

  handleTransactionUpdate = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    let newState = cloneDeep(this.state.currentState)
    

    let newTransactionTypes = cloneDeep(newState.cashFlowTransactionTypes)
    if (isNil(newTransactionTypes)) {
      newTransactionTypes = []
    }

    let updatedTransactionTypeIndex = newTransactionTypes?.findIndex(type => type?.transactionType?.code === transactionTypeCode)
    
    let updatedTransactionType = cloneDeep(get(newTransactionTypes, updatedTransactionTypeIndex))
    if (updatedTransactionTypeIndex < 0 || isNil(updatedTransactionType)) {
      updatedTransactionType = {
        __typename: 'CashFlowTransactionType',
        transactionType: { __typename: 'TransactionTypeLookup', code: transactionTypeCode },
        transactions: []
      }
    }
    

    if (isNil(updatedTransactionType.transactions)) {
      updatedTransactionType.transactions = []
    }

    let existingTransactionInd = updatedTransactionType?.transactions?.findIndex(transaction => transaction?.transactionNumber === updatedTransaction.transactionNumber)

    if (isNil(existingTransactionInd) || existingTransactionInd < 0) {
      updatedTransactionType.transactions.push(updatedTransaction)
    } else {
      set(updatedTransactionType, `transactions[${existingTransactionInd}]`, updatedTransaction)
    }

    if (updatedTransactionTypeIndex < 0) {
      newTransactionTypes?.push(updatedTransactionType)
    } else {
      newTransactionTypes[updatedTransactionTypeIndex] = updatedTransactionType
    }

    newState.cashFlowTransactionTypes = newTransactionTypes
    this.setState({ currentState: newState },() => {
      this.props.handleTransactionChange(transactionTypeCode, updatedTransaction)
    })
  }

  handleTransactionRemove = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    let newState = cloneDeep(this.state.currentState)
    let newTransactionTypes = cloneDeep(newState.cashFlowTransactionTypes)
    if (isNil(newTransactionTypes)) {
      return
    }

    let updatedTransactionTypeIndex = newTransactionTypes?.findIndex(type => type?.transactionType?.code === transactionTypeCode)

    let updatedTransactionType = cloneDeep(get(newTransactionTypes, updatedTransactionTypeIndex))
    if (updatedTransactionTypeIndex < 0 || isNil(updatedTransactionType)) {
      return
    }

    if (isNil(updatedTransactionType.transactions)) {
      return
    }

    let newTransactions = updatedTransactionType.transactions
    let removedTransactionInd = newTransactions.findIndex(transaction => transaction?.transactionNumber === updatedTransaction.transactionNumber)

    if (isNil(removedTransactionInd) || removedTransactionInd < 0) {
      return
    }

    newTransactions.splice(removedTransactionInd,1)
    updatedTransactionType.transactions = newTransactions
    newTransactionTypes[updatedTransactionTypeIndex] = updatedTransactionType

    newState.cashFlowTransactionTypes = newTransactionTypes
    this.setState({ currentState: newState },() => {
      this.props.handleTransactionRemove(transactionTypeCode, updatedTransaction)
    })
  }

  handleActivityUpdate = (property:keyof CashFlowCashActivity, value:number) => {
    let newState = cloneDeep(this.state.currentState)
    let newActivity = cloneDeep(newState.cashFlowCashActivity)

    if (!newActivity) {
      newActivity = { __typename: 'CashFlowCashActivity', periodEndDate: this.props.date}
    }
    set(newActivity, property, value)

    newState.cashFlowCashActivity = newActivity
    this.setState({ currentState: newState },() => {
      this.props.handleActivityChange(`cashFlowCashActivity.${property}`, value)
    })
  }

  handleEndAssetChange = (value: number ) => {
    let newState = cloneDeep(this.state.currentState)
    let newEndingValue = cloneDeep(newState.endingNetAssetValue)

    if (newEndingValue) {
      set(newEndingValue, 'amount', value)
    } else {
      newEndingValue = {
        __typename: 'CashFlowNetAssetValue',
        accruedInterests: 0,
        assetClassAbbreviation: this.props.cashflowDefaults.assetClassAbbreviation,
        country: { code: this.props.cashflowDefaults.country, __typename: 'PortfolioCountryLookup' },
        date: this.props.date,
        amount: value,
        currency: { code: this.props.cashflowDefaults.currency, __typename: 'PortfolioCurrencyLookup' }
      }
    }

    newState.endingNetAssetValue = newEndingValue
    this.setState({ currentState: newState },() => {
      if (newEndingValue) {
        this.props.handleEndAssetChange(newEndingValue)
      }
    })
  }

  generatePerformanceParams = () => {
    let state = this.state.initialState

    let input = {
      beginningNetAssetValue: state.beginningNetAssetValue ? {
        date: state.beginningNetAssetValue?.date,
        amount: state.beginningNetAssetValue?.amount,
        accruedInterests: state.beginningNetAssetValue?.accruedInterests,
        currency: state.beginningNetAssetValue?.currency?.code
      } : null,
      endingNetAssetValue: state.endingNetAssetValue ? {
        date: state.endingNetAssetValue?.date,
        amount: state.endingNetAssetValue?.amount,
        accruedInterests: state.endingNetAssetValue?.accruedInterests,
        currency: state.endingNetAssetValue?.currency?.code
      } : null,
      numeraire: this.props.cashflowDefaults.currency,
      cashFlowTransactionTypes: state.cashFlowTransactionTypes?.map(ttype => {
        return {
          transactionType: ttype?.transactionType?.code,
          transactions: ttype?.transactions?.map(transaction => { return {
            date: transaction?.transactionDate,
            amount: transaction?.amount,
            transactionNumber: transaction?.transactionNumber,
            currency: transaction?.currency?.code,
          }})
        }
      }),
      net: true,
    }

    return input
  }
  calculateAppreciation = () => {
    const endingNetAssetValue = this.state.currentState?.endingNetAssetValue?.amount || 0
    const beginningNetAssetValue = this.state.currentState?.beginningNetAssetValue?.amount || 0

    let total = endingNetAssetValue - beginningNetAssetValue

    // endingNetAssetValue - beginningNetAssetValue - sum(C) -sum(CI) + sum(CL) - sum(CO) + sum(RC) + sum(D) + sum(DR) + sum (DU) + sum(F)- sum(RF) + sum(FE) - sum(RE) + sum(FO) - sum(RO) - sum(IP) + sum(IL)

    // updated on 2024/04 https://callanllc.atlassian.net/browse/CAL-3171?focusedCommentId=85551
    // endingNetAssetValue - beginningNetAssetValue -sum(C)-sum(CO)+sum(OR)-sum(IP)+sum(IL)+sum(D)-sum(CD)+sum(RC)+sum(DR)-sum(RD)+sum(DU)+sum(F)-sum(RF)+sum(FO)-sum(RO)+sum(FE)-sum(RE)+sum(CI)-sum(CL)
    this.state.currentState.cashFlowTransactionTypes?.map(transactionType => {
      if (!transactionType) {
        return 0
      }

      const currentNetValue = this.transactionsInCurrentPeriodNetValue(transactionType)
      const type = transactionType?.transactionType
      // if (type?.code && ["CL","RC","D","DR","DU", "F","FE","FO","IL"].includes(type?.code)) {
      if (type?.code && ["OR","CI","RC","D","DR","DU", "F","FE","FO","IL"].includes(type?.code)) {
        return total += currentNetValue || 0
      } else {
        return total -= currentNetValue || 0
      }
    })

    return total
  }

  transactionsInCurrentPeriod = (transactionType:CashFlowTransactionType) => {
    return compact(transactionType?.transactions?.filter(t => t?.periodEndDate === this.props.date )) || []
  }

  transactionsInCurrentPeriodNetValue = (transactionType:CashFlowTransactionType):number => {
    const reducer = (sum:number, t:CashFlowTransaction) => { return sum + t.amount }
    return this.transactionsInCurrentPeriod(transactionType).reduce(reducer, 0)
  }

  render() {
    const cashflows = this.state.currentState
    const { editMode } = this.props
    const generatedParams = this.generatePerformanceParams()

    return(
      <>
      <div className="pane pane-table">
        <Row>
          <Col md="8">
            <Row>
              <Col md="12">
                <h3 className="subheader underline green-underline">
                  Transactions
                </h3>
                Please enter all transactions as positive numbers.  All transactions will be saved as positive values.
              </Col>
            </Row>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <td className="w-25 width-160"></td>
                  <td className="text-uppercase text-gray-50 text-left w-25"><small>Date</small></td>
                  <td className="text-uppercase text-gray-50 text-right w-50"><small>Net Value</small></td>
                </tr>
              </thead>
              <tbody>
                <tr className='text-callan-blue font-weight-bold row-highlight'>
                  <td className="text-left">
                    Beginning net asset value
                  </td>
                  <td className="text-left">{  cashflows.beginningNetAssetValue?.date || this.props.date }</td>
                  <td className="text-right"><span className="strong-emphasis">{ numbro(cashflows.beginningNetAssetValue?.amount || 0).format('$0,0.00')}</span></td>
                </tr>
              </tbody>
            </Table>
            { this.props.transactionTypeMap.sort((a,b) => a.code < b.code ? -1 : 1).map((transactionTypeCode, idx: number) => {
              return (
                <TransactionTypeTable
                  key={`transaction-table-${idx}`}
                  transactionTypeCode={transactionTypeCode.code}
                  transactionType={cashflows?.cashFlowTransactionTypes?.find(t => t?.transactionType.code === transactionTypeCode.code)}
                  editMode={editMode}
                  transactionTypeNum={idx}
                  updateTransactionType={this.handleTransactionUpdate}
                  removeTransaction={this.handleTransactionRemove}
                  date={this.props.date}
                  serviceStartDate={this.props.serviceStartDate}
                  cashflowDefaults={this.props.cashflowDefaults}
                />
              )
            })}
            {/* {
              cashflows?.cashFlowTransactionTypes?.sort((a,b) => (a?.transactionType?.code || 0) < (b?.transactionType?.code || 0) ? -1 : 1).map((transactionType, idx:number) =>
                <TransactionTypeTable
                  key={`transaction-table-${idx}`}
                  transactionTypeCode={transactionTypeCode}
                  transactionType={transactionType}
                  editMode={editMode}
                  transactionTypeNum={idx}
                  updateTransactionType={this.handleTransactionUpdate}
                  removeTransaction={this.handleTransactionRemove}
                  date={this.props.date}
                  cashflowDefaults={this.props.cashflowDefaults}
                />
              )
            } */}
            <Table borderless size="sm" className="mt-3">
              <thead>
                <tr>
                  <th className="text-left width-160 w-25"><strong>Ending net asset value</strong></th>
                  <td className="text-uppercase text-gray-50 text-left w-25"><small>Date</small></td>
                  <td className="text-uppercase text-gray-50 text-right w-50"><small>Net Value</small></td>
                </tr>
              </thead>
              <tbody>
                <tr className="background-gray-30">
                  <td></td>
                  <td className="text-left">
                    {moment(cashflows.endingNetAssetValue?.date || this.props.date ).format(DATE_DISPLAY_FORMAT)}
                  </td>
                  <td className="text-right">
                    <FormInput
                      idx={`endingNetAssetValue-amount`}
                      property={`endingNetAssetValue-amount`}
                      displayName=''
                      type='float'
                      subtype='currency'
                      editMode={editMode}
                      propertyVal={cashflows.endingNetAssetValue?.amount}
                      updateValue={(v) => this.handleEndAssetChange(v)}
                    />
                  </td>

                </tr>
              </tbody>
            </Table>
            <Table borderless size="sm" className="mt-3">
              <thead>
                <tr>
                  <th></th>
                  <td className="text-uppercase text-gray-50 text-right"><small>Amount</small></td>
                </tr>
              </thead>
              <tbody>
                <tr className='text-callan-blue font-weight-bold row-highlight'>
                  <td className="text-left">
                    <strong>Appreciation/Depreciation *</strong>
                  </td>
                  <td className="text-right"><span className="strong-emphasis">{ numbro(this.calculateAppreciation()  || 0).format('$0,0.00')}</span></td>
                </tr>
              </tbody>
            </Table>
            <small className="text-gray-50">* Calculated using the transaction data shown on this screen.</small>

          </Col>
          <Col md="4">
            <Query<ClientPortfolioPerformanceCashflowPerformanceQuery> query={CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS_PERFORMANCE} variables={{ id: this.props.portfolioId, date: this.props.date, performanceInput: generatedParams }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
              {
                ({loading, error, data}) => {
                  if (loading) {
                    return <PlaceHolder />
                  }

                  if (error) {
                    return (
                      <Alert color="warning">
                        Unable to retrieve cash flows performance metrics.
                      </Alert>
                    )
                  }

                  return (
                    <>
                      <PerformanceTable
                        editMode={editMode}
                        cashflowPerformance={data?.portfolio_cashflow_performance?.cashFlowsPerformance}
                        cashflowType='client'
                        serviceStartDate={this.props.serviceStartDate}
                        vehicleType={data?.portfolio_cashflow_performance?.relatedVehicle?.__typename || ''}
                      />

                      <ValuationsTable
                        editMode={editMode}
                        cashFlowCashActivity={cashflows.cashFlowCashActivity}
                        updateActivity={this.handleActivityUpdate}
                        vehicleType={this.props.vehicleType}
                      />

                      <CashActivityTable
                        editMode={editMode}
                        cashFlowCashActivity={cashflows.cashFlowCashActivity}
                        cashflowPerformance={data?.portfolio_cashflow_performance?.cashFlowsPerformance}
                        updateActivity={this.handleActivityUpdate}
                        vehicleType={this.props.vehicleType}
                        />
                    </>
                  )
                }
              }
            </Query>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

export default ProductPerformanceClientsCashflow