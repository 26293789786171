import React, { Component } from "react"
import { Query } from '@apollo/client/react/components'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Tooltip
} from "reactstrap"
import { Link, RouteComponentProps } from "react-router-dom"
import Logrocket from "logrocket"
import SearchBar from "../Search/SearchBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icon from "../ui/Icon"
import Auth from "../../Auth/Auth"
import { LocationProps } from '../../queries/extended/appProps'
import { History } from 'history';
import { SearchTypeDisplays} from '../Search/SearchEnums'
import { Maybe, MeFragment, MeQuery } from '../../__generated__/graphql'
import { ME_QUERY } from "../../queries/Person"
import { MatchParams } from "../ui/LayoutWrapper"
import { HelpSiteMapping } from "../../routes/helpSiteMapping"
import {SideBar} from "../SideBar"
import { getRecentProducts } from "../../helpers/session"

export interface matchProps<MatchParams> {
  params: MatchParams;
  isExact: boolean;
  path: string;
  url: string;
}

interface HeaderProps extends RouteComponentProps<MatchParams>{
  location: LocationProps
  auth: Auth
  children?: React.ReactNode
  history: History
  match: matchProps<MatchParams>;
}

interface HeaderState {
  navOpen: boolean
  userOpen: boolean
  tooltipOpen: boolean
  helpOpen: boolean,
  mobileSearchOpen: boolean,
  user: MeQuery | undefined
  helpSite: string
  auth: Auth
}
export default class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props)
    this.state = {
      auth: props.auth,
      navOpen: false,
      userOpen: false,
      tooltipOpen: false,
      helpOpen: false, // ??
      mobileSearchOpen: false,
      user: undefined,
      helpSite: ""
    }
  }
  componentDidUpdate = (prevProps: HeaderProps) => {
    if(this.props.match !== prevProps.match) {
      this.setState({helpSite: this.getHelpSite()})
    }
  }
  showBrand() {
    let {match} = this.props
    let {category} = match.params
    let headerName = "Dashboard"
    switch(category){
      case("clients"):
        headerName = "Clients"
        break
      case("reports"):
        headerName = "Reports"
        break
      case("lists"):
        headerName = "Lists"
        break
      case("plans"):
      case("clientportfolios"):
        headerName = "Plans"
        break
      case("managers"):
      case("products"):
      case("glidepaths"):
      case("recordkeepers"):
      case("custodians"):
      case("documents"):
      case("groups"):
      case("indexes"):
        headerName = "Data"
        break
      case("search"):
        headerName = ""
        break
      case("research"):
        headerName = "Research"
        break
      case("control-center"):
        headerName = "Controls"
        break
    }
    let sideBarPermission = this.props.auth.checkPermissions(["view:mega_menu"])
    return(
      <Nav className={`brandNav ${ this.state.navOpen ? 'open' : ''}`}>
        {sideBarPermission && <SideBar auth={this.state.auth}/>}
        {!sideBarPermission && (
          <NavLink
            //onClick={()=>this.setState({navOpen: !this.state.navOpen})}
            href="/"
          >
            <Icon width={17} height={17} />
          </NavLink>
        )}
        <NavbarBrand className="company_logo" to="/" tag={Link}>
          <img
            className="light-text-color mr-2"
            src="/img/callan_logo.svg"
            width="82px"
            height="24px"
            alt="callan_logo"
          />
        </NavbarBrand>
        <h2 className="headline">{headerName}</h2>
      </Nav>
    );
  }

  renderName = (firstName?:string | null, lastName?:string | null) => {
    if((firstName?.length || 0) + (lastName?.length || 0) >= 20){
      return `${firstName} ${lastName?.charAt(0)}.`
    } else {
      return `${firstName} ${lastName}`
    }
  }

  getDisplayName = (me: Maybe<{__typename: "User";}& MeFragment> | undefined) => {
    if(me?.person && (me.person.firstName || me.person.lastName)){
      return this.renderName(me.person.firstName, me.person.lastName)
    } else if (me?.firstName || me?.lastName) {
      return this.renderName(me?.firstName, me?.lastName)
    } else {
      return me?.email
    }
  }

  getDisplayInitials = (me: Maybe<{__typename: "User";}& MeFragment> | undefined) => {
    if(me?.person && (me.person.firstName || me.person.lastName)){
      return `${me.person.firstName?.charAt(0)}${me.person.lastName?.charAt(0)}`
    } else if (me?.firstName || me?.lastName) {
      return `${me?.firstName?.charAt(0)}${me?.lastName?.charAt(0)}`
    } else {
      return `${me?.email?.charAt(0)}`
    }
  }

  getHelpSite = (user?: MeQuery | undefined) => {
    const isCallanUser = this.props.auth.checkPermissions(["view:all_clients"])
    const isClientUser = this.props.auth.checkPermissions(["view:client"])

    let role = "", callanUserDefault = "", clientUserDefault = ""
    if(isCallanUser){
      role = "callanInternal"
      callanUserDefault = HelpSiteMapping.callanInternal as string
    }else if (isClientUser) {
      role = "clientUser"
      clientUserDefault = HelpSiteMapping.clientUserDefault as string
    }
    let {match} = this.props
    let {category, type, typeOrReportId, reportId, listId, subtype} = match.params
    if(category) {
      let categoryValue = HelpSiteMapping[category]
      if(categoryValue && typeof(categoryValue) !== "string") {
        let typeValue:any = ""
        if(typeOrReportId) {
          // for client portal
          typeValue = categoryValue[type || typeOrReportId]
          if(type === "documents"){
            typeValue = categoryValue["fund documents"]
          }else if(type === "managerdocs"){
            typeValue = categoryValue["manager documents"]
          }
        }else if(category === "research") {
          let isInternal = this.props.auth.checkPermissions(["view:opinion_column"])
          let viewResearch = this.props.auth.checkPermissions(["view:research_products"])
          role = isInternal? "callanInternal": viewResearch? "default": ""
          if(role) {
            return categoryValue[role] as string
          }else {
            // no permission
            return isCallanUser? callanUserDefault: clientUserDefault
          }
        }
        if(reportId && category === "reports") {
          typeValue = categoryValue['reports editor']
        }
        if(listId && category === "lists"){
          typeValue = categoryValue['list detail']
        }
        switch(subtype){
          case "diversity":
            typeValue = categoryValue['diversity']
            break;
          case "esg": 
            typeValue = categoryValue['esg']
            break;
          case "targetDateFamilies":
            typeValue = categoryValue['target date families']
            break;
          case "clientType":
            typeValue = categoryValue['client type']
            break;
          case "location":
            typeValue = categoryValue['location']
            break;
          case "assetClass":
            typeValue = categoryValue['asset class']
            break;
          case "accounts":
            typeValue = categoryValue['accounts']
            break;
          case "closedEnd":
            typeValue = categoryValue['closed-end fundraising']
            break;
        }
        // else if(category === "clientportfolio"){
        //   typeValue = categoryValue['client portfolio overview']
        // }
        if((type && type !== "default") || typeValue) {
          if(type && !typeValue) {
            typeValue = categoryValue[type]
          }
          if(typeValue && typeof(typeValue) !== "string") {
            if(role) {
              // use recent product session for help site.
              if(category === "products" && type === "profile") {
                let recentProduct = getRecentProducts(1)
                if(recentProduct.length > 0) {
                  let product = recentProduct[0]
                  let isPrivateEquity = product.parentMix === 57 || product.parentMix === 146
                  if(isPrivateEquity) {
                    if(role === "callanInternal") {
                      role = "callanInternalPrivateEquity"
                    }else if(role === "clientUser") {
                      role = "clientUserPrivateEquity"
                    }
                  }
                }
              }
              return typeValue[role] || typeValue.default as string
            }else {
              return typeValue.default as string
            }
          }else if(typeValue) {
            return typeValue
          }else {
            return (isClientUser? categoryValue.clientUserDefault: categoryValue.default) as string
          }
        }else if(type === "default") {
          return clientUserDefault || categoryValue.default as string
        }else if(!type) {
          if(role) {
            return categoryValue[role] || categoryValue.default as string
          }else {
            return categoryValue.default as string
          }
        }
        return clientUserDefault || (categoryValue?.default || HelpSiteMapping.default) as string
      }else if(categoryValue) {
        return categoryValue
      }
    }else {
      // Dashboard default
      return (callanUserDefault || clientUserDefault || HelpSiteMapping.default) as string
    }

    return callanUserDefault || clientUserDefault || HelpSiteMapping.default as string
  }
  render() {
    const { children, location } = this.props;
    const searchParams = new URLSearchParams(location.search)
    return (
      <Query<MeQuery> query={ME_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} >
      {params => {
        const me = params?.data?.me
        if(me && process.env.NODE_ENV !== 'development') {
          Logrocket.identify(me.id, {
            name: me.firstName + " " + me.lastName,
            email: me.email || "",
          })
        }
        let user = params?.data
        let helpSite = HelpSiteMapping["default"]
        if(user && !this.state.user) {
          helpSite = this.getHelpSite(params?.data)
        }
        let displayName = this.getDisplayName(me)
        let displayInitials = this.getDisplayInitials(me)
        if (this.state.mobileSearchOpen) {
          return (
            <Navbar className="header background-blue-120">
              <Nav className="searchBar d-flex px-0">
                <SearchBar
                  query={searchParams.get('q')}
                  type={searchParams.get('types') as SearchTypeDisplays}
                  location={location}
                  auth={this.props.auth}
                />
              </Nav>
              <Nav className="mobileSearchNavClose">
                <NavItem>
                  <NavLink onClick={() => this.setState({ mobileSearchOpen: false })}>
                    <FontAwesomeIcon
                      icon="times"
                      size="2x"
                      className='fontawesome-icon text-blue-70'
                    />
                  </NavLink>
                </NavItem>
                </Nav>
            </Navbar>
          );
        }
        return (<Navbar className="header background-blue-120">
        { this.showBrand() }
        <Nav className="searchBar d-none d-md-flex">
          <SearchBar
            query={searchParams.get('q')}
            type={searchParams.get('types') as SearchTypeDisplays}
            location={location}
            auth={this.props.auth}
          />
        </Nav>
        <Nav className="mobileSearchNav d-md-none">
          <NavItem>
            <NavLink onClick={() => this.setState({ mobileSearchOpen: true })}>
              <FontAwesomeIcon
                icon="search"
                size="2x"
                className='fontawesome-icon text-blue-70'
              />
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="helpNav">
          <NavItem className="help">
            <NavLink
              href={helpSite}
              className={`toggle header-text${ this.state.helpOpen ? ' open' : ''}`}
              id='helpIcon'
              target="_blank">
              <FontAwesomeIcon
                icon="question-circle"
                size="2x"
                className={`fontawesome-icon ${ this.state.tooltipOpen ? 'text-white' : 'text-blue-70'}`}
              />
              <Tooltip placement="auto" isOpen={this.state.tooltipOpen} target="helpIcon" toggle={() => this.setState({ tooltipOpen: !this.state.tooltipOpen})}>
                Callan DNA Support Center
              </Tooltip>
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className="userNav">
          <NavItem>
            <NavLink onClick={()=> this.setState({userOpen: !this.state.userOpen })} className={`toggle header-text${ this.state.userOpen ? ' open' : ''}`}>
              <div className="d-none d-md-inline">{displayName}</div>
              <div className="d-inline d-md-none">{displayInitials !== 'undefined' && displayInitials}</div>
              <FontAwesomeIcon
                icon={this.state.userOpen ? 'minus' : 'chevron-down' }
                size="xs"
                className={`fontawesome-icon  ml-2 ${ this.state.userOpen ? 'text-white' : 'text-blue-70'}`}
              />
            </NavLink>
          </NavItem>
          <Nav vertical className={`userDropdown p-2 ${ this.state.userOpen ? 'open' : ''}`}>
            {this.props.auth.userProfile}
            {children}
          </Nav>
        </Nav>
      </Navbar>)
      }}
    </Query>
    )
  }
}


