import React, { useContext, useState, useEffect, useRef } from "react"
import { CalendarContext, appDate, monthAppDate, yearAppDate } from "../Context/CalendarContext"
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Popover, PopoverBody } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { useHistory } from "react-router-dom"
// import { parse, ParsedQuery, stringify } from 'query-string'
import moment, { Moment } from 'moment'
import classnames from 'classnames'
import { DATE_API_FORMAT } from '../helpers/constant'
import { GuardModal } from './Shared/RouteLeavingGuard'

export type CalendarPickerPeriod = "quarter" | "month" | "year"

export interface CalendarPickerProps {
  hasMonthly?: boolean,
  hasHistorical?: boolean,
  singular?: boolean,
  onlyYear?: boolean,
  hideDate?: boolean,
  defaultPeriod?: CalendarPickerPeriod,
  editMode?: boolean,
  hasCustomItems?: boolean,
  children?: React.ReactNode,
  defaultType?: string,
  setEditMode?: (value:boolean) => void,
  updateValue: (startDate:string) => void,
  updateType?: (type:string) => void,
  updatePeriod?: (type:CalendarPickerPeriod) => void,
}

export const getInitialYearRange = (currentDate: Moment, period: number = 12) => {
  const endYear = appDate.year()
  const yearDiff = endYear - currentDate.year()
  const periodOffset = Math.floor(yearDiff/period)
  const rangeOffset = ((periodOffset + 1) * period) -1
  return endYear - rangeOffset
}

// This should work as a container for off-canvas left navigation
export const CalendarPicker: React.FC<CalendarPickerProps> = props => {
  const context = useContext(CalendarContext)
  const { month: inMonth, quarter: inQuarter, year: inYear, period: inPeriod, lockQuarter, lockMonth, lockYear, lockPeriod } = context
  const [ openPeriod, setOpenPeriod ] = useState(false)
  const [ openType, setOpenType ] = useState(false)
  const [ openPicker, setOpenPicker ] = useState(false)
  let supportedPeriods = ['quarter']
  if(props.hasMonthly){
    supportedPeriods.push("month")
  } else if (props.onlyYear){
    supportedPeriods = ["year"]
  }
  let inPeriodFiltered = supportedPeriods.includes(inPeriod || "") ? (inPeriod) as CalendarPickerPeriod : null
  const [ period, setPeriod ] = useState<CalendarPickerPeriod>((inPeriod === "historical" ? null : inPeriodFiltered) || props.defaultPeriod || "quarter")
  const [ type, setType ] = useState(inPeriod === "historical" ? "historical" : (props.defaultType || "single"))
  const [ quarter, setQuarter ] = useState(inQuarter)
  const [ month, setMonth ] = useState(inMonth)
  const [ yearly, setYearly ] = useState(inYear)
  let initialLocked = false
  if(props.hideDate && inPeriodFiltered){
    initialLocked = true
  } else if (period === "quarter"){
    initialLocked = appDate.format(DATE_API_FORMAT) !== quarter
  } else if (period === "month"){
    initialLocked = monthAppDate.format(DATE_API_FORMAT) !== month
  } else if (period === "year"){
    initialLocked = yearAppDate.format(DATE_API_FORMAT) !== yearly
  }
  const [ locked, setLocked ] = useState(initialLocked)
  const currentDate = moment((period === "quarter") ? quarter : (period === "year") ? yearly : month, DATE_API_FORMAT)
  const [ year, setYear ] = useState(currentDate.year())
  const [ yearRange, setYearRange ] = useState(getInitialYearRange(currentDate, 12))
  const [ modalVisible, setModalVisible ] = useState(false)
  const [ blockedUpdate, setBlockedUpdate ] = useState(() => () => {return})
  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  // const history = useHistory()

  const blockUpdate = (callback:() => void) => {
    setModalVisible(true)
    setBlockedUpdate(() => callback)
  }

  const blockStay = () => {
    setModalVisible(false)
    setBlockedUpdate(() => () => {return})
  }

  const blockLeave = () => {
    blockedUpdate()
    if(!!props.setEditMode) props.setEditMode(false)
    setBlockedUpdate(() => () => {return})
    setModalVisible(false)
  }

  const toggleLock = () => {
    if(locked){
      setLocked(false)
      lockPeriod(null)
    } else {
      if(period === "quarter"){
        lockQuarter(quarter)
      }else if( period === "month"){
        lockMonth(month)
      }else if( period ==="year"){
        lockYear(yearly)
      }
      lockPeriod(period)
      setLocked(true)
      setOpenPicker(false)
    }
  }

  const togglePicker = () => {
    setOpenPicker(!openPicker)
  }

  const handleClick = (e:any) => {
    if (dropdownRef?.current?.contains(e.target) || buttonRef?.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpenPicker(false)
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    if(inPeriodFiltered){
      setPeriod(inPeriodFiltered)
      if(props.updatePeriod) props.updatePeriod(inPeriodFiltered)
    }
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if(props.defaultType){
      setType(props.defaultType)
    }
  }, [props.defaultType])

  useEffect(() => {
    if(props.defaultPeriod){
      setPeriod(props.defaultPeriod)
    }
  }, [props.defaultPeriod])

  const selectPeriod = (period:CalendarPickerPeriod, forceUpdate?:boolean) => {
    if(props.editMode && !forceUpdate){
      blockUpdate(() => selectPeriod(period, true))
      return
    }
    setPeriod(period)
    if(props.updatePeriod) props.updatePeriod(period)
    if(period === "quarter"){
      props.updateValue(quarter)
    }else if( period === "month"){
      props.updateValue(month)
    }
    setLocked(false)
  }

  const setSelectedQuarter = (quarter_number:number, forceUpdate?:boolean) => {
    const value = moment(year.toString(), "YYYY").quarter(quarter_number).endOf("quarter")
    if(value.isAfter(appDate)){
      return
    }
    if(props.editMode && !forceUpdate){
      blockUpdate(() => setSelectedQuarter(quarter_number, true))
      return
    }
    setQuarter(value.format(DATE_API_FORMAT))
    props.updateValue(value.format(DATE_API_FORMAT))
    setLocked(false)
  }

  const setSelectedMonth = (month_number:number, forceUpdate?:boolean) => {
    const value = moment(year.toString(), "YYYY").month(month_number).endOf("month")
    if(value.isAfter(appDate)){
      return
    }
    if(props.editMode && !forceUpdate){
      blockUpdate(() => setSelectedMonth(month_number, true))
      return
    }
    setMonth(value.format(DATE_API_FORMAT))
    props.updateValue(value.format(DATE_API_FORMAT))
    setLocked(false)
  }

  const setSelectedYearRange = (yearRange:number) => {
    if(yearRange > appDate.year() || yearRange < 1900){
      return
    }
    setYearRange(yearRange)
  }

  const setSelectedYear = (year_number:number, forceUpdate?:boolean) => {
    if(year_number > appDate.year() || year_number < 1900){
      return
    }
    if(props.editMode && !forceUpdate){
      blockUpdate(() => setSelectedYear(year_number, true))
      return
    }
    let value
    if(period === "quarter"){
      value = moment(quarter, DATE_API_FORMAT).year(year_number)
      if(value.isAfter(appDate)){
        value = appDate
      }
      setQuarter(value.format(DATE_API_FORMAT))
    }else if( period === "month"){
      value = moment(month, DATE_API_FORMAT).year(year_number)
      if(value.isAfter(appDate)){
        value = appDate
      }
      setMonth(value.format(DATE_API_FORMAT))
    } else if( period === "year"){
      value = moment(year, "YYYY").year(year_number).endOf('year')
      setYearly(value.format(DATE_API_FORMAT))
    }
    setYear(year_number)
    props.updateValue(value?.format(DATE_API_FORMAT) || "")
    setLocked(false)
  }

  const selectType = (type:string, forceUpdate?: boolean) => {
    if(props.editMode && !forceUpdate){
      blockUpdate(() => selectType(type, true))
      return
    }
    // let parsedParams:ParsedQuery = parse(history.location.search)
    // if(type == "historical"){
    //   parsedParams.period = "historical"
    // } else {
    //   delete parsedParams.period
    // }
    // history.replace({
    //   pathname: history.location.pathname,
    //   search: '?'+stringify(parsedParams)
    // })
    setType(type)
    if(props.updateType){
      props.updateType(type)
    }
  }

  // Change Icon to better one
  const quarterlySelect = (
    <>
      <FontAwesomeIcon
        icon={["fal", "calendar-alt"]}
        size="sm"
        className="mr-2"
        onClick={() => toggleLock()}
      />
      Quarter{!props.singular &&`ly`}
    </>
  )

  const monthlySelect = (
    <>
      <FontAwesomeIcon
        icon={["fal", "calendar-alt"]}
        size="sm"
        className="mr-2"
        onClick={() => toggleLock()}
      />
      Month{!props.singular &&`ly`}
    </>
  )

  // const yearlySelect = (
  //   <>
  //     <FontAwesomeIcon
  //       icon={["fal", "calendar-alt"]}
  //       size="sm"
  //       className="mr-2"
  //       onClick={() => toggleLock()}
  //     />
  //     Yearly
  //   </>
  // )

  const quarterList = [
    {name: "Q1", value: 1},
    {name: "Q2", value: 2},
    {name: "Q3", value: 3},
    {name: "Q4", value: 4}
  ]

  const monthList = [
    {name: "Jan", value: 1},
    {name: "Feb", value: 2},
    {name: "Mar", value: 3},
    {name: "Apr", value: 4},
    {name: "May", value: 5},
    {name: "Jun", value: 6},
    {name: "Jul", value: 7},
    {name: "Aug", value: 8},
    {name: "Sep", value: 9},
    {name: "Oct", value: 10},
    {name: "Nov", value: 11},
    {name: "Dec", value: 12}
  ]

  const yearOffsetList = [0,1,2,3,4,5,6,7,8,9,10,11]

  return (
    <>
      <div ref={buttonRef} className={`btn btn-secondary btn-lockable missing-icon btn-cal mr-1 ${locked ? "locked" : ""}`}>
        {/* if both quarterly/monthly picker */}
        {props.hasMonthly &&
          <ButtonDropdown isOpen={openPeriod} toggle={() => setOpenPeriod(!openPeriod)} className="mr-1">
            <DropdownToggle color="link" caret>
              {period === "quarter" &&
                quarterlySelect
              }
              {period === "month" &&
                monthlySelect
              }
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => selectPeriod("quarter")}>{quarterlySelect}</DropdownItem>
              <DropdownItem onClick={() => selectPeriod("month")}>{monthlySelect}</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        }
        {/* single/historical picker if required */}
        {props.hasHistorical &&
          <ButtonDropdown isOpen={openType} toggle={() => setOpenType(!openType)} className="mr-1 within-btn">
            <DropdownToggle caret>
              {type === "single" &&
                "Single"
              }
              {type === "historical" &&
                "Historical"
              }
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => selectType("single")}>Single</DropdownItem>
              <DropdownItem onClick={() => selectType("historical")}>Historical</DropdownItem>
              { props.hasCustomItems && props.children }
            </DropdownMenu>
          </ButtonDropdown>
        }
        {!props.hasHistorical && props.hasCustomItems && props.children &&
          <ButtonDropdown isOpen={openType} toggle={() => setOpenType(!openType)} className="mr-1 within-btn">
            <DropdownToggle caret>
              Single
            </DropdownToggle>
            <DropdownMenu>
              { props.children }
            </DropdownMenu>
          </ButtonDropdown>
        }
        {/* date/quarter picker */}
        {type === "single" &&
          <>
            {!props.hideDate &&
              <>
                <span onClick={() => togglePicker()} id="calendar-popover" className="pr-4">
                  <FontAwesomeIcon
                    icon={["fal", "calendar-alt"]}
                    size="sm"
                    className="mr-2"
                    />
                    {period === "quarter" &&
                      `Q${currentDate.quarter()} ${year}`
                    }
                    {period === "month" &&
                      `${currentDate.format("MMM")} ${year}`
                    }
                    {period === "year" &&
                      `${year}`
                    }
                </span>
                <Popover placement="bottom" isOpen={openPicker} target="calendar-popover" toggle={() => togglePicker()} className="calendar-dropdown">
                  <PopoverBody>
                    <div ref={dropdownRef}>
                      {period === "quarter" &&
                        <div className="quarter calendar-options mb-2">
                          <Row>
                            {quarterList.map((quarterObject) => (
                              <Col
                                key={quarterObject.value}
                                xs="3"
                                onClick={() => setSelectedQuarter(quarterObject.value)}
                                className={classnames({ 'calendar-item': true, active: currentDate.year() === year && currentDate.quarter() === quarterObject.value, disabled: (year >= appDate.year() && quarterObject.value > appDate.quarter()) })}
                              >
                                {quarterObject.name}
                              </Col>
                            ))}
                          </Row>
                        </div>
                      }
                      {period === "month" &&
                        <div className="month calendar-options mb-2">
                          <Row>
                            {monthList.map((monthObject) => (
                              <Col
                                key={monthObject.value}
                                xs="3"
                                onClick={() => setSelectedMonth(monthObject.value)}
                                className={classnames({ 'calendar-item': true, active: currentDate.year() === year && currentDate.month() === monthObject.value, disabled: (year >= appDate.year() && monthObject.value > appDate.month()) })}
                              >
                                {monthObject.name}
                              </Col>
                            ))}
                          </Row>
                        </div>
                      }
                      {/* Year Select */}
                      <div className="calendar-navigator mb-2">
                        <Row>
                          <Col xs="3" onClick={() => setSelectedYearRange(yearRange-12)} className={classnames({ 'calendar-item': true, disabled: (yearRange-12 < 1900) })}>-</Col>
                          <Col xs="6">{yearRange} - {yearRange+11}</Col>
                          <Col xs="3" onClick={() => setSelectedYearRange(yearRange+12)} className={classnames({ 'calendar-item': true, disabled: (yearRange+12 > appDate.year()) })}>+</Col>
                        </Row>
                      </div>
                      <div className="month calendar-options mb-2">
                        <Row>
                          {yearOffsetList.map((yearOffset) => (
                            <Col
                              key={yearOffset}
                              xs="3"
                              onClick={() => setSelectedYear(yearRange + yearOffset)}
                              className={classnames({ 'calendar-item': true, disabled: (yearRange + yearOffset < 1900), active: currentDate.year() === yearRange + yearOffset })}
                            >
                              {yearRange + yearOffset}
                            </Col>
                          ))}
                        </Row>
                      </div>
                      {/* nice string */}
                      <p className="mb-0 text-center"><span className="text-capitalize">{period}</span> ending on {currentDate.format("MMMM Do, YYYY")}</p>
                    </div>
                  </PopoverBody>
                </Popover>
              </>
            }
            {/* Lock */}
            <span className="lock-container" onClick={() => toggleLock()}>
              <FontAwesomeIcon
                icon={["far", "lock"]}
                size="sm"
                className="lock-icon"
              />
              <FontAwesomeIcon
                icon={["far", "lock-open"]}
                size="sm"
                className="unlock-icon"
              />
            </span>
          </>
        }
      </div>
      <GuardModal
        open={modalVisible}
        stay={blockStay}
        leave={blockLeave}
      />
    </>
  )
}
