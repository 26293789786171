import {
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from "@ag-grid-community/core"
import { convertFilterModel, convertSortStateToSavedFilterState } from "../../../ResearchProducts/FilterViewModal"

const mockData = {
  product: {
    activePassive: "ACTIVE",
    assetClass: {
      id: 16,
      fullName: "Domestic Balanced",
      parent: {
        fullName: "Domestic Balanced",
        id: 16,
        __typename: "AssetClass",
      },
      __typename: "AssetClass",
    },
    clientExposure: [],
    hasCommingled: false,
    hasMutualFund: false,
    id: 1,
    inactive: true,
    lastActivity: null,
    latestAum: null,
    manager: { id: 256, name: "Capital Management Associates", __typename: "Manager" },
    name: "Balanced Composite",
    numberOfClientsInvested: 0,
    primaryBenchmark: null,
    quarterly: [],
    writeUps: { opinions: Array(0), __typename: "ProductWriteUps" },
    yearly: [],
    __typename: "ProductFields",
  },
  __typename: "OpenEndedEquity",
}

// variables: {filters: {controls, limit}, options, viewOpinion, viewClientExposure}
const mergeVariables = (variables: any, tableFilters: any, request: IServerSideGetRowsRequest) => {
  const { startRow, endRow, filterModel, sortModel } = request
  const sortControls = convertSortStateToSavedFilterState(sortModel) || []
  let { filters, ...rest } = variables
  let mergedFilters = {
    controls: [...filters.controls, ...tableFilters, ...sortControls],
    offset: startRow,
    limit: filters.limit || 100,
  }
  return { filters: mergedFilters, ...rest }
}

const DEFAULT_CACHED_BLOCK_SIZE = 100

export const createServerSideDatasource: (
  query: (tVariables: any) => any,
  variables: any,
  gridOptions: GridOptions,
  gridReadyEvent: GridReadyEvent | null
) => IServerSideDatasource = (query, variables, gridOptions, gridReadyEvent) => {
  return {
    getRows: (params: IServerSideGetRowsParams, options: GridOptions = gridOptions, event: GridReadyEvent| null = gridReadyEvent) => {
      const { startRow, endRow, filterModel, sortModel } = params.request
      event?.api?.showLoadingOverlay()
      const filters = convertFilterModel(filterModel) || []
      let mergedVariables = mergeVariables(variables, filters, params.request)
      query(mergedVariables)
        .then((response: any) => {
          const data = response?.data?.products || []
          let {products: rows, hits: rowCount} = data
          if (!rows?.length) {
            event?.api?.showNoRowsOverlay()
            params.success({ rowData: rows, rowCount })
          } else {
            event?.api?.hideOverlay()
            params.success({ rowData: rows, rowCount })
          }
        })
        .catch((err: any) => {
          console.error({ err })
          event?.api?.showNoRowsOverlay()
          params.fail()
        })
    },
  }
}
