import { cloneDeep, findIndex, uniqueId } from "lodash";
import React from "react"

// import { initialState, IState, reducer } from './reducer';

export interface AlertOptions {
  message: string
  title?: string
  color?: string
  timeout?: number
  type?: string
}

export interface AlertOptionsWithId extends AlertOptions {
  show: boolean
  id: string
}

export interface FuncProps {
  addAlert: (alert: AlertOptions) => void
}
export interface ContextProps extends FuncProps {
  alerts: AlertOptionsWithId[]
}


const initialState:ContextProps = {
  alerts: [],
  addAlert: (alert: AlertOptions) => {},
}

const IState:ContextProps = {
  alerts: initialState.alerts,
  addAlert: initialState.addAlert,
}

export const TemporaryAlertContext = React.createContext<ContextProps>(IState)

// Provider for sidebar
export class TemporaryAlertProvider extends React.Component {
  constructor(props:any) {
    super(props);
    this.state = {
      alerts: initialState.alerts,
      addAlert: this.addAlert,
    }
  }

  state:ContextProps

  addAlert = (alert: AlertOptions) => {
    const id = uniqueId()
    this.setState((state:ContextProps) => {
      return {
        alerts: [
          ...state.alerts,
          {
            ...alert,
            show: true,
            id,
          }
        ]
      }
    }, () =>
      window.setTimeout(()=>{
        let alertsCopy = cloneDeep(this.state.alerts)
        const alertIndex = findIndex(alertsCopy, (a) => a.id === id)
        if(alertIndex !== -1) alertsCopy[alertIndex].show = false
        this.setState({ alerts: alertsCopy })
      }, alert.timeout || 2000)
    )
  }

  render() {
    return (
      <TemporaryAlertContext.Provider
        value={this.state}
      >
        {this.props.children}
      </TemporaryAlertContext.Provider>
    )
  }
}
