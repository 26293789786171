import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"

// constants setting here
const EXPLANATION_CHARACTER_LIMIT = 200

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
  charactersLimit?: number
  placeholder?: string
}

interface LimitationsTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type LimitationsTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
}

export const LimitationsTableRowDef: RowDefItem[] = [
  {
    field: "cash",
    type: "text",
    title: "Cash",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Cash"
  },
  {
    field: "geography",
    type: "text",
    title: "Geography",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Geography"
  },
  {
    field: "propertyType",
    type: "text",
    title: "Property Type",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Property Type"
  },
  {
    field: "jointVenture",
    type: "text",
    title: "Joint Venture",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Joint Venture"
  },
  {
    field: "nonCoreAllocation",
    type: "text",
    title: "Non-Core Allocation",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Non-Core Allocation"
  },
  {
    field: "other",
    type: "text",
    title: "Other",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    placeholder: "Other"
  }
]

interface LimitationsTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const LimitationsTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: LimitationsTableRowProps) => {
  let { field, title, subClasses, type, placeholder, charactersLimit } = rowDef
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`Management-${field}-0`} className={"text-left"}>
        {title}
      </td>
      <td key={`Management-${field}-1`}>
        <FormInput
          property={field + "l"}
          displayName={""}
          type={type}
          idx={index}
          editMode={editMode}
          propertyVal={value}
          placeholder={placeholder}
          subClasses={subClasses}
          updateValue={value => updateValue(index, value, type, field)}
          charactersLimit={charactersLimit}
        />
      </td>
    </tr>
  )
}

const LimitationsTable = (seed: LimitationsTableProps) => {
  const tableName = "LimitationsTable"
  const widthClassName = "col-sm-6"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)
  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeManagementRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableState,
        newValue: value,
        property: `${property}`,
        type
      }) || {}
    handleChange(_.get(newData, property), property)
    setData(newData)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size w-100 py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Limitations`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-0`}
      >
        <Table borderless hover size="sm" key={`Limitations-table`}>
          <tbody key={0}>
            {LimitationsTableRowDef.map((rowDef, rowIndex) => {
              return (
                <LimitationsTableRow
                  index={rowIndex}
                  key={rowIndex}
                  data={tableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeManagementRow}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default LimitationsTable
